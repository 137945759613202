import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  filter,
  map,
  switchMap,
  take,
  withLatestFrom,
} from 'rxjs/operators';
import { ApiService } from '../api/api.service';
import { Store } from '@ngrx/store';
import {
  IActivityLogState,
  selectActivityLogRequestId,
  selectActivityLogViewStatus,
  selectNotSubmittedActivityLogs,
  selectSubmittedActivityLogs,
} from './activity-log.reducer';
import {
  debugLog,
  log,
  removeActivityLogs,
  submitActivityLog,
  submitActivityLogFailure,
  submitActivityLogSuccess,
} from './activity-log.actions';
import { of, timer } from 'rxjs';
import { stripActivityLogsHTML } from './stripActivityLogHTML';
import { environment } from '../../environments/environment';
import { endTripSuccessful } from '../trip/trip.actions';
import { equals, isNil, isNilOrEmpty } from '@qld-recreational/ramda';
import { ViewStatus } from '../shared/ViewStatus';
import { IAuthState, selectEmail } from '../auth/auth.reducer';
import { StorageSyncActions } from '../reducers/ngrx-ionic-storage-sync';
import { ActivityStatus } from '../api/model';
import { ITripState } from '../trip/trip.reducer';
import { selectTripId } from '../trip/trip.selectors';
import { utcNow } from '@qld-recreational/moment';
import { BackgroundRequestService } from '../background-request/background-request.service';

@Injectable()
export class ActivityLogEffects {
  private readonly SUBMIT_ACTIVITY_LOG_INTERVAL = 10 * 1000;
  public submitActivityLogs$ = createEffect(() =>
    // @ts-ignore
    this.actions$.pipe(
      ofType(StorageSyncActions.HYDRATED),
      switchMap(() =>
        timer(0, this.SUBMIT_ACTIVITY_LOG_INTERVAL).pipe(
          withLatestFrom(this.authStore.select(selectEmail)),
          filter(([_, email]) => !isNilOrEmpty(email)),
          switchMap(() =>
            this.activityLogStore
              .select(selectActivityLogViewStatus)
              .pipe(take(1))
          ),
          filter(
            (activityLogViewStatus) =>
              !equals(activityLogViewStatus, ViewStatus.Loading)
          ),
          switchMap(() =>
            this.activityLogStore
              .select(selectNotSubmittedActivityLogs)
              .pipe(take(1))
          ),
          filter((activityLogs) => activityLogs.length > 0),
          switchMap(() =>
            this.backgroundRequestService.checkConnectivity().pipe(take(1))
          ),
          filter((isConnected) => isConnected),
          map(() => submitActivityLog())
        )
      )
    )
  );

  public submitActivityLog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(submitActivityLog),
      switchMap(() =>
        this.activityLogStore.select(selectNotSubmittedActivityLogs).pipe(
          take(1),
          map((activityLogs) => stripActivityLogsHTML(activityLogs)),
          withLatestFrom(
            this.activityLogStore.select(selectActivityLogRequestId)
          ),
          switchMap(([entries, requestId]) =>
            this.apiService
              .postActivityLogs({
                clientVersion: environment.buildNumber,
                requestId,
                entries,
              })
              .pipe(
                map(() => submitActivityLogSuccess()),
                catchError((err) =>
                  of(
                    submitActivityLogFailure({
                      err,
                      shouldUpdateRequestId:
                        !isNil(err.status) && !equals(err.status, 400),
                    })
                  )
                )
              )
          )
        )
      )
    )
  );

  public clearSubmittedActivityLogs = createEffect(() =>
    this.actions$.pipe(
      ofType(endTripSuccessful),
      switchMap(() =>
        this.activityLogStore.select(selectSubmittedActivityLogs).pipe(take(1))
      ),
      map((activityLogs) =>
        removeActivityLogs({
          ids: activityLogs.map(
            (activityLog) => `${activityLog.timestamp} - ${activityLog.action}`
          ),
        })
      )
    )
  );

  public debugActivityLogsForSLA$ = createEffect(() =>
    this.actions$.pipe(
      ofType(debugLog),
      withLatestFrom(this.tripStore.select(selectTripId)),
      map(([{ activityLog }, tripCorrelationID]) =>
        log({
          activityLog: {
            ...activityLog,
            timestamp: utcNow(),
            status: ActivityStatus.success,
            tripCorrelationID,
          },
        })
      )
    )
  );

  constructor(
    private actions$: Actions,
    private activityLogStore: Store<IActivityLogState>,
    private authStore: Store<IAuthState>,
    private backgroundRequestService: BackgroundRequestService,
    private apiService: ApiService,
    private tripStore: Store<ITripState>
  ) {}
}
