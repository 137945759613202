import { createReducer, on } from '@ngrx/store';
import { switchUser } from '../auth/auth.actions';
import { clearSessionData } from '../settings/settings.actions';
import { TripStep } from './trip-step';
import {
  changeTripStep,
  createTrip,
  endTrip,
  endTripFailure,
  endTripSuccessful,
  lockLogbookSuccessful,
  lockPreTrip,
  lockPriorEmergencyNoticeSuccessful,
  lockRetainNoticeSuccessful,
  lockWeightNoticeSuccessful,
  resetEndTripViewStatus,
  resetTrip,
  submitCDRSuccessful,
  submitNilFishing,
  submitPreTripSuccessful,
  updateActivityNoticeStatus,
  updateBoatMark,
  updateCatchDisposalLogbookEnabled,
} from './trip.actions';
import {
  ActivityEmergencyCreate,
  ActivityPreTripCreate,
  ActivityPriorCreate,
  ActivityWeightCreate,
  LogbookCreate,
} from '../api/model';
import { utcNow } from '@qld-recreational/moment';
import { IActivityNoticeStatus } from '../shared/models/ActivityNoticeStatus';
import { ActivityRetainedCreate } from '../api/model/activityRetainedCreate';
import { NilFishingCreate } from '../api/model/nilFishingCreate';
import { ViewStatus } from '../shared/ViewStatus';
import { v4 as uuidv4 } from 'uuid';
import { IPreTrip } from './trip.model';

export const tripFeatureKey = 'trip';

export interface ITripState extends IActivityNoticeStatus {
  id: string;
  preTripRequestId: string;
  endTripRequestId: string;
  boatMark: string;
  tripStep: TripStep;
  nilFishingCreates: Omit<NilFishingCreate, 'requestId'>[];
  preTrip: IPreTrip;
  logbookCreate: Omit<LogbookCreate, 'requestId' | 'clientVersion'>;
  priorEmergencyNoticeCreate:
    | Omit<ActivityPriorCreate, 'requestId' | 'clientVersion'>
    | Omit<ActivityEmergencyCreate, 'requestId' | 'clientVersion'>;
  weightNoticeCreates: ActivityWeightCreate[];
  retainNoticeCreate: Omit<
    ActivityRetainedCreate,
    'requestId' | 'clientVersion'
  >;
  catchDisposalLogbookEnabled: boolean;
  cdrCreates: LogbookCreate[];
  endTripViewStatus: ViewStatus;
}

export const initialState = (): ITripState => ({
  id: undefined,
  preTripRequestId: uuidv4(),
  endTripRequestId: uuidv4(),
  boatMark: undefined,
  tripStep: TripStep.PreTrip,
  nilFishingCreates: [],
  preTrip: null,
  logbookCreate: null,
  priorEmergencyNoticeCreate: null,
  catchDisposalLogbookEnabled: undefined,
  weightNoticeCreates: [],
  retainNoticeCreate: null,
  cdrCreates: [],
  emergencyActivityNoticeEnabled: true,
  priorActivityNoticeEnabled: true,
  retainActivityNoticeEnabled: true,
  weightActivityNoticeEnabled: true,
  endTripViewStatus: ViewStatus.Initial,
});

const tripStepAfterLockLogbookSuccessful = (
  state: ITripState,
  noQuota: boolean
) => {
  if (noQuota) {
    return TripStep.ReadyToEndTrip;
  }
  if (
    state.priorActivityNoticeEnabled ||
    state.emergencyActivityNoticeEnabled
  ) {
    return TripStep.PriorEmergencyNotice;
  }
};

export const reducer = createReducer(
  initialState(),
  on(changeTripStep, (state, { tripStep }) => ({ ...state, tripStep })),
  on(updateActivityNoticeStatus, (state, { activityNoticeStatus }) => ({
    ...state,
    ...activityNoticeStatus,
  })),
  on(submitNilFishing, (state, { nilFishingCreate }) => ({
    ...state,
    nilFishingCreates: [...state.nilFishingCreates, nilFishingCreate],
  })),
  on(createTrip, (state, { id }) => ({
    ...state,
    id,
    tripStep: TripStep.RecordCatch,
  })),
  on(lockPreTrip, (state, { preTrip }) => ({
    ...state,
    preTrip,
  })),
  on(submitPreTripSuccessful, (state, { receipt }) => ({
    ...state,
    preTrip: { ...state.preTrip, lastSubmitted: utcNow(), receipt },
  })),
  on(lockLogbookSuccessful, (state, { logbookCreate, noQuota }) => ({
    ...state,
    tripStep: tripStepAfterLockLogbookSuccessful(state, noQuota),
    logbookCreate,
  })),
  on(
    lockPriorEmergencyNoticeSuccessful,
    (state, { priorEmergencyNoticeCreate }) => ({
      ...state,
      priorEmergencyNoticeCreate,
    })
  ),
  on(lockWeightNoticeSuccessful, (state, { weightNoticeCreate }) => ({
    ...state,
    ...(!state.catchDisposalLogbookEnabled
      ? { tripStep: TripStep.ReadyToEndTrip }
      : {}),
    weightNoticeCreates: [...state.weightNoticeCreates, weightNoticeCreate],
  })),
  on(lockRetainNoticeSuccessful, (state, { retainNoticeCreate }) => ({
    ...state,
    tripStep: TripStep.ReadyToEndTrip,
    retainNoticeCreate,
  })),
  on(submitCDRSuccessful, (state, { logbookCreate }) => ({
    ...state,
    tripStep: TripStep.ReadyToEndTrip,
    cdrCreates: [...state.cdrCreates, logbookCreate],
  })),
  on(updateBoatMark, (state, { boatMark }) => ({ ...state, boatMark })),
  on(
    updateCatchDisposalLogbookEnabled,
    (state, { catchDisposalLogbookEnabled }) => ({
      ...state,
      catchDisposalLogbookEnabled,
    })
  ),
  on(endTrip, (state) => ({ ...state, endTripViewStatus: ViewStatus.Loading })),
  on(endTripSuccessful, (state) => ({
    ...state,
    endTripViewStatus: ViewStatus.Success,
  })),
  on(endTripFailure, (state, { shouldUpdateRequestId }) => ({
    ...state,
    ...(shouldUpdateRequestId ? { endTripRequestId: uuidv4() } : {}),
    endTripViewStatus: ViewStatus.Failure,
  })),
  on(resetEndTripViewStatus, (state) => ({
    ...state,
    endTripViewStatus: ViewStatus.Initial,
  })),
  on(clearSessionData, switchUser, resetTrip, () => initialState())
);
