import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { selectApiUrl, selectApiUrlSV } from '../settings/settings.selectors';
import { Store } from '@ngrx/store';
import { ISettingState } from '../settings/settings.reducer';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private settingStore: Store<ISettingState>
  ) {}

  public static addAuthHeader(
    request: HttpRequest<any>,
    token: string
  ): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return this.settingStore.select(selectApiUrl).pipe(
      withLatestFrom(this.settingStore.select(selectApiUrlSV)),
      map(
        ([apiUrl, apiUrlSV]) =>
          request.url.includes(apiUrl) || request.url.includes(apiUrlSV)
      ),
      switchMap((isApiUrl) => {
        if (!isApiUrl || request.url.includes('ping')) {
          return next.handle(request);
        }
        return this.authService.getAccessToken().pipe(
          take(1),
          switchMap((token) => {
            const transformedRequest = TokenInterceptor.addAuthHeader(
              request,
              token
            );

            return next.handle(transformedRequest);
          })
        );
      })
    );
  }
}
