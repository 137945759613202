import { createAction, props } from '@ngrx/store';
import { ActivityPreTripAmendCreate, ActivityResult } from '../api/model';

export const amendLandingLocation = createAction(
  '[AmendingNotice] amend landing location',
  props<{
    payload: Omit<ActivityPreTripAmendCreate, 'requestId' | 'clientVersion'> & {
      landingPointAsString: string;
    };
  }>()
);

export const amendLandingLocationSuccess = createAction(
  '[AmendingNotice] amend landing location Success',
  props<{ data: ActivityResult }>()
);

export const amendLandingLocationFailure = createAction(
  '[AmendingNotice] amend landing location Failure',
  props<{ error: any }>()
);
