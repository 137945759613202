import { Envs } from '../settings/settings';
import { Preferences } from '@capacitor/preferences';
import {
  AuthConnect,
  AuthResult,
  ProviderOptions,
} from '@ionic-enterprise/auth';

export const ACCESS_TOKEN_KEY = 'APP_AUTH_ACCESS_TOKEN';
export const AUTH_RESPONSE_KEY = 'APP_AUTH_AUTH_RESPONSE';
export const ID_TOKEN_KEY = 'APP_AUTH_ID_TOKEN';
export const REFRESH_TOKEN_KEY = 'APP_AUTH_REFRESH_TOKEN';

export enum AuthenticationError {
  UserDecline = 'USER_CANCELLED',
}

export function storeTokens(authResult: AuthResult) {
  return Promise.all([
    tokenStorageProvider.setAccessToken(authResult.accessToken),
    tokenStorageProvider.setAuthResponse(JSON.stringify(authResult)),
    tokenStorageProvider.setIdToken(authResult.idToken),
    tokenStorageProvider.setRefreshToken(authResult.refreshToken),
  ]);
}

export async function getAuthResponse(): Promise<AuthResult> {
  const result = await tokenStorageProvider.getAuthResponse();
  return JSON.parse(result);
}

export function clearTokens() {
  return tokenStorageProvider.clear();
}

export async function retrieveTokens(): Promise<
  Parameters<typeof AuthConnect.buildAuthResult>[2]
> {
  const refreshToken = await tokenStorageProvider.getRefreshToken();
  const accessToken = await tokenStorageProvider.getAccessToken();
  const idToken = await tokenStorageProvider.getIdToken();
  return {
    refreshToken,
    accessToken,
    idToken,
  };
}

const tokenStorageProvider = {
  clear: async () =>
    [
      ACCESS_TOKEN_KEY,
      AUTH_RESPONSE_KEY,
      ID_TOKEN_KEY,
      REFRESH_TOKEN_KEY,
    ].forEach((key) => Preferences.remove({ key })),
  getAccessToken: async () =>
    (await Preferences.get({ key: ACCESS_TOKEN_KEY })).value,
  getAuthResponse: async () =>
    JSON.parse((await Preferences.get({ key: AUTH_RESPONSE_KEY })).value),
  getIdToken: async () => (await Preferences.get({ key: ID_TOKEN_KEY })).value,
  getRefreshToken: async () =>
    (await Preferences.get({ key: REFRESH_TOKEN_KEY })).value,
  setAccessToken: async (accessToken: string) =>
    Preferences.set({
      key: ACCESS_TOKEN_KEY,
      value: accessToken,
    }),
  setAuthResponse: async (authResponse: string) =>
    Preferences.set({
      key: AUTH_RESPONSE_KEY,
      value: JSON.stringify(authResponse),
    }),
  setRefreshToken: async (refreshToken: string) =>
    Preferences.set({
      key: REFRESH_TOKEN_KEY,
      value: refreshToken,
    }),
  setIdToken: async (idToken: string) =>
    Preferences.set({
      key: ID_TOKEN_KEY,
      value: idToken,
    }),
};

export const getAudienceFromDiscoveryUrl = (discoveryUrl: string) =>
  'https://' + discoveryUrl?.replace('https://', '')?.split('/')?.[0];

export const ionicAuthWebOptions = (env: Envs): ProviderOptions => ({
  audience: getAudienceFromDiscoveryUrl(env?.azureDiscoveryUrl),
  clientId: env?.azureClientId,
  discoveryUrl: env?.azureDiscoveryUrl,
  redirectUri: env?.azureWebRedirectUrl,
  scope: env?.azureScope,
  logoutUrl: env?.azureWebRedirectUrl,
});

export const ionicAuthMobileOptions = (env: Envs): ProviderOptions => ({
  audience: getAudienceFromDiscoveryUrl(env?.azureDiscoveryUrl),
  clientId: env?.azureClientId,
  discoveryUrl: env?.azureDiscoveryUrl,
  redirectUri: env?.azureMobileRedirectUrl,
  scope: env?.azureScope,
  logoutUrl: env?.azureMobileRedirectUrl,
});
