import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  filter,
  map,
  mergeMap,
  switchMap,
  take,
  withLatestFrom,
} from 'rxjs/operators';
import {
  addCatchDisposalRecord,
  deleteCatchDisposalRecord,
  deleteCatchDisposalRecords,
} from '../catch-disposal-record/catch-disposal-record.actions';
import { Store } from '@ngrx/store';
import {
  ICatchDisposalRecordCatchesState,
  selectCDRCatchesByCDRId,
} from './catch-disposal-record-catches.reducer';
import {
  initCDRCatchesForSpecies,
  removeCDRCatches,
  updateCDRCatches,
} from './catch-disposal-record-catches.actions';
import { forkJoin } from 'rxjs';
import { selectCatchesByLogbookEventId } from '../logbook-catches/logbook-catches.reducer';
import { isEmpty } from '@qld-recreational/ramda';
import {
  ICatchDisposalRecordState,
  selectInitSpecies,
} from '../catch-disposal-record/catch-disposal-record.reducer';
import { CatchDisposalRecordCatchesService } from './catch-disposal-record-catches.service';

@Injectable()
export class CatchDisposalRecordCatchesEffects {
  constructor(
    private actions$: Actions,
    private cdrCatchStore: Store<ICatchDisposalRecordCatchesState>,
    private cdrStore: Store<ICatchDisposalRecordState>,
    private cdrCatchesService: CatchDisposalRecordCatchesService
  ) {}

  public initCDRCatchForSpecies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(initCDRCatchesForSpecies),
      switchMap(({ species, catchDisposalRecord: cdr }) =>
        this.cdrCatchesService.getCDRLogbookBySpecies(species).pipe(
          switchMap((logbook) =>
            this.cdrCatchesService.getCDRCatchesFromPreferences(
              species,
              logbook,
              cdr
            )
          ),
          map((cdrCatches) => {
            return updateCDRCatches({ cdrCatches });
          })
        )
      )
    )
  );

  public addCDRCatchesToCDRInit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addCatchDisposalRecord),
      withLatestFrom(this.cdrStore.select(selectInitSpecies)),
      mergeMap(([{ catchDisposalRecord }, initSpecies]) =>
        initSpecies.map((species) =>
          initCDRCatchesForSpecies({ species, catchDisposalRecord })
        )
      )
    )
  );

  public deleteCDRCatchesByCDRId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteCatchDisposalRecord),
      switchMap(({ id }) =>
        this.cdrCatchStore.select(selectCDRCatchesByCDRId(id)).pipe(
          filter((cdrCatches) => cdrCatches.length > 0),
          take(1),
          map((cdrCatches) => cdrCatches.map((cdrCatch) => cdrCatch.id))
        )
      ),
      map((keys) => removeCDRCatches({ keys }))
    )
  );

  public deleteCDRCatchesByCDRIds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteCatchDisposalRecords),
      mergeMap(({ ids }) =>
        forkJoin(
          ids.map((id) =>
            this.cdrCatchStore
              .select(selectCatchesByLogbookEventId(id))
              .pipe(take(1))
          )
        )
      ),
      map((cdrCatches) => cdrCatches.flat()),
      map((cdrCatches) => cdrCatches.map(({ id }) => id)),
      filter((ids) => !isEmpty(ids)),
      map((keys) => removeCDRCatches({ keys }))
    )
  );
}
