import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import {
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from '@ngrx/store';
import { switchUser } from '../auth/auth.actions';
import { clearSessionData } from '../settings/settings.actions';
import {
  deleteCDRFishFormMeasurePreferences,
  loadCDRFishFormMeasurePreferences,
  upsertCDRFishFormMeasurePreferences,
} from './preference-cdr-fish-forms-measures.actions';

export const preferencesCDRFishFormsMeasuresFeatureKey =
  'preferencesCDRFishFormsMeasures';

export interface SpeciesFishFormMeasurePreference {
  fishFormID: string;
  measureID: string;
}

export function makeSpeciesFishFormMeasureEntityID(
  item: SpeciesFishFormMeasurePreference
) {
  return `${item.fishFormID}__${item.measureID}`;
}

const adapter: EntityAdapter<SpeciesFishFormMeasurePreference> = createEntityAdapter(
  {
    selectId: makeSpeciesFishFormMeasureEntityID,
  }
);

export interface ICDRFishFormsMeasuresPreferenceState {
  [logbook: string]: {
    [species: string]: EntityState<SpeciesFishFormMeasurePreference>;
  };
}

const initialState: ICDRFishFormsMeasuresPreferenceState = {};

export const reducer = createReducer(
  initialState,
  on(loadCDRFishFormMeasurePreferences, (state, { preferences }) => ({
    ...preferences,
  })),
  on(
    upsertCDRFishFormMeasurePreferences,
    (state, { logbook, species, preferences }) => {
      const logbookPreference = state[logbook] ?? {};

      const speciesPreference = adapter.upsertMany(
        preferences,
        logbookPreference[species] || adapter.getInitialState()
      );
      return {
        ...state,
        [logbook]: {
          ...logbookPreference,
          [species]: speciesPreference,
        },
      };
    }
  ),
  on(
    deleteCDRFishFormMeasurePreferences,
    (state, { logbook, species, preference }) => {
      const logbookPreference = state[logbook] ?? {};

      const speciesPreference = adapter.removeOne(
        adapter.selectId(preference).toString(),
        logbookPreference[species] || adapter.getInitialState()
      );
      return {
        ...state,
        [logbook]: {
          ...logbookPreference,
          [species]: speciesPreference,
        },
      };
    }
  ),
  on(clearSessionData, switchUser, () => initialState)
);

export const selectCDRFishFormPreferencesState = createFeatureSelector<
  ICDRFishFormsMeasuresPreferenceState
>(preferencesCDRFishFormsMeasuresFeatureKey);

export const selectCDRFishFormPreferenceForSpecies = (
  logbook: string,
  species: string
) =>
  createSelector(selectCDRFishFormPreferencesState, (state) =>
    adapter
      .getSelectors()
      .selectAll(state?.[logbook]?.[species] ?? adapter.getInitialState())
  );
