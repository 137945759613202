import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { of } from 'rxjs';
import { loginSuccess } from '../auth/auth.actions';
import { MESSAGES } from '../messages';
import { loadVMS, loadVMSFailed, loadVMSSuccessful } from './vms.actions';
import { ApiService } from '../api/api.service';
import { ToastService } from '@qld-recreational/toast';
import { selectTripId } from '../trip/trip.selectors';
import { log } from '../activity-log/activity-log.actions';
import { Store } from '@ngrx/store';
import { IActivityLogState } from '../activity-log/activity-log.reducer';
import { ITripState } from '../trip/trip.reducer';
import { loadVMSSuccessActivityLog } from './vms-activity-log';
import { Page } from '../api/model';
import { equals } from '@qld-recreational/ramda';
import { BackgroundRequestService } from '../background-request/background-request.service';

@Injectable()
export class VmsEffects {
  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private toastService: ToastService,
    private activityLogStore: Store<IActivityLogState>,
    private tripStore: Store<ITripState>,
    private bgService: BackgroundRequestService
  ) {}

  public loadVMS$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadVMS),
      switchMap(({ page }) => this.getVMS(page))
    )
  );

  public loginSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loginSuccess),
      switchMap(() => this.getVMS())
    )
  );

  public loadVMSSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadVMSSuccessful),
        withLatestFrom(this.tripStore.select(selectTripId)),
        tap(([{ page }, tripCorrelationID]) =>
          this.activityLogStore.dispatch(
            log({
              activityLog: loadVMSSuccessActivityLog(page, tripCorrelationID),
            })
          )
        )
      ),
    { dispatch: false }
  );

  private getVMS = (page?: Page) =>
    this.bgService.checkConnectivity().pipe(
      switchMap((connected) => {
        if (!connected) {
          this.toastService.presentFailureToast(MESSAGES.noInternet);
          return of(loadVMSFailed());
        }
        return this.apiService.getVMS().pipe(
          map((vmsStatus) => loadVMSSuccessful({ page, vmsStatus })),
          catchError((err) => {
            if (err.status !== 401) {
              this.toastService.presentWarningToast(MESSAGES.loadVMSFailure);
            }
            return of(loadVMSFailed());
          })
        );
      })
    );
}
