import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import {
  preferencesDisposalFeatureKey,
  reducer,
} from './preferences-disposal.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(preferencesDisposalFeatureKey, reducer),
  ],
})
export class PreferenceDisposalModule {}
