import { Injectable } from '@angular/core';
import BackgroundGeolocation, {
  Config,
  Location,
  AuthorizationStatus,
} from '@transistorsoft/capacitor-background-geolocation';
import { Store } from '@ngrx/store';
import { IManualPollingState } from './manual-polling.reducer';
import {
  locationReceived,
  startPolling,
  stopPollingIfPollingHasStarted,
} from './manual-polling.actions';
import {
  GeolocationAuthorizationStatus,
  ILocation,
  LocationStatus,
} from './manual-polling';
import { AlertService } from '@qld-recreational/alert';
import { utcNow } from '@qld-recreational/moment';
import * as moment from 'moment';
import { equals, isNilOrEmpty } from '@qld-recreational/ramda';
import { Capacitor } from '@capacitor/core';
import { MESSAGES } from '../messages';

@Injectable()
export class ManualPollingService {
  private readonly MANUAL_POLLING_INTERVAL = 60 * 60 * 1000;
  private readonly ALLOWED_POLLING_TIME_OFFSET = 60 * 1000;
  private readonly bgGeoLocation = BackgroundGeolocation;
  private lastRecordTime: string;
  private lastLocation: Location;
  private eventIsInProgress = false;

  constructor(
    private store: Store<IManualPollingState>,
    private alertService: AlertService
  ) {
    this.bgGeoLocation = BackgroundGeolocation;

    // wrapper for web so we can run the app in the browser
    if (equals(Capacitor.getPlatform(), 'web')) {
      this.bgGeoLocation = {
        onProviderChange: () => {},
        onLocation: () => {},
        onHeartbeat: () => {},
        stop: () => {},
        ready: () => {},
        requestPermission: () => {},
      } as any;
    }
  }

  public init() {
    const config: Config = {
      reset: true,
      desiredAccuracy: this.bgGeoLocation.DESIRED_ACCURACY_HIGH,
      stationaryRadius: 1,
      distanceFilter: 1,
      activityType: this.bgGeoLocation.ACTIVITY_TYPE_OTHER,
      locationTimeout: 60,
      useSignificantChangesOnly: false,
      stopOnStationary: false,
      allowIdenticalLocations: true,
      disableStopDetection: true,
      pausesLocationUpdatesAutomatically: false,
      locationAuthorizationRequest: 'Always',
      locationAuthorizationAlert: {
        titleWhenNotEnabled: 'The app need higher location authorization',
        titleWhenOff: 'Location services are off',
        instructions:
          'To enable the app to record your location at the background, please give the app ALWAYS location authorization',
        cancelButton: 'Cancel',
        settingsButton: 'Settings',
      },
      backgroundPermissionRationale: {
        title: `Allow access to this device's location in the background?`,
        message: `In order to allow the app to manually poll your location periodically, please enable 'Allow all the time' permission`,
        positiveAction: 'Change to Allow all the time',
      },
      stopOnTerminate: false,
      preventSuspend: true,
      heartbeatInterval: 60,
      startOnBoot: true,
      activityRecognitionInterval: 1,
      fastestLocationUpdateInterval: 1,
      logLevel: this.bgGeoLocation.LOG_LEVEL_ERROR, // switch to LOG_LEVEL_VERBOSE for debugging
      persistMode: this.bgGeoLocation.PERSIST_MODE_NONE,
      disableLocationAuthorizationAlert: false,
    };
    this.bgGeoLocation.onProviderChange((event) => {
      if (event.status === this.bgGeoLocation.AUTHORIZATION_STATUS_DENIED) {
        this.store.dispatch(stopPollingIfPollingHasStarted());
      }
    });

    this.bgGeoLocation.onLocation((location) => {
      if (this.eventIsInProgress) {
        return;
      }
      this.eventIsInProgress = true;
      this.lastLocation = location;
      if (isNilOrEmpty(this.lastRecordTime)) {
        this.store.dispatch(
          locationReceived({
            location: this.constructLocation(location, utcNow()),
          })
        );
        this.lastRecordTime = utcNow();
        this.eventIsInProgress = false;
        return;
      }
      const [numberOfMissedRecord, offset] = this.numberOfMissedRecord;
      if (numberOfMissedRecord < 1) {
        this.eventIsInProgress = false;
        return;
      }
      [...Array(numberOfMissedRecord).keys()].forEach((i) => {
        const recordTime = this.nextRecordTime;
        this.store.dispatch(
          locationReceived({
            location: this.constructLocation(
              equals(i, numberOfMissedRecord) &&
                offset <= this.ALLOWED_POLLING_TIME_OFFSET
                ? location
                : this.lastLocation,
              recordTime
            ),
          })
        );
        this.lastRecordTime = recordTime;
      });
      this.eventIsInProgress = false;
    });

    this.bgGeoLocation.onHeartbeat(() => {
      if (this.eventIsInProgress) {
        return;
      }
      if (isNilOrEmpty(this.lastRecordTime)) {
        return;
      }
      this.eventIsInProgress = true;
      const [numberOfMissedRecord, offset] = this.numberOfMissedRecord;
      if (numberOfMissedRecord < 1) {
        this.eventIsInProgress = false;
        return;
      }
      [...Array(numberOfMissedRecord).keys()].forEach((i) => {
        const recordTime = this.nextRecordTime;
        if (
          equals(i, numberOfMissedRecord) &&
          offset <= this.ALLOWED_POLLING_TIME_OFFSET
        ) {
          BackgroundGeolocation.getCurrentPosition({
            samples: 1,
            timeout: 60,
            persist: true,
          })
            .then((location) => {
              this.lastLocation = location;
              this.store.dispatch(
                locationReceived({
                  location: this.constructLocation(location, recordTime),
                })
              );
            })
            .catch(() => {
              this.store.dispatch(
                locationReceived({
                  location: this.constructLocation(
                    this.lastLocation,
                    recordTime
                  ),
                })
              );
            });
        } else {
          this.store.dispatch(
            locationReceived({
              location: this.constructLocation(this.lastLocation, recordTime),
            })
          );
        }
        this.lastRecordTime = recordTime;
      });
      this.eventIsInProgress = false;
    });

    this.bgGeoLocation.ready(
      config,
      (state) => {},
      (error) => {
        console.log(error);
      }
    );
  }

  get numberOfMissedRecord() {
    const timeDiff = moment().diff(moment(this.lastRecordTime));
    return [
      Math.trunc(timeDiff / this.MANUAL_POLLING_INTERVAL),
      timeDiff % this.MANUAL_POLLING_INTERVAL,
    ];
  }

  get nextRecordTime() {
    return moment(this.lastRecordTime)
      .add(this.MANUAL_POLLING_INTERVAL, 'ms')
      .format();
  }

  public refreshTracking(): Promise<void> {
    return this.bgGeoLocation.changePace(true);
  }

  public stopPolling() {
    this.lastRecordTime = undefined;
    this.lastLocation = undefined;
    this.eventIsInProgress = false;
    this.bgGeoLocation.stop();
  }

  public requestLocationPermission(): Promise<AuthorizationStatus> {
    return this.bgGeoLocation.requestPermission();
  }

  public async ifLocationIsEnabled(): Promise<boolean> {
    try {
      const { status } = await this.bgGeoLocation.getProviderState();
      return !equals(
        status,
        GeolocationAuthorizationStatus.AUTHORIZATION_STATUS_DENIED
      );
    } catch {
      return false;
    }
  }

  public startPolling() {
    this.bgGeoLocation.start();
  }

  public handlePollingExceed5DaysLimit(payload: {
    licence: string;
    boatMark: string;
  }) {
    this.store.dispatch(stopPollingIfPollingHasStarted());
    this.alertService.presentDoubleActionAlert(
      'Vessel tracking',
      MESSAGES.refreshManualPolling,
      () => {
        this.store.dispatch(startPolling(payload));
      }
    );
  }

  private constructLocation(
    location: Location,
    effectiveDateTime: string
  ): ILocation {
    const coords = location.coords;

    return {
      latitude: parseFloat(coords.latitude.toFixed(6)),
      longitude: parseFloat(coords.longitude.toFixed(6)),
      effectiveDateTime,
      accuracy: coords.accuracy,
      speed: coords.speed,
      heading: coords.heading,
      altitude: coords.altitude,
      status: LocationStatus.Pending,
    };
  }
}
