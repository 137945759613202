import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  loadNewNotifications,
  loadNewNotificationsSuccess,
  loadNewNotificationsFailure,
} from './notification.actions';
import {
  switchMap,
  map,
  catchError,
  tap,
  take,
  filter,
  delay,
} from 'rxjs/operators';
import { NotificationService } from './notification.service';
import { of } from 'rxjs';
import { loginSuccess, logout } from '../auth/auth.actions';
import { Store } from '@ngrx/store';
import { IAuthState, selectEmail } from '../auth/auth.reducer';
import { INotificationState } from './notification.reducer';
import { Platform } from '@ionic/angular';
import { StorageSyncActions } from '../reducers/ngrx-ionic-storage-sync';

@Injectable()
export class NotificationEffects {
  public initNotification$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(StorageSyncActions.HYDRATED),
        tap(() => this.notificationService.initNotification())
      ),
    { dispatch: false }
  );

  public addEmailTag = createEffect(() =>
    this.actions$.pipe(
      ofType(loginSuccess),
      switchMap(() => this.authStore.select(selectEmail).pipe(take(1))),
      tap((email) => this.notificationService.subscriptionNotifications(email)),
      // wait for potential switch user to update lastLoadedTime
      delay(1000),
      map(() => loadNewNotifications())
    )
  );

  public loadNewNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadNewNotifications),
      filter(() => this.platform.is('hybrid')),
      switchMap(() =>
        this.notificationService.loadNewNotifications().pipe(
          filter((notifications) => notifications?.length > 0),
          map((notifications) =>
            loadNewNotificationsSuccess({ notifications })
          ),
          catchError(() => of(loadNewNotificationsFailure()))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private platform: Platform,
    private notificationService: NotificationService,
    private notificationStore: Store<INotificationState>,
    private authStore: Store<IAuthState>
  ) {}
}
