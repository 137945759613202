import { Injectable, isDevMode } from '@angular/core';
import {
  clearTokens,
  getAuthResponse,
  ionicAuthMobileOptions,
  ionicAuthWebOptions,
  storeTokens,
} from './auth';
import { Store } from '@ngrx/store';
import { IAuthState } from './auth.reducer';
import { Platform } from '@ionic/angular';
import {
  catchError,
  map,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { equals } from '@qld-recreational/ramda';
import { ISettingState } from '../settings/settings.reducer';
import {
  selectTargetEnv,
  selectTargetEnvs,
} from '../settings/settings.selectors';
import { concatMap, from, Observable, of, retry } from 'rxjs';
import { TargetEnv } from '../settings/settings';
import {
  AuthConnect,
  AuthResult,
  AzureProvider,
  ProviderOptions,
  TokenType,
} from '@ionic-enterprise/auth';
import jwtDecode from 'jwt-decode';
import { isNil } from 'rambda';
import { logout } from './auth.actions';

const refreshTokenExpiredErrorMessage = 'grant has expired';
const refreshTokenInvalidGrant = 'invalid_grant';
const refreshTokenIsNotActive = 'authentication error: token is not active';
const refreshTokenNotAvailable = 'refresh token is not available';
const refreshTokenUnableToRefresh = 'unable to refresh session';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private platform: Platform,
    private authStore: Store<IAuthState>,
    private settingsStore: Store<ISettingState>
  ) {}

  private provider = new AzureProvider();
  private authResult: AuthResult;

  public async setup() {
    await AuthConnect.setup({
      platform: this.platform.is('hybrid') ? 'capacitor' : 'web',
      logLevel: isDevMode() ? 'DEBUG' : 'ERROR',
      ios: {
        webView: 'private',
      },
      web: {
        authFlow: 'PKCE',
        uiMode: 'popup',
      },
    });

    this.authResult = await getAuthResponse();
  }

  private getProviderOptions(): Observable<ProviderOptions> {
    return this.settingsStore.select(selectTargetEnvs).pipe(
      take(1),
      withLatestFrom(this.settingsStore.select(selectTargetEnv)),
      map(([envs, targetEnv]) => {
        if (equals(targetEnv, TargetEnv.mock)) {
          return undefined;
        }

        if (this.platform.is('hybrid')) {
          return ionicAuthMobileOptions(envs);
        }
        return ionicAuthWebOptions(envs);
      })
    );
  }

  public getAccessToken() {
    return this.settingsStore.select(selectTargetEnv).pipe(
      take(1),
      switchMap((targetEnv) => {
        if (equals(targetEnv, TargetEnv.mock)) {
          return of('mock token');
        }

        if (isNil(this.authResult)) {
          return of(null);
        }

        return from(AuthConnect.isAccessTokenExpired(this.authResult)).pipe(
          switchMap((isAccessTokenExpired) => {
            if (!isAccessTokenExpired) {
              return this.getAccessTokenFromResult(this.authResult);
            }

            return from(
              AuthConnect.refreshSession(this.provider, this.authResult)
            ).pipe(
              tap((result) => (this.authResult = result)),
              switchMap((result) =>
                from(storeTokens(result)).pipe(
                  switchMap(() => this.getAccessTokenFromResult(result))
                )
              ),
              catchError((error) => {
                if (
                  typeof error?.message === 'string' &&
                  (error.message.toLowerCase().startsWith('token error') ||
                    [
                      refreshTokenExpiredErrorMessage,
                      refreshTokenInvalidGrant,
                      refreshTokenIsNotActive,
                      refreshTokenNotAvailable,
                      refreshTokenUnableToRefresh,
                    ].some((message) =>
                      error.message.toLowerCase().includes(message)
                    ))
                ) {
                  this.authStore.dispatch(logout());
                }
                return of(null);
              })
            );
          })
        );
      })
    );
  }

  public expire() {
    return from(AuthConnect.expire(this.authResult)).subscribe();
  }

  public refreshSession() {
    return from(
      AuthConnect.refreshSession(this.provider, this.authResult)
    ).pipe(
      tap((result) => (this.authResult = result)),
      switchMap((result) => storeTokens(result))
    );
  }

  public getIsAccessTokenExpired() {
    return from(AuthConnect.isAccessTokenExpired(this.authResult));
  }

  public getRefreshToken() {
    return from(AuthConnect.getToken(TokenType.refresh, this.authResult));
  }

  public getAccessTokenExpiration() {
    return from(AuthConnect.getAccessTokenExpiration(this.authResult));
  }

  public getEmail() {
    return this.settingsStore.select(selectTargetEnv).pipe(
      take(1),
      switchMap((targetEnv) => {
        if (equals(targetEnv, TargetEnv.mock)) {
          return of('test@email.com');
        }

        return from(AuthConnect.getToken(TokenType.id, this.authResult)).pipe(
          map((token) => jwtDecode(token)),
          map(({ emails }) => emails[0])
        );
      })
    );
  }

  public async getAccessTokenFromResult(authResult: AuthResult) {
    if (!authResult) {
      return null;
    }

    return AuthConnect.getToken(TokenType.access, authResult);
  }

  public login() {
    return this.getProviderOptions().pipe(
      take(1),
      withLatestFrom(this.settingsStore.select(selectTargetEnv)),
      switchMap(([options, targetEnv]) => {
        if (equals(targetEnv, TargetEnv.mock)) {
          return of(true);
        }

        return from(AuthConnect.login(this.provider, options)).pipe(
          tap((result) => (this.authResult = result)),
          switchMap((result) => storeTokens(result))
        );
      })
    );
  }

  public logout() {
    if (isNil(this.authResult)) {
      return of(null);
    }

    return this.settingsStore.select(selectTargetEnv).pipe(
      take(1),
      concatMap((targetEnv) => {
        if (equals(targetEnv, TargetEnv.mock)) {
          return of(true);
        }

        return from(AuthConnect.logout(this.provider, this.authResult)).pipe(
          tap(() => clearTokens()),
          tap(() => (this.authResult = null))
        );
      }),
      retry(2),
      tap(() => clearTokens()),
      tap(() => (this.authResult = null))
    );
  }
}
