import { Injectable } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { PendingTransactionModalComponent } from './pending-transaction-modal.component';
import { from, Observable, UnaryFunction } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { networkConnected } from '@qld-recreational/network';
import { hapticWarning } from '@qld-recreational/haptic';
import { equals, isNil } from '@qld-recreational/ramda';
import { ToastService } from '@qld-recreational/toast';
import { MESSAGES } from '../messages';

@Injectable()
export class PendingTransactionModalService {
  private readonly PENDING_TRANSACTION_MODAL_ID = 'pending-transaction-modal';
  private timeoudId?: ReturnType<typeof setTimeout>;
  private readonly TIMEOUT_DURATION = 1000 * 90;

  constructor(
    private modalController: ModalController,
    private toastController: ToastService
  ) {}

  public async presentModal() {
    const isNetworkConnected = await networkConnected();
    if (!isNetworkConnected) {
      return;
    }
    hapticWarning();
    const modal = await this.modalController.create({
      id: this.PENDING_TRANSACTION_MODAL_ID,
      component: PendingTransactionModalComponent,
      cssClass: 'pending-transaction-modal',
      animated: false,
    });
    this.timeoudId = setTimeout(
      () => this.handleTimeout(),
      this.TIMEOUT_DURATION
    );
    return await modal.present();
  }

  private clearTimeout(): void {
    if (!isNil(this.timeoudId)) {
      clearTimeout(this.timeoudId);
      this.timeoudId = undefined;
    }
  }

  private async handleTimeout(): Promise<void> {
    this.toastController.presentWarningToast(
      MESSAGES.transactionTimeoutWarning
    );
    await this.dismissPendingTransactionModal();
  }

  public checkAndPresentPendingTransactionModal<T>(): UnaryFunction<
    Observable<T>,
    Observable<T>
  > {
    return switchMap((payload) =>
      from(this.presentModal()).pipe(map(() => payload))
    );
  }

  public async dismissPendingTransactionModal() {
    this.clearTimeout();

    const modal = await this.modalController.getTop();
    if (isNil(modal)) {
      return;
    }
    if (!equals(modal.id, this.PENDING_TRANSACTION_MODAL_ID)) {
      return;
    }
    await this.modalController.dismiss(
      null,
      null,
      this.PENDING_TRANSACTION_MODAL_ID
    );
  }
}
