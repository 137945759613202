import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  changeTripStep,
  createTrip,
  endTrip,
  endTripFailure,
  endTripSuccessful,
  lockLogbook,
  lockLogbookSuccessful,
  lockPreTrip,
  lockPriorEmergencyNotice,
  lockPriorEmergencyNoticeSuccessful,
  lockRetainNotice,
  lockRetainNoticeSuccessful,
  lockWeightNotice,
  lockWeightNoticeSuccessful,
  resetEndTripViewStatus,
  resetTrip,
  submitCDR,
  submitCDRFailure,
  submitCDRSuccessful,
  submitNilFishing,
  submitPreTripSuccessful,
  tripStepChange,
  updateActivityNoticeStatus,
  updateCatchDisposalLogbookEnabled,
} from './trip.actions';
import {
  catchError,
  filter,
  map,
  skip,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { TripService } from './trip.service';
import { log } from '../activity-log/activity-log.actions';
import {
  submitPriorEmergencyNoticeActivityLog,
  submitPriorEmergencyNoticeSuccessActivityLog,
} from '../prior-emergency-notice-page/prior-emergency-notice-page-activity-log';
import { qldDateTime, utcNow } from '@qld-recreational/moment';
import {
  addBackgroundRequest,
  submitBackgroundRequestFailure,
  updateBackgroundRequest,
} from '../background-request/background-request.actions';
import {
  BackgroundRequestPath,
  BackgroundRequestService,
} from '../background-request/background-request.service';
import { equals, includes, isNil, isNilOrEmpty } from '@qld-recreational/ramda';
import {
  ActivityEmergencyCreate,
  ActivityPreTripCreate,
  ActivityStatus,
  Page,
  TripTypeId,
} from '../api/model';
import {
  IUserBucketState,
  selectActivityNoticeEnabled,
} from '../user-bucket/user-bucket.reducer';
import { Store } from '@ngrx/store';
import { ITripState } from './trip.reducer';
import {
  selectActivityNoticeStatus,
  selectEndTripRequestId,
  selectPreTrip,
  selectTripId,
  selectTripState,
  selectTripStep,
} from './trip.selectors';
import {
  submitRetainNoticeActivityLog,
  submitRetainNoticeSuccessActivityLog,
} from '../retain-notice-page/retain-notice-page-activity-log';
import {
  IPriorEmergencyNoticeState,
  selectValidQuotaCatches,
} from '../prior-emergency-notice/prior-emergency-notice.reducer';
import { TripStep } from './trip-step';
import {
  submitWeightNoticeActivityLog,
  submitWeightNoticeSuccessActivityLog,
} from '../weight-notice-page/weight-notice-page-activity-log';
import {
  submitNilFishingActivityLog,
  submitNilFishingSuccessActivityLog,
} from '../nil-fishing/nil-fishing-activitiy-log';
import { ApiService } from '../api/api.service';
import { of, throwError } from 'rxjs';
import { PendingTransactionModalService } from '../pending-transaction-modal/pending-transaction-modal.service';
import {
  preTripSubmitFailureActivityLog,
  preTripSubmitSuccessActivityLog,
} from '../pre-trip-page/pre-trip-page-activity-log';
import { AlertService } from '@qld-recreational/alert';
import { IActivityLogState } from '../activity-log/activity-log.reducer';
import { DisposalMethod } from '../shared/models/CDR';
import { StorageSyncActions } from '../reducers/ngrx-ionic-storage-sync';
import { NavController } from '@ionic/angular';
import { ToastService } from '@qld-recreational/toast';
import { endTripSuccessfulMessage, MESSAGES } from '../messages';
import * as Sentry from '@sentry/browser';

@Injectable()
export class TripEffects {
  public subscribeTripStepChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StorageSyncActions.HYDRATED),
      switchMap(() => this.tripStore.select(selectTripStep)),
      skip(1),
      map((tripStep) => tripStepChange({ tripStep }))
    )
  );

  public updateActivityNoticeStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createTrip),
      switchMap(() =>
        this.userBucketStore.select(selectActivityNoticeEnabled).pipe(take(1))
      ),
      map((activityNoticeStatus) =>
        updateActivityNoticeStatus({ activityNoticeStatus })
      )
    )
  );

  public lockPretrip$ = createEffect(() =>
    this.actions$.pipe(
      ofType(lockPreTrip),
      map(({ preTrip, vesselStatusMessage }) =>
        addBackgroundRequest({
          backgroundRequest: {
            path: BackgroundRequestPath.PreTrip,
            payload: preTrip,
            activityLogData: preTripSubmitSuccessActivityLog({
              tripCorrelationID: preTrip.tripCorrelationID,
              licence: preTrip.primaryCommercialFishingLicence,
              vesselStatusMessage: includes(preTrip.tripTypeID, [
                TripTypeId.Charter,
                TripTypeId.Recreational,
              ])
                ? undefined
                : vesselStatusMessage,
              purpose: this.tripService.getTripPurpose(preTrip.tripTypeID),
            }),
          },
        })
      )
    )
  );

  public submitPreTripSuccessful$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateBackgroundRequest),
      filter(
        ({
          backgroundRequest: {
            changes: { path },
          },
        }) => equals(path, BackgroundRequestPath.PreTrip)
      ),
      map(
        ({
          backgroundRequest: {
            changes: { transactionNumber, payload },
          },
        }) =>
          submitPreTripSuccessful({
            receipt: Number(transactionNumber),
          })
      )
    )
  );

  public submitPreTripFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(submitBackgroundRequestFailure),
      filter(
        ({
          backgroundRequest: {
            changes: { path },
          },
        }) => equals(path, BackgroundRequestPath.PreTrip)
      ),
      map(
        ({
          backgroundRequest: {
            changes: { payload },
          },
        }) =>
          log({
            activityLog: preTripSubmitFailureActivityLog(
              (payload as ActivityPreTripCreate).primaryCommercialFishingLicence
            ),
          })
      )
    )
  );

  public createTrip$ = createEffect(() =>
    this.actions$.pipe(
      ofType(submitPreTripSuccessful),
      tap(({ receipt }) =>
        this.alertService.presentSingleActionAlert(
          'Pre-trip submitted successfully',
          `Transaction number is <span>${receipt}</span>`
        )
      ),
      withLatestFrom(this.tripStore.select(selectPreTrip)),
      switchMap(([_, { tripCorrelationID, tripTypeID }]) =>
        equals(tripTypeID, TripTypeId.Commercial)
          ? [createTrip({ id: tripCorrelationID })]
          : []
      )
    )
  );

  public checkIfUserHasQuotaForAllSpecies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(lockLogbook),
      switchMap(() =>
        this.tripService.getLogbookCatchesAndQuotaAccountForTrip()
      ),
      filter(([caught, authorityQuotas]) =>
        caught.some((quotaSymbol) => !authorityQuotas.has(quotaSymbol))
      ),
      tap(() =>
        this.toastService.presentWarningToast(
          MESSAGES.reportingQuotaSpeciesWithoutQuotaReminder
        )
      ),
      withLatestFrom(this.tripStore.select(selectTripId)),
      map(([, tripCorrelationID]) =>
        log({
          activityLog: {
            tripCorrelationID,
            timestamp: utcNow(),
            action: 'Lock Logbook',
            page: Page.Logbook,
            message:
              'Quota fish taken without holding required quota authority.',
          },
        })
      )
    )
  );

  public lockLogbook$ = createEffect(() =>
    this.actions$.pipe(
      ofType(lockLogbook),
      switchMap(() => this.tripService.constructSubmitLogbookPayload()),
      switchMap((logbookCreate) =>
        this.tripService.logbookHasNoQuotaCatch().pipe(
          take(1),
          map((logbookHasNoQuotaCatch) =>
            lockLogbookSuccessful({
              logbookCreate,
              noQuota: logbookHasNoQuotaCatch,
            })
          )
        )
      )
    )
  );

  public getShouldCDREnabled$ = createEffect(() =>
    this.actions$.pipe(
      ofType(lockLogbookSuccessful),
      filter(({ noQuota }) => !noQuota),
      switchMap(() => this.tripService.ifCDRShouldBeEnabled()),
      map((catchDisposalLogbookEnabled) =>
        updateCatchDisposalLogbookEnabled({ catchDisposalLogbookEnabled })
      )
    )
  );

  public lockPriorEmergencyNotice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(lockPriorEmergencyNotice),
      switchMap(() =>
        this.tripService
          .constructSubmitPriorEmergencyNoticePayload()
          .pipe(
            map((priorEmergencyNoticeCreate) =>
              lockPriorEmergencyNoticeSuccessful({ priorEmergencyNoticeCreate })
            )
          )
      )
    )
  );

  public submitNilFishing$ = createEffect(() =>
    this.actions$.pipe(
      ofType(submitNilFishing),
      switchMap(({ nilFishingCreate }) => [
        log({
          activityLog: submitNilFishingActivityLog(utcNow(), nilFishingCreate),
        }),
        addBackgroundRequest({
          backgroundRequest: {
            path: BackgroundRequestPath.NilFishing,
            payload: nilFishingCreate,
            activityLogData: submitNilFishingSuccessActivityLog,
          },
        }),
      ])
    )
  );

  public submitPriorEmergencyNotice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(lockPriorEmergencyNoticeSuccessful),
      switchMap(({ priorEmergencyNoticeCreate }) => {
        const isPriorNotice = isNilOrEmpty(
          (priorEmergencyNoticeCreate as ActivityEmergencyCreate).landingPoint
        );
        return [
          log({
            activityLog: submitPriorEmergencyNoticeActivityLog({
              tripCorrelationID: priorEmergencyNoticeCreate.tripCorrelationID,
              createdDateTime: utcNow(),
              isPriorNotice,
            }),
          }),
          addBackgroundRequest({
            backgroundRequest: {
              path: isPriorNotice
                ? BackgroundRequestPath.PriorNotice
                : BackgroundRequestPath.EmergencyNotice,
              payload: priorEmergencyNoticeCreate,
              activityLogData: submitPriorEmergencyNoticeSuccessActivityLog(
                priorEmergencyNoticeCreate.tripCorrelationID,
                isPriorNotice
              ),
            },
          }),
        ];
      })
    )
  );

  public updateTripStepAfterLockPriorEmergencyNoticeSuccessful$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(lockPriorEmergencyNoticeSuccessful),
        switchMap(() =>
          this.tripStore.select(selectActivityNoticeStatus).pipe(take(1))
        ),
        withLatestFrom(
          this.priorEmergencyNoticeStore.select(selectValidQuotaCatches)
        ),
        map(
          ([
            { weightActivityNoticeEnabled, retainActivityNoticeEnabled },
            quotaCatches,
          ]) => {
            if (
              !retainActivityNoticeEnabled ||
              quotaCatches.every(
                (quotaCatch) => !quotaCatch.retainActivityNoticeEnabled
              )
            ) {
              return changeTripStep({ tripStep: TripStep.WeightNotices });
            }
            return changeTripStep({ tripStep: TripStep.RetainWeightNotices });
          }
        )
      )
  );

  public lockRetainNotice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(lockRetainNotice),
      switchMap(() => this.tripService.constructSubmitRetainNoticePayload()),
      map((retainNoticeCreate) =>
        lockRetainNoticeSuccessful({ retainNoticeCreate })
      )
    )
  );

  public submitRetainNotice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(lockRetainNoticeSuccessful),
      switchMap(({ retainNoticeCreate }) => {
        return [
          log({
            activityLog: submitRetainNoticeActivityLog({
              tripCorrelationID: retainNoticeCreate.tripCorrelationID,
              createdDateTime: utcNow(),
            }),
          }),
          addBackgroundRequest({
            backgroundRequest: {
              path: BackgroundRequestPath.RetainNotice,
              payload: retainNoticeCreate,
              activityLogData: submitRetainNoticeSuccessActivityLog(
                retainNoticeCreate.tripCorrelationID
              ),
            },
          }),
        ];
      })
    )
  );

  public lockWeightNotice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(lockWeightNotice),
      switchMap(({ id }) =>
        this.tripService.constructSubmitWeightNoticePayload(id)
      ),
      map(lockWeightNoticeSuccessful)
    )
  );

  public submitWeightNotice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(lockWeightNoticeSuccessful),
      switchMap(({ weightNoticeCreate, localId, id }) => {
        return [
          log({
            activityLog: submitWeightNoticeActivityLog({
              tripCorrelationID: weightNoticeCreate.tripCorrelationID,
              createdDateTime: utcNow(),
              localId,
            }),
          }),
          addBackgroundRequest({
            backgroundRequest: {
              path: BackgroundRequestPath.WeightNotice,
              payload: weightNoticeCreate,
              activityLogData: submitWeightNoticeSuccessActivityLog(
                weightNoticeCreate.tripCorrelationID,
                localId
              ),
              payloadId: id,
            },
          }),
        ];
      })
    )
  );

  public submitCDRSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(submitCDRSuccessful),
      map(({ logbookCreate }) =>
        this.tripService.getCDRLog(
          logbookCreate.tripCorrelationID,
          logbookCreate.disposalMethod,
          true
        )
      )
    )
  );

  public submitCDR$ = createEffect(() =>
    this.actions$.pipe(
      ofType(submitCDR),
      this.pendingTransactionModalService.checkAndPresentPendingTransactionModal(),
      switchMap(({ id }) =>
        this.tripService.constructSubmitCDRPayload(id).pipe(
          tap((logbookCreate) =>
            this.activityLogStore.dispatch(
              this.tripService.getCDRLog(
                logbookCreate.tripCorrelationID,
                logbookCreate.disposalMethod
              )
            )
          ),
          switchMap((logbookCreate) =>
            this.apiService.postLogbook(logbookCreate).pipe(
              switchMap(async () => {
                await this.pendingTransactionModalService.dismissPendingTransactionModal();
                return submitCDRSuccessful({ id, logbookCreate });
              }),
              catchError(async (error) => {
                await this.pendingTransactionModalService.dismissPendingTransactionModal();
                return submitCDRFailure({
                  id,
                  shouldUpdateRequestId: !isNil(error.status),
                });
              })
            )
          ),
          catchError(async () => {
            await this.pendingTransactionModalService.dismissPendingTransactionModal();
            return submitCDRFailure({ id });
          })
        )
      )
    )
  );

  public endTripLog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(endTripSuccessful),
      map(({ tripCorrelationID, page }) => {
        const timestamp = utcNow();
        return log({
          activityLog: {
            tripCorrelationID,
            timestamp,
            status: ActivityStatus.success,
            page,
            action: 'End Trip',
            message: `User ended trip at ${qldDateTime(timestamp)}`,
          },
        });
      })
    )
  );

  public endTrip$ = createEffect(() =>
    this.actions$.pipe(
      ofType(endTrip),
      switchMap(({ page, pollingStartedWhenAttempt }) =>
        this.tripStore.select(selectTripState).pipe(
          take(1),
          map(
            ({
              id,
              preTrip: {
                primaryCommercialFishingLicence,
                commercialFisherLicence,
              },
            }) => ({
              tripCorrelationID: id,
              primaryCommercialFishingLicence,
              commercialFisherLicence,
              createdDateTime: utcNow(),
            })
          ),
          tap((activityEndTripCreate) =>
            Sentry.captureMessage(
              `${activityEndTripCreate.tripCorrelationID}::End trip payload: ` +
                JSON.stringify(activityEndTripCreate)
            )
          ),
          withLatestFrom(this.tripStore.select(selectEndTripRequestId)),
          tap(([{ tripCorrelationID }, requestId]) =>
            Sentry.captureMessage(
              `${tripCorrelationID}::End trip request id: ` + requestId
            )
          ),
          switchMap(([activityEndTripCreate, requestId]) =>
            this.backgroundRequestService.checkConnectivity().pipe(
              take(1),
              switchMap((isConnected) =>
                isConnected
                  ? this.apiService
                      .endTrip({
                        ...activityEndTripCreate,
                        requestId,
                      })
                      .pipe(
                        tap(() =>
                          Sentry.captureMessage(
                            `${activityEndTripCreate.tripCorrelationID}::End trip request success`
                          )
                        ),
                        map(() =>
                          endTripSuccessful({
                            tripCorrelationID:
                              activityEndTripCreate.tripCorrelationID,
                            page,
                            pollingStartedWhenAttempt,
                          })
                        )
                      )
                  : throwError(() => new Error('No internet connection'))
              ),
              catchError((error) => {
                Sentry.captureMessage(
                  `${activityEndTripCreate.tripCorrelationID}::End trip request failure with ${error.status}`
                );
                Sentry.captureEvent(error);
                Sentry.captureMessage(
                  `${activityEndTripCreate.tripCorrelationID}::End trip error message` +
                    error.message
                );
                Sentry.captureMessage(
                  `${activityEndTripCreate.tripCorrelationID}::End trip error` +
                    error
                );
                return of(
                  endTripFailure({
                    page,
                    shouldUpdateRequestId: !isNil(error.status),
                  })
                );
              })
            )
          )
        )
      )
    )
  );

  public endTripSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(endTripSuccessful),
      tap(({ page, pollingStartedWhenAttempt }) => {
        if (!equals(page, Page.PreTrip)) {
          this.toastService.presentSuccessToast(
            endTripSuccessfulMessage(pollingStartedWhenAttempt),
            '',
            true
          );
        }
      }),
      map(() => resetTrip())
    )
  );

  public endTripFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(endTripFailure),
      tap(({ page }) => {
        if (!equals(page, Page.PreTrip)) {
          this.toastService.presentFailureToast(MESSAGES.failedToEndTrip);
        }
      }),
      map(() => resetEndTripViewStatus())
    )
  );

  public resetTrip$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(resetTrip),
        tap(() => {
          this.navController.navigateBack(['/home']);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private tripService: TripService,
    private apiService: ApiService,
    private alertService: AlertService,
    private toastService: ToastService,
    private userBucketStore: Store<IUserBucketState>,
    private tripStore: Store<ITripState>,
    private priorEmergencyNoticeStore: Store<IPriorEmergencyNoticeState>,
    private pendingTransactionModalService: PendingTransactionModalService,
    private activityLogStore: Store<IActivityLogState>,
    private navController: NavController,
    private backgroundRequestService: BackgroundRequestService
  ) {}
}
