import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ManualPollingService } from './manual-polling.service';
import { EffectsModule } from '@ngrx/effects';
import { ManualPollingEffects } from './manual-polling.effects';
import { manualPollingFeatureKey, reducer } from './manual-polling.reducer';
import { StoreModule } from '@ngrx/store';
import { OpenNativeSettings } from '@awesome-cordova-plugins/open-native-settings/ngx';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(manualPollingFeatureKey, reducer),
    EffectsModule.forFeature([ManualPollingEffects]),
  ],
  providers: [ManualPollingService, OpenNativeSettings],
})
export class ManualPollingModule {}
