import { createAction, props } from '@ngrx/store';

export const login = createAction('[Auth] Login');

export const loginSuccess = createAction(
  '[Auth] Login Success',
  props<{ email: string }>()
);

export const loginFailure = createAction(
  '[Auth] Login Failed',
  props<{ error: string }>()
);

export const switchUser = createAction('[Auth] Switch User');

export const logout = createAction('[Auth] Logout');

export const logoutSuccess = createAction('[Auth] Logout Success');

export const logoutFailure = createAction(
  '[Auth] Logout Failed',
  props<{ error: string }>()
);
