import { createAction, props } from '@ngrx/store';
import { VmsStatus } from '../api/model';
import { Page } from '../api/model';

export const loadVMS = createAction('[Vms] Load VMS', props<{ page: Page }>());

export const loadVMSSuccessful = createAction(
  '[VMS] Load VMS Successful',
  props<{ page: Page; vmsStatus: VmsStatus }>()
);

export const loadVMSFailed = createAction('[VMS] Load VMS Failed');
