import { ActivityStatus, Page } from '../api/model/activityLogPostEntry';
import { utcNow } from '@qld-recreational/moment';

export const loadVMSSuccessActivityLog = (
  page: Page = Page.Login,
  tripCorrelationID: string
) => ({
  tripCorrelationID,
  page,
  action: 'Load vessel tracking units',
  timestamp: utcNow(),
  message: `Vessel tracking unit/s loaded successfully.`,
  status: ActivityStatus.success,
});
