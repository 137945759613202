export interface Envs {
  azureScope: string;
  azureClientId: string;
  azureWebRedirectUrl: string;
  azureMobileRedirectUrl: string;
  azureDiscoveryUrl: string;
  azureAuthorizationBaseUrl: string;
  azureAccessTokenEndPoint: string;
  apiBaseUrl: string;
  apiBaseUrlSV: string;
}

export enum TargetEnv {
  dev = 'dev',
  local = 'local',
  qa = 'qa',
  uat = 'uat',
  prod = 'prod',
  mock = 'mock',
}

export interface EnvMap {
  [key: string]: Envs;
}
