export const MESSAGES = {
  authSuccess: 'Login successful',
  authFailure:
    'Failed to login. There was an issue connecting to network, please try again later',
  noActiveCFL:
    'Qld eFisher can only be used by a commercial fisher licence (CFL) holder with an association to a PCFL. Check your licence validity and association by following FishNet help documents or call 13 25 23 for assistance.',
  noActivePCFL:
    'Qld eFisher can only be used by a commercial fisher licence (CFL) holder with an association to a PCFL. Check your licence validity and association by following FishNet help documents or call 13 25 23 for assistance.',
  loadVMSFailure:
    'The vessel tracking system is currently unavailable, please try again later. You can also confirm your unit is working through the AIVR (07) 3031 8283 or your personal platform with your provider.',
  noInternet:
    'Unable to load your user information. Please check your internet connection',
  loadUserBucketFailure:
    'The Fisheries system is currently unavailable, please try again later. If you want to confirm that your vessel tracking unit is operating call (07) 3031 8283 or your personal platform with your provider.',
  vesselAllWorking: 'Vessel tracking devices are all working properly',
  vesselNotWorking:
    'One or more vessel tracking devices are not working properly',
  emptyVessel: 'No vessel tracking devices registered for this authority',
  vesselNotAvailable: 'Vessel tracking device status is unavailable',
  noCatch: 'Note: 0 Catch recorded',
  noQuotaCatch: 'Note: No quota species recorded',
  noTepInteractions: 'Note: No TEP interaction recorded',
  logbookEffortSaveToast: 'Logbook effort is saved',
  tepSaveToast: 'TEP is saved',
  logbookCatchSaveToast: 'Logbook catch is saved',
  cdrCatchSaveToast: 'Fish detail is saved',
  cdrSaveToast: 'CDR is saved',
  submissionStatus:
    'Some of the submissions can happen even if you are not connected to the internet and sync later. <br/><br/>No further action is required',
  quotaToBeAdded:
    'Note: Are there quota species you need to prior that are not shown?',
  contactFishery: `If the quota species are not available - contact Fisheries' Quota unit`,
  emergencyNoticeNote:
    'An emergency notice can only be given in the event of a medical emergency or extreme weather, the authorised boat lands without giving a prior notice. The landing may be at another landing place other than that already stated.',
  incompleteWeightNoticeAlert: `Do you want to give a retained fish notice? Please note, any incomplete weight notices will be discarded.<br>Once a retained fish notice has been given you will not be able to give a weight notice afterwards.<br>Do you wish to continue?`,
  startRetainNoticeWithNoWeightNotice: `Do you want to give a retained fish notice? Once a retained fish notice has been created you will not be able to discard it, or give a weight notice afterwards.<br>Do you wish to continue?`,
  startRetainNoticeWithWeightNotice: `Once you give a retained fish notice, you will not be able to give another weight notice.<br>Do you wish to continue?`,
  quotaBalanceDescription:
    'Always ensure you hold sufficient quota or that the fishery is open before taking quota species.',
  quotaBalanceDescriptionHelper:
    'To view your current quota balances, press the refresh button on this screen when you are connected to the internet.',
  sharkAndRayWarning:
    'Any reported shark and/or ray (catch or discards) will not be submitted or quota reported. Are you sure you want to turn this off?',
  tepWarning:
    'Any reported TEP interactions will not be submitted. Are you sure you want to turn this off?',
  logbookNoEntry: 'Please make an entry in your logbook before ending the day',
  logbookNotCompleted:
    'Please complete all required logbook fields before ending the day',
  tepNotCompleted:
    'Please complete all required TEP fields before ending the day',
  deleteSpecies:
    'Are you sure you wish to remove the species from the CDR? Removing the species will delete the information for the species',
  noCDR:
    'Create a CDR for each disposal associated with the catch from this weight notice',
  retainedFishNotice:
    'If you have species from a retained fish notice, add them to the CDR with the Add species (incl. retained) button',
  noWeightNotice:
    'There is no weight for this trip. Add a weight notice to give the weight for your catch',
  noSpecies:
    'Please note: there must be at least one species in order to submit a CDR',
  noFishForm: 'Please add the fish form by measure that you want to dispose',
  cdrHelpingMessage:
    'Please note there must be at least one species by form and measure e.g. number of individuals or containers in order to submit a CDR',
  failedToCheckContentfulUpdate:
    'Failed to check content update, please try again later',
  failedToLoadContentful: 'Failed to init content, please try again later',
  failedToSubmitCDR: 'Failed to submit CDR, please try again later',
  failedToEndLogbookDay: 'Failed to end logbook day, please try again later',
  submitCDRSuccessful: 'CDR submission successful',
  failedToLogout: 'Fail to logout, please try again later',
  anotherCDRConfirm: 'Are you sure you want to create another CDR?',
  createCDRUnderRelevantWeightNotice:
    'Please ensure you create the CDR to the relevant weight notice for your fish. Do you wish to proceed?',
  giveWeightBeforeCDR: 'Please give your weight notice before creating a CDR',
  anotherWeightNotice: 'Do you wish to give another weight notice?',
  cdrPendingTransaction:
    'You have a pending transaction. Please ensure connectivity and submit again',
  endTripPendingTransaction:
    'You have pending transaction(s). Please ensure connectivity and end trip again',
  endTripUnfinishedWeightCDR:
    'You must complete the CDR/s for the catch associated with your weight notice before ending the trip',
  endTripFromRetainDisabledOrSubmitted:
    'Do you wish to end your trip? Have you given all required notices and CDR/s for your catch?',
  endTripFromRetainDisabledOrSubmittedWhenCDRDisabled:
    'Have you given all required notices for your catch?',
  endTripFromRetainEnabledButNotSubmitted:
    'Do you wish to end your trip? Have you given the weight notice(s) and relevant CDR(s) as required for all fish taken during the fishing operation?',
  failedToEndTrip:
    'Failed to end trip, please check your connectivity and/or try again later',
  unableToAmendNotice: `You aren't able to amend your landing place after landing`,
  refreshManualPolling:
    'Manual reporting has been active for 5 days. If you have obtained approval from the chief executive, click Ok to continue',
  rememberToStopManualPolling:
    'Please remember to turn off manual reporting after landing the relevant boat',
  reminderToStopManualPollingIfNotOnTrip:
    'To stop manual reporting you must press the Stop button',
  invalidAccessTokenError: 'Qld eFisher - Invalid Access Token',
  invalidAccessToken:
    'Invalid token. Please check your internet connection or logout and login again when you have stable internet connection',
  submitLogbookConfirmation:
    'I certify that the information I have provided on this form is a complete and accurate record',
  startDayConfirmation: 'Are you sure you want to start another day?',
  deleteLogbookDayConfirmation:
    'Are you sure you want to delete this day? Any associated logbook entries for this day will be deleted',
  deleteLogbookEventWithoutTEPConfirmation:
    'Are you sure you want to delete this entry? Any catch and effort for this entry will also be deleted',
  deleteLogbookEventWithTEPConfirmation:
    'Deleting this logbook entry will also delete any associated TEP logbook entries. Do you want to continue?',
  deleteTepEventConfirmation:
    'Are you sure you want to delete this entry? Any details for this entry will also be deleted',
  deleteWeightNoticeConfirmation:
    'Are you sure you want to delete this weight notice? Any CDRs associated with this weight notice will also be deleted',
  deleteCDRConfirmation: 'Are you sure you want to delete this CDR?',
  cancelPreTripConfirmation:
    'I confirm I have not commenced my fishing operation and departed the landing location',
  shotOrderMessage: 'Enter your shot data in the order you did the shots.',
  manualPollingMissingBoatmark:
    'There is no boat mark associated with the selected PCFL.  A boat mark is required to use the manual reporting feature, please select a relevant PCFL.',
  preferenceCDRFishFormsNoLogbook:
    'Your licences do not require CDR reporting.',
  transactionTimeoutWarning:
    'Your submission is taking longer than usual, please check that you have reliable service. <b>Only if you have full service</b> log out of the app then log back in. \nIf your transaction remains pending, call 13 25 23. DO NOT uninstall/delete the app as all pending submissions and preferences will be lost.',
  addFilletGroupPrompt:
    'Fillets of red throat emperor, spangled emperor, red emperor, goldband snapper, saddletail snapper and stripey snapper must be reported individually and not grouped by the filleting species groups below.',
  cdrCatchReminderNextScreenText:
    'On your next screen, you will be shown the species information entered in your logbook to guide you in CDR completion. This does not account for form changes after you completed the logbook.',
  cdrCatchReminderReported:
    'For this commercial fishing operation for the species you reported:',
  retainNoticeDescription:
    'This notice is for permitted quota species you intend on taking back out on a subsequent fishing operation, to be included in your next prior notice. This is the last notice you can give - ensure any required weight notices for unloaded fish have been given first.',
  sharkRayReminder:
    'Please ensure you select the correct region where you caught the shark/ray',
  reportingQuotaSpeciesWithoutQuotaReminder:
    "ALERT: You do not hold a relevant quota account for some of the species taken. Please contact Fisheries Queensland's Reporting team on 13 25 23.",
};

export const logbookSummaryDisclaimerMessage = (date: Date) =>
  `This information is accurate as of ${date.toLocaleDateString('default', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  })} ${date.toLocaleTimeString('default', {
    hour12: false,
    hour: '2-digit',
    minute: '2-digit',
  })} and does not account for any fish form changes since the creation of the logbook entry.`;

export const endTripFromLogbookMessage = (pollingStarted: boolean) => {
  const commonMessage =
    'Ending your trip will remove your ability to have the logbook available for immediate inspection. Do not end your trip until the fish taken during the trip are no longer in your physical possession. Do you wish to continue?';
  const manualPollingMessage =
    '<br><br>The vessel tracking - manual reporting feature will be turned off when you end your trip. If you’re no longer required to manually report but don’t want to end your trip yet, please stop manual reporting from the app feature';
  return pollingStarted ? commonMessage + manualPollingMessage : commonMessage;
};

export const endTripSuccessfulMessage = (pollingStarted: boolean) => {
  const commonMessage =
    'Trip ended successfully. Qld eFisher has refreshed and is ready for your next fishing operation';
  const manualPollingMessage =
    '. The vessel tracking - manual reporting feature has been turned off';
  return pollingStarted ? commonMessage + manualPollingMessage : commonMessage;
};
