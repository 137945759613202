import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Browser } from '@capacitor/browser';
import { Platform } from '@ionic/angular';
import { hapticLight } from '@qld-recreational/haptic';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'qld-commercial-version-mismatch-page',
  templateUrl: './version-mismatch-page.component.html',
  styleUrls: ['./version-mismatch-page.component.scss'],
})
export class VersionMismatchPageComponent implements OnInit {
  public title$: Observable<string>;

  constructor(private platform: Platform, private route: ActivatedRoute) {}

  public ngOnInit(): void {
    this.title$ = this.route.queryParams.pipe(map((params) => params.from));
  }

  public async updateApp() {
    hapticLight();
    await Browser.open({
      url: this.platform.is('android')
        ? 'https://play.google.com/store/apps/details?id=au.gov.qld.daff.qldecatch'
        : 'https://apps.apple.com/au/app/qld-ecatch/id1578978864',
      presentationStyle: 'popover',
    });
  }
}
