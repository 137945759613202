import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ISettingState, settingsFeatureKey } from './settings.reducer';

export const selectSettingsState = createFeatureSelector<ISettingState>(
  settingsFeatureKey
);

export const selectTargetEnv = createSelector(
  selectSettingsState,
  (state) => state.targetEnv
);

export const selectTargetEnvs = createSelector(
  selectSettingsState,
  (state) => state.environments[state.targetEnv]
);

export const selectEnvironments = createSelector(
  selectSettingsState,
  (state) => state.environments
);

export const selectApiUrl = createSelector(
  selectTargetEnvs,
  (env) => env?.apiBaseUrl
);

export const selectApiUrlSV = createSelector(
  selectTargetEnvs,
  (env) => env?.apiBaseUrlSV
);

export const selectAzureWebRedirectUrl = createSelector(
  selectTargetEnvs,
  (env) => env?.azureWebRedirectUrl
);

export const selectAzureAuthBaseUrl = createSelector(
  selectTargetEnvs,
  (env) => env?.azureAuthorizationBaseUrl
);

export const selectContentfulContext = createSelector(
  selectSettingsState,
  (state: ISettingState) => state.contentfulContext
);
