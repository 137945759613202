/**
 * Queensland Fisheries FishNet API
 * Queensland Fisheries FishNet API
 *
 * OpenAPI spec version: 1.0.2
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export enum ActivityStatus {
  success = 'SUCCESS',
  pending = 'PENDING',
  error = 'ERROR',
}

export enum Page {
  Login = 'Login page',
  NilFishing = 'Record day/s not commercially fished page',
  PreTrip = 'Pre-trip page',
  AmendingNotice = 'Amending notice page',
  Logbook = 'Logbook page',
  EmergencyNotice = 'Emergency notice page',
  PriorNotice = 'Prior notice page',
  Home = 'Home page',
  RetainNotice = 'Retain notice page',
  WeightNotice = 'Weight notice page',
  QuotaBalance = 'Quota balance page',
  VesselTracking = 'Vessel tracking page',
  ManualPolling = 'Manual report page',
}

export interface ActivityLogPostEntry {
  tripCorrelationID?: string;
  page?: string;
  action?: string;
  timestamp?: string;
  message?: string;
  status?: string;
}
