<ion-header>
  <ion-toolbar>
    <ion-buttons collapse="true" slot="start">
      <ion-back-button
        defaultHref="/home"
        color="dark"
        text=""
      ></ion-back-button>
    </ion-buttons>
    <ion-title> {{ (title$ | async) ?? 'Version Mismatch'}} </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content [forceOverscroll]="false">
  <div class="center-container">
    <div class="ion-text-center ion-padding">
      <p>
        A new version of Qld eFisher with enhancements based on your feedback is
        available to download from the store. Please update to continue.
      </p>
      <ion-button class="main-button" (click)="updateApp()">Update</ion-button>
    </div>
  </div></ion-content
>
