import { ActivityLogPostEntry } from '../api/model';

export const stripHTML = (message: string): string =>
  message?.replace(/<[^>]+>/gi, '');

export const stripLineBreak = (message: string): string =>
  message?.replace(/\n/gi, '');

export const stripActivityLogsHTML = (activityLogs: ActivityLogPostEntry[]) => {
  try {
    return activityLogs.map((activityLog) => ({
      ...activityLog,
      message: stripLineBreak(stripHTML(activityLog.message)).trim(),
    }));
  } catch (e) {
    return activityLogs;
  }
};
