import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  filter,
  finalize,
  map,
  switchMap,
  take,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { ManualPollingService } from './manual-polling.service';
import {
  appTrackLocation,
  locationReceived,
  startManualPollingFailed,
  startManualPollingSuccessful,
  startPolling,
  stopPolling,
  stopPollingFinished,
  stopPollingIfPollingHasStarted,
  updateSubmitStatus,
} from './manual-polling.actions';
import { combineLatest, from, of, timer } from 'rxjs';
import {
  addBackgroundRequest,
  updateBackgroundRequest,
} from '../background-request/background-request.actions';
import { BackgroundRequestPath } from '../background-request/background-request.service';
import { ILocation } from './manual-polling';
import { Store } from '@ngrx/store';
import { ITripState } from '../trip/trip.reducer';
import { selectPreTrip, selectTripStep } from '../trip/trip.selectors';
import { equals, omit } from '@qld-recreational/ramda';
import { ManualPositions, Page } from '../api/model';
import { log } from '../activity-log/activity-log.actions';
import {
  startPollingActivityLog,
  stopPollingActivityLog,
} from '../manual-polling-page/manual-polling-activity-log';
import {
  IManualPollingState,
  selectPollingData,
  selectPollingStarted,
  selectStartDateTime,
} from './manual-polling.reducer';
import * as moment from 'moment';
import { lockLogbookSuccessful } from '../trip/trip.actions';
import { ToastService } from '@qld-recreational/toast';
import { MESSAGES } from '../messages';
import { ModalController } from '@ionic/angular';
import { utcNow } from '@qld-recreational/moment';
import { TripStep } from '../trip/trip-step';
import { lockPreTripPage, unlockPreTripPage } from '../home/home.actions';

@Injectable()
export class ManualPollingEffects {
  private readonly CHECK_5DAYS_LIMIT_INTERVAL = 60 * 1_000;

  public appInitTrack$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(appTrackLocation),
        tap(() => this.manualPollingService.init())
      ),
    { dispatch: false }
  );

  public logFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(startManualPollingFailed),
      map(() =>
        log({
          activityLog: {
            timestamp: utcNow(),
            page: Page.ManualPolling,
            action: 'Deny location permissions',
            message: 'Location services not allowed by user',
          },
        })
      )
    )
  );

  public startPolling$ = createEffect(() =>
    this.actions$.pipe(
      ofType(startPolling),
      switchMap((payload) =>
        from(this.manualPollingService.requestLocationPermission()).pipe(
          map((status) => startManualPollingSuccessful({ status, ...payload })),
          catchError(() => of(startManualPollingFailed())),
          finalize(
            async () =>
              (await this.modalController.getTop()) &&
              this.modalController.dismiss()
          )
        )
      )
    )
  );

  public notifyStopPollingAfterLanding = createEffect(
    () =>
      this.actions$.pipe(
        ofType(lockLogbookSuccessful),
        switchMap(() =>
          this.manualPollingStore.select(selectPollingStarted).pipe(take(1))
        ),
        filter((trackStarted) => trackStarted),
        tap(() =>
          this.toastService.presentSuccessToast(
            MESSAGES.rememberToStopManualPolling
          )
        )
      ),
    { dispatch: false }
  );

  public remindStopPollingIfNotOnTrip$ = createEffect(() =>
    this.actions$.pipe(
      ofType(startManualPollingSuccessful),
      withLatestFrom(this.tripStore.select(selectTripStep)),
      filter(([_, tripStep]) => equals(tripStep, TripStep.PreTrip)),
      tap(() => {
        this.toastService.presentWarningToast(
          MESSAGES.reminderToStopManualPollingIfNotOnTrip
        );
      }),
      map(() => lockPreTripPage())
    )
  );

  public startReceiveLocation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(startManualPollingSuccessful),
        switchMap(() =>
          this.manualPollingStore.select(selectStartDateTime).pipe(take(1))
        ),
        filter((startDateTime) =>
          this.pollingHasNotStartedFor5Days(startDateTime)
        ),
        map(() => this.manualPollingService.startPolling())
      ),
    { dispatch: false }
  );

  public startPollingActivityLog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(startManualPollingSuccessful),
      switchMap(() =>
        combineLatest([
          this.tripStore.select(selectPreTrip),
          this.manualPollingStore.select(selectPollingData),
        ]).pipe(take(1))
      ),
      map(([pretrip, data]) =>
        log({
          activityLog: startPollingActivityLog(
            pretrip?.tripCorrelationID,
            data?.boatMark
          ),
        })
      )
    )
  );

  public check5DaysLimit$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(startManualPollingSuccessful),
        switchMap(() =>
          timer(0, this.CHECK_5DAYS_LIMIT_INTERVAL).pipe(
            takeUntil(
              this.manualPollingStore
                .select(selectPollingStarted)
                .pipe(filter((trackStarted) => !trackStarted))
            ),
            switchMap(() =>
              combineLatest([
                this.manualPollingStore.select(selectStartDateTime),
                this.manualPollingStore.select(selectPollingData),
              ]).pipe(take(1))
            ),
            map(([startDateTime, data]) => {
              if (!this.pollingHasNotStartedFor5Days(startDateTime)) {
                this.manualPollingService.handlePollingExceed5DaysLimit(data);
                return;
              }
            })
          )
        )
      ),
    { dispatch: false }
  );

  public submitLocation$ = createEffect(() => {
    let position: ILocation;
    return this.actions$.pipe(
      ofType(locationReceived),
      tap(({ location }) => (position = location)),
      switchMap(() =>
        combineLatest([
          this.tripStore.select(selectPreTrip),
          this.manualPollingStore.select(selectPollingData),
        ]).pipe(take(1))
      ),
      map(([pretrip, data]) => ({
        tripCorrelationID: pretrip?.tripCorrelationID,
        primaryCommercialFishingLicence: data?.licence,
        boatMark: data?.boatMark,
      })),
      switchMap(
        ({ tripCorrelationID, primaryCommercialFishingLicence, boatMark }) =>
          of(
            addBackgroundRequest({
              backgroundRequest: {
                path: BackgroundRequestPath.ManualPositions,
                payload: {
                  positions: [
                    {
                      tripCorrelationID,
                      licence: primaryCommercialFishingLicence,
                      boatmark: boatMark,
                      locations: [omit('status', position)],
                    },
                  ],
                },
              },
            })
          )
      )
    );
  });

  public stopPollingIfPollingHasStarted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(stopPollingIfPollingHasStarted),
      switchMap(() =>
        this.manualPollingStore.select(selectPollingStarted).pipe(take(1))
      ),
      filter((pollingStarted) => pollingStarted),
      map(() => stopPolling())
    )
  );

  public stopPolling$ = createEffect(() =>
    this.actions$.pipe(
      ofType(stopPolling),
      tap(() => this.manualPollingService.stopPolling()),
      switchMap(() =>
        combineLatest([
          this.tripStore.select(selectPreTrip),
          this.manualPollingStore.select(selectPollingData),
        ]).pipe(take(1))
      ),
      switchMap(([pretrip, data]) => [
        log({
          activityLog: stopPollingActivityLog(
            pretrip?.tripCorrelationID,
            data?.boatMark
          ),
        }),
        stopPollingFinished(),
        unlockPreTripPage(),
      ])
    )
  );

  public backgroundRequestSubmitted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateBackgroundRequest),
      filter(
        ({
          backgroundRequest: {
            changes: { path },
          },
        }) => equals(path, BackgroundRequestPath.ManualPositions)
      ),
      map(
        ({
          backgroundRequest: {
            changes: { payload },
          },
        }) =>
          (payload as ManualPositions).positions[0].locations.map(
            (location) => location.effectiveDateTime
          )
      ),
      map((effectiveDateTimes) => updateSubmitStatus({ effectiveDateTimes }))
    )
  );

  private pollingHasNotStartedFor5Days = (startDateTime: string) =>
    moment(startDateTime).add(5, 'd').isAfter(moment());

  constructor(
    private actions$: Actions,
    private manualPollingService: ManualPollingService,
    private tripStore: Store<ITripState>,
    private toastService: ToastService,
    private manualPollingStore: Store<IManualPollingState>,
    private modalController: ModalController
  ) {}
}
