import {
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { switchUser } from '../auth/auth.actions';
import { clearSessionData } from '../settings/settings.actions';
import {
  addLogbookEventPendingSpecies,
  addLogbookEventSuccess,
  deleteLogbookEvent,
  deleteLogbookEvents,
  deleteTepEventOfLogbook,
  updateLogbookEvent,
  updateLogbookEvents,
} from './logbook-events.actions';
import { equals, includes, isNil, isNilOrEmpty } from '@qld-recreational/ramda';
import { selectLogbookDays } from '../logbook-days/logbook-days.reducer';
import { LocationType } from '../shared/models/Location';
import { LogbookField, LogbookSpecies } from '../api/model';
import { endTripSuccessful } from '../trip/trip.actions';
import { selectTEPLogbookReference } from '../user-bucket/user-bucket.reducer';
import { clone } from 'rambda';

export type CatchPageSpeciesWithoutQuotaSymbols = Omit<
  LogbookSpecies,
  'quotaSymbols'
> & { quotaSymbol: string };
export type CatchPageSpecies = CatchPageSpeciesWithoutQuotaSymbols & {
  quotaSymbol: string;
};

export interface IEventableFieldsValue {
  id: string;
  completed: boolean;
  isEmpty: boolean;
}

export interface IEventRegionsAndSpecies {
  [region: string]: CatchPageSpecies[];
}

export interface ILogbookEvent {
  id: string;
  logbookDayId: string;
  activityType?: number;
  fishingMethod?: string;
  gearCode?: string;
  locationType?: { [key: string]: LocationType };
  /*
  for tep event, completed means all the fields other than the potential fishing methods are completed
  fishing method is only required when activity type allows catch
  */
  completed?: boolean;
  pendingSpecies?: Array<CatchPageSpecies>;
  pendingSecondarySpecies?: Array<CatchPageSpecies>;
  eventableFields?: Array<LogbookField>;
  eventableFieldsValue?: Array<IEventableFieldsValue>;
  isSecondaryEnabled?: boolean;
  isTEPEnabled?: boolean;
  parentEventId?: string;
  speciesIncompleteStatus?: { [speciesId: string]: boolean };
  createdAt: string;
  allowsCatch: boolean;
}

export const logbookEventsFeatureKey = 'logbookEvents';

export interface ILogbookEventsState extends EntityState<ILogbookEvent> {}

export const adapter: EntityAdapter<ILogbookEvent> =
  createEntityAdapter<ILogbookEvent>({
    selectId: (logbookEvent) => logbookEvent.id,
  });

export const initialState: ILogbookEventsState = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(addLogbookEventSuccess, (state, { logbookEvent }) =>
    adapter.addOne(logbookEvent, state)
  ),
  on(updateLogbookEvent, (state, { logbookEvent }) => {
    const clonedLogbookEvent = { ...logbookEvent };
    if (!isNilOrEmpty(logbookEvent.eventableFieldsValue)) {
      clonedLogbookEvent.eventableFieldsValue =
        clonedLogbookEvent.eventableFieldsValue.slice().reverse();
    }
    return adapter.updateOne(
      {
        id: logbookEvent.id,
        changes: clonedLogbookEvent,
      },
      state
    );
  }),
  on(updateLogbookEvents, (state, { logbookEvents }) =>
    adapter.upsertMany(logbookEvents, state)
  ),
  on(deleteLogbookEvent, (state, { id }) =>
    adapter.removeOne(
      id,
      adapter.removeMany((event) => equals(event.parentEventId, id), state)
    )
  ),
  on(deleteLogbookEvents, (state, { ids }) =>
    adapter.removeMany(
      ids,
      adapter.removeMany((event) => includes(event.parentEventId, ids), state)
    )
  ),
  on(deleteTepEventOfLogbook, (state, { id }) =>
    adapter.removeMany((event) => equals(event.parentEventId, id), state)
  ),
  on(
    addLogbookEventPendingSpecies,
    (state, { id, speciesAdded, isSecondaryCatchPage }) => {
      const clonedLogbookEvent = clone(state.entities[id]);

      if (isNil(clonedLogbookEvent)) {
        return state;
      }

      const pendingSpeciesKey = isSecondaryCatchPage
        ? 'pendingSecondarySpecies'
        : 'pendingSpecies';

      clonedLogbookEvent[pendingSpeciesKey] = clonedLogbookEvent[
        pendingSpeciesKey
      ].filter(
        (species) =>
          !speciesAdded.some(
            (catchPageSpecies) =>
              equals(catchPageSpecies.species.id, species.id) &&
              equals(catchPageSpecies.species.quotaSymbol, species.quotaSymbol)
          )
      );

      return adapter.updateOne(
        {
          id,
          changes: clonedLogbookEvent,
        },
        state
      );
    }
  ),
  on(clearSessionData, switchUser, endTripSuccessful, () => initialState)
);

const { selectAll } = adapter.getSelectors();

const selectLogbookEventsState = createFeatureSelector<ILogbookEventsState>(
  logbookEventsFeatureKey
);

export const selectEvents = createSelector(selectLogbookEventsState, selectAll);

export const selectEventById = (id: string) =>
  createSelector(selectEvents, (events) =>
    events.find((event) => equals(event.id, id))
  );

export const selectEventSpeciesIncompleteStatusById = (id: string) =>
  createSelector(
    selectEvents,
    (events) =>
      events.find((event) => equals(event.id, id)).speciesIncompleteStatus
  );

export const selectEventableFieldsById = (id: string) =>
  createSelector(selectEventById(id), ({ eventableFields }) => eventableFields);

export const selectEventableFieldsValueById = (id: string) =>
  createSelector(
    selectEventById(id),
    (logbookEvent) => logbookEvent?.eventableFieldsValue
  );

export const selectEventsByLogbookDayId = (logbookDayId: string) =>
  createSelector(selectEvents, (events) =>
    events.filter((event) => equals(event.logbookDayId, logbookDayId))
  );

export const selectTEPEventsByParentEventId = (parentEventId: string) =>
  createSelector(selectEvents, (events) =>
    events.filter((event) => equals(event.parentEventId, parentEventId))
  );

export const selectIfTEPEventsByParentEventIdAllCompleted = (
  parentEventId: string
) =>
  createSelector(
    selectTEPLogbookReference,
    selectTEPEventsByParentEventId(parentEventId),
    (logbook, events) =>
      events.every((event) => {
        const allowsCatch = logbook.activityTypes.find((activityType) =>
          equals(activityType.id, event.activityType)
        ).allowsCatch;
        return event.completed && (!allowsCatch || !isNil(event.fishingMethod));
      })
  );

export const selectEventsByDate = (date: string) =>
  createSelector(selectEvents, selectLogbookDays, (events, logbookDays) =>
    events.filter(
      (event) =>
        !event.parentEventId &&
        includes(
          event.logbookDayId,
          logbookDays
            .filter((logbookDay) => equals(logbookDay.date, date))
            .map((logbookDay) => logbookDay.id)
        )
    )
  );
