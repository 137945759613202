import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  IUserBucketState,
  selectLogbooksReferenceByFisherySymbols,
  selectMyQuotaSymbolsForPriorEmergencyNotice,
  selectRetainedCatch,
} from '../user-bucket/user-bucket.reducer';
import {
  ILogbookCatchesState,
  selectCatches,
} from '../logbook-catches/logbook-catches.reducer';
import { defaultIfEmpty, map, mergeMap, switchMap, take } from 'rxjs/operators';
import { ITripState } from '../trip/trip.reducer';
import { selectPreTrip } from '../trip/trip.selectors';
import { combineLatest, forkJoin, Observable } from 'rxjs';
import { LogbookService } from '../shared/logbook.service';
import { constructQuotaCatches } from './constructQuotaCatches';
import { IQuotaConversions } from './prior-emergency-notice';
import { IQuotaCatch } from './prior-emergency-notice.reducer';
import { isNil } from '@qld-recreational/ramda';

@Injectable()
export class PriorEmergencyNoticeService {
  constructor(
    private tripStore: Store<ITripState>,
    private userBucketStore: Store<IUserBucketState>,
    private logbookCatchStore: Store<ILogbookCatchesState>,
    private logbookService: LogbookService
  ) {}

  public getMyQuotaSymbols(): Observable<IQuotaCatch[]> {
    return combineLatest([
      this.userBucketStore.select(selectMyQuotaSymbolsForPriorEmergencyNotice),
      this.userBucketStore.select(selectRetainedCatch),
      this.getLogbookCatchesWithFisherySymbol(),
      this.getAllLogbookQuotaConversions(),
    ]).pipe(
      take(1),
      map(
        ([
          quotaSymbols,
          retainedCatchFromPreviousTrip,
          catches,
          quotaConversions,
        ]) =>
          constructQuotaCatches(
            quotaSymbols,
            retainedCatchFromPreviousTrip,
            catches,
            quotaConversions
          )
      )
    );
  }

  private getAllLogbookQuotaConversions(): Observable<IQuotaConversions> {
    return this.tripStore.select(selectPreTrip).pipe(
      take(1),
      switchMap(({ primaryFisherySymbol, secondaryFisherySymbol }) =>
        this.userBucketStore.select(
          selectLogbooksReferenceByFisherySymbols([
            primaryFisherySymbol,
            secondaryFisherySymbol,
          ])
        )
      ),
      map((logbooks) =>
        logbooks.map(({ fisherySymbols, quota }) => ({ fisherySymbols, quota }))
      ),
      map((quotas) => quotas.flat())
    );
  }

  private getLogbookCatchesWithFisherySymbol() {
    return this.logbookCatchStore.select(selectCatches).pipe(
      take(1),
      map((logbookCatches) =>
        logbookCatches.filter(
          ({ quantity, discarded }) => !isNil(quantity) && !discarded
        )
      ),
      mergeMap((logbookCatches) =>
        forkJoin(
          logbookCatches.map((logbookCatch) =>
            this.logbookService
              .selectFisherySymbolByFishingEventId(logbookCatch.logbookEventId)
              .pipe(take(1))
          )
        ).pipe(
          defaultIfEmpty([]),
          map((fisherySymbols) =>
            fisherySymbols.map((fisherySymbol, i) => ({
              ...logbookCatches[i],
              fisherySymbol,
            }))
          )
        )
      )
    );
  }
}
