import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'qld-commercial-pending-transaction-modal',
  templateUrl: './pending-transaction-modal.component.html',
  styleUrls: ['./pending-transaction-modal.component.scss'],
})
export class PendingTransactionModalComponent implements OnInit {
  constructor() {}

  public ngOnInit(): void {}
}
