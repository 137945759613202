import {
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from '@ngrx/store';
import { ICDRConsignment } from '../shared/models/CDR';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { equals } from '@qld-recreational/ramda';
import {
  deleteConsignmentPreference,
  loadConsignmentPreferences,
  upsertConsignmentPreference,
} from './preferences-consignment.action';
import { clearSessionData } from '../settings/settings.actions';
import { switchUser } from '../auth/auth.actions';

export const preferencesConsignmentFeatureKey = 'preferencesConsignment';

export interface IConsignmentPreference extends ICDRConsignment {
  id: string;
}

export interface IPreferencesConsignmentState
  extends EntityState<IConsignmentPreference> {}

export const adapter: EntityAdapter<IConsignmentPreference> = createEntityAdapter<
  IConsignmentPreference
>({
  selectId: (consignment) => consignment.id,
});

export const initialState: IPreferencesConsignmentState = adapter.getInitialState(
  {}
);

export const reducer = createReducer(
  initialState,
  on(loadConsignmentPreferences, (state, { preferences }) => ({
    ...preferences,
  })),
  on(upsertConsignmentPreference, (state, { consignment }) =>
    adapter.upsertOne(consignment, state)
  ),
  on(deleteConsignmentPreference, (state, { id }) =>
    adapter.removeOne(id, state)
  ),
  on(clearSessionData, switchUser, () => initialState)
);

export const selectPreferencesConsignmentState = createFeatureSelector<
  IPreferencesConsignmentState
>(preferencesConsignmentFeatureKey);

export const { selectAll } = adapter.getSelectors();

export const selectConsignmentPreferences = createSelector(
  selectPreferencesConsignmentState,
  selectAll
);

export const selectConsignmentPreferenceById = (consignmentId: string) =>
  createSelector(selectConsignmentPreferences, (consignments) =>
    consignments.find(({ id }) => equals(id, consignmentId))
  );
