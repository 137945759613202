import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { compare } from 'compare-versions';
import { take } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import {
  IUserBucketState,
  selectClientVersion,
} from '../user-bucket/user-bucket.reducer';

@Injectable()
export class VersionMismatchService {
  constructor(private router: Router, private store: Store<IUserBucketState>) {}

  public clientIsOlder(version: string): boolean {
    return compare(version, environment.version, '>');
  }

  /**
   * Make sure this runs AFTER user bucket has been loaded
   */
  public checkClientVersion(from?: string): void {
    this.store
      .select(selectClientVersion)
      .pipe(take(1))
      .subscribe((clientVersion) => {
        if (this.clientIsOlder(clientVersion)) {
          this.router.navigate(['version-mismatch'], {
            replaceUrl: true,
            queryParams: { from },
          });
        }
      });
  }
}
