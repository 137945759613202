import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as fromPriorNotice from './prior-emergency-notice.reducer';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { PriorEmergencyNoticeEffects } from './prior-emergency-notice.effects';
import { PriorEmergencyNoticeService } from './prior-emergency-notice.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromPriorNotice.priorEmergencyNoticeFeatureKey,
      fromPriorNotice.reducer
    ),
    EffectsModule.forFeature([PriorEmergencyNoticeEffects]),
  ],
  providers: [PriorEmergencyNoticeService],
})
export class PriorEmergencyNoticeModule {}
