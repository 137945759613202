/**
 * Queensland Fisheries FishNet API
 * Queensland Fisheries FishNet API
 *
 * OpenAPI spec version: 1.0.2
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { LogbookActivityType } from './logbookActivityType';
import { LogbookField } from './logbookField';
import { LogbookFishForm } from './logbookFishForm';
import { LogbookFishingMethod } from './logbookFishingMethod';
import { LogbookMeasure } from './logbookMeasure';
import { LogbookQuota } from './logbookQuota';
import { LogbookSpecies } from './logbookSpecies';
import { LogbookGear } from './logbookGear';

export enum LogbookClass {
  TEP = 'TEP',
  SR = 'SR',
  CD = 'CD',
}

export interface Logbook {
  logbook?: string;
  fisherySymbols?: Array<string>;
  secondaryQuestion?: string;
  entryFormatString?: string;
  logbookClass?: LogbookClass;
  activityTypes?: Array<LogbookActivityType>;
  fishingMethods?: Array<LogbookFishingMethod>;
  gear?: Array<LogbookGear>;
  fields?: Array<LogbookField>;
  grades?: Array<string>;
  measures?: Array<LogbookMeasure>;
  fishForms?: Array<LogbookFishForm>;
  discardMeasures?: Array<LogbookMeasure>;
  discardFishForms?: Array<LogbookFishForm>;
  species?: Array<LogbookSpecies>;
  quota?: Array<LogbookQuota>;
}
