import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import {
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from '@ngrx/store';
import { LandingPoint } from '../api/model';
import { switchUser } from '../auth/auth.actions';
import { clearSessionData } from '../settings/settings.actions';
import {
  deleteLandingPlacesPreference,
  loadLandingPlacesPreferences,
  upsertLandingPlacesPreference,
} from './preference-landing-places.action';

export const preferencesLandingPlacesFeatureKey = 'preferencesLandingPlaces';

export type ILandingPlacePreference = Pick<LandingPoint, 'code'>;

export const adapter: EntityAdapter<ILandingPlacePreference> = createEntityAdapter(
  {
    selectId: (landingPlace) => landingPlace.code,
  }
);

const initialState = adapter.getInitialState({});

export type ILandingPlacePreferenceState = typeof initialState;

export const reducer = createReducer(
  initialState,
  on(loadLandingPlacesPreferences, (state, { preferences }) => ({
    ...preferences,
  })),
  on(upsertLandingPlacesPreference, (state, { landingPlaces }) =>
    adapter.upsertMany(landingPlaces, state)
  ),
  on(deleteLandingPlacesPreference, (state, { code }) =>
    adapter.removeOne(code, state)
  ),
  on(clearSessionData, switchUser, () => initialState)
);

export const selectPreferencesLandingPlacesState = createFeatureSelector<
  ILandingPlacePreferenceState
>(preferencesLandingPlacesFeatureKey);

export const selectLandingPlacePreferencesIds = createSelector(
  selectPreferencesLandingPlacesState,
  (state) => new Set(state.ids.map((id) => Number(id)))
);
