import {
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from '@ngrx/store';
import { clone, equals, includes, isNilOrEmpty } from '@qld-recreational/ramda';
import {
  disableWeightNotice,
  endTripSuccessful,
  lockPriorEmergencyNoticeSuccessful,
  lockRetainNoticeSuccessful,
  tripStepChange,
} from '../trip/trip.actions';
import { switchUser } from '../auth/auth.actions';
import { clearSessionData } from '../settings/settings.actions';
import { IPage } from '../shared/models/Page';
import { ActivityEmergencyCreate } from '../api/model';
import { TripStep } from '../trip/trip-step';

export enum ActivityNoticeType {
  PriorNotice = 'Prior notice',
  EmergencyNotice = 'Emergency notice',
}

export const activityNoticesFeatureKey = 'activity-notices';

export const TripStepToPageTitle = {
  PriorNotice: 'Prior or Emergency notice',
  RetainedNotice: 'Retained fish notice',
  WeightNotices: 'Weight notice & CDR',
};

const TripStepToPageHref = {
  PriorNotice: '/prior-emergency-notice',
  WeightNotices: '/weight-disposal',
  RetainedNotice: '/retain-notice',
};

const priorEmergencyNoticeDescription = {
  priorNotice: 'Give your prior notice before landing',
  emergencyNotice: 'Give an emergency notice',
};

export interface IActivityNoticesState {
  pages: Array<IPage>;
}

export const initialState: IActivityNoticesState = {
  pages: [
    {
      href: TripStepToPageHref.PriorNotice,
      title: TripStepToPageTitle.PriorNotice,
      description: `${priorEmergencyNoticeDescription.priorNotice} / ${priorEmergencyNoticeDescription.emergencyNotice}`,
      enabled: false,
      viewOnly: false,
    },
    {
      href: TripStepToPageHref.WeightNotices,
      title: TripStepToPageTitle.WeightNotices,
      description:
        'Give your weight notices and submit your catch disposal records',
      enabled: false,
      viewOnly: false,
    },
    {
      href: TripStepToPageHref.RetainedNotice,
      title: TripStepToPageTitle.RetainedNotice,
      description:
        'Give your notice for fish you are not unloading and taking back out to sea',
      enabled: false,
      viewOnly: false,
    },
  ],
};

const pagesAfterSubmitPriorEmergencyNotice = (
  state: IActivityNoticesState,
  { priorEmergencyNoticeCreate }
) => {
  const isPriorNotice = isNilOrEmpty(
    (priorEmergencyNoticeCreate as ActivityEmergencyCreate).landingPoint
  );
  const pages = clone(state.pages);
  const priorEmergencyTitle = isPriorNotice
    ? 'prior notice'
    : 'emergency notice';
  const priorEmergencyNoticePage = pages.find((p) =>
    equals(p.href, TripStepToPageHref.PriorNotice)
  );
  priorEmergencyNoticePage.viewOnly = true;
  priorEmergencyNoticePage.title = `View ${priorEmergencyTitle}`;
  priorEmergencyNoticePage.description = isPriorNotice
    ? priorEmergencyNoticeDescription.priorNotice
    : priorEmergencyNoticeDescription.emergencyNotice;
  return { pages };
};

const pagesAfterSubmitRetainNotice = (state: IActivityNoticesState) => {
  const pages = clone(state.pages);
  const retainNoticePage = pages.find((p) =>
    equals(p.href, TripStepToPageHref.RetainedNotice)
  );
  retainNoticePage.viewOnly = true;
  retainNoticePage.title = 'View retained fish notice';
  return { pages };
};

const pagesForRetainWeightNotices = (state: IActivityNoticesState) => {
  const pages = clone(state.pages);
  pages
    .filter((p) =>
      includes(p.href, [
        TripStepToPageHref.RetainedNotice,
        TripStepToPageHref.WeightNotices,
      ])
    )
    .map((p) => (p.enabled = true));
  return { pages };
};

const pagesForWeightNotices = (state: IActivityNoticesState) => {
  const pages = clone(state.pages);
  pages.find((p) =>
    equals(p.href, TripStepToPageHref.WeightNotices)
  ).enabled = true;
  return { pages };
};

const pagesForRetainNotice = (state: IActivityNoticesState) => {
  const pages = clone(state.pages);
  pages.find((p) =>
    equals(p.href, TripStepToPageHref.RetainedNotice)
  ).enabled = true;
  return { pages };
};

const pagesForPriorEmergencyNotices = (state: IActivityNoticesState) => {
  const pages = clone(state.pages);
  pages.find((p) =>
    equals(p.href, TripStepToPageHref.PriorNotice)
  ).enabled = true;
  return { pages };
};

const pagesForReadyToEndTrip = (state: IActivityNoticesState) => {
  const pages = clone(state.pages);
  const retainNoticePage = pages.find((p) =>
    equals(p.href, TripStepToPageHref.RetainedNotice)
  );
  retainNoticePage.viewOnly = true;
  retainNoticePage.title = 'View retained fish notice';
  return { pages };
};

const handleTripStepChange = (state: IActivityNoticesState, { tripStep }) => {
  switch (tripStep) {
    case TripStep.PriorEmergencyNotice:
      return pagesForPriorEmergencyNotices(state);
    case TripStep.RetainWeightNotices:
      return pagesForRetainWeightNotices(state);
    case TripStep.RetainNotice:
      return pagesForRetainNotice(state);
    case TripStep.WeightNotices:
      return pagesForWeightNotices(state);
    default:
      return state;
  }
};

const handleDisableWeightNotice = (state: IActivityNoticesState) => {
  const pages = clone(state.pages);
  pages.find((p) =>
    equals(p.href, TripStepToPageHref.WeightNotices)
  ).enabled = false;
  return { pages };
};

export const reducer = createReducer(
  initialState,
  on(lockPriorEmergencyNoticeSuccessful, pagesAfterSubmitPriorEmergencyNotice),
  on(tripStepChange, handleTripStepChange),
  on(disableWeightNotice, handleDisableWeightNotice),
  on(lockRetainNoticeSuccessful, pagesAfterSubmitRetainNotice),
  on(switchUser, clearSessionData, endTripSuccessful, () => initialState)
);

export const selectActivityNoticesState = createFeatureSelector<
  IActivityNoticesState
>(activityNoticesFeatureKey);

export const selectPages = createSelector(
  selectActivityNoticesState,
  (state) => state?.pages
);

export const selectRetainNoticePage = createSelector(selectPages, (pages) =>
  pages?.find((p) => equals(p.href, TripStepToPageHref.RetainedNotice))
);
