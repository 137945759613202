/* write an action to get preferences from api */

import { createAction } from '@ngrx/store';

export const preferencesChanged = createAction(
  '[Preference] Preferences Changed'
);

export const attemptSavePreferences = createAction(
  '[Preference] Attempt Save Preferences'
);

export const savePreferencesSuccess = createAction(
  '[Preference] Save Preferences Success'
);

export const savePreferencesFailure = createAction(
  '[Preference] Save Preferences Failure'
);
