import {
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from '@ngrx/store';
import { equals } from '@qld-recreational/ramda';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { switchUser } from '../auth/auth.actions';
import { clearSessionData } from '../settings/settings.actions';
import { addLogbook } from './logbooks.actions';
import { endTripSuccessful } from '../trip/trip.actions';

export interface ILogbook {
  id: string;
  tripId: string;
  fisherySymbol: string;
}

export const logbooksFeatureKey = 'logbooks';

export interface ILogbooksState extends EntityState<ILogbook> {}

export const adapter: EntityAdapter<ILogbook> = createEntityAdapter<ILogbook>({
  selectId: (logbook) => logbook.id,
});

export const initialState: ILogbooksState = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(addLogbook, (state, { logbook }) => adapter.addOne(logbook, state)),
  on(clearSessionData, switchUser, endTripSuccessful, () => initialState)
);

const { selectAll } = adapter.getSelectors();

const selectLogbooksState = createFeatureSelector<ILogbooksState>(
  logbooksFeatureKey
);

export const selectLogbooks = createSelector(selectLogbooksState, selectAll);

export const selectLogbooksByTripId = (tripId: string) =>
  createSelector(selectLogbooks, (logbooks) =>
    logbooks.filter((logbook) => equals(logbook.tripId, tripId))
  );

export const selectFisherySymbolById = (id: string) =>
  createSelector(
    selectLogbooks,
    (logbooks) =>
      logbooks.find((logbook) => equals(logbook.id, id)).fisherySymbol
  );

export const selectLogbookByFisherySymbol = (fisherySymbol: string) =>
  createSelector(selectLogbooks, (logbooks) =>
    logbooks.find((logbook) => equals(logbook.fisherySymbol, fisherySymbol))
  );

export const selectLogbookById = (id: string) =>
  createSelector(selectLogbooks, (logbooks) =>
    logbooks.find((logbook) => equals(logbook.id, id))
  );
