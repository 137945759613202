import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ContentfulService } from './contentful.service';
import { StoreModule } from '@ngrx/store';
import * as fromContentful from './contentful.reducer';
import { EffectsModule } from '@ngrx/effects';
import { ContentfulEffects } from './contentful.effects';
import { HttpClientModule } from '@angular/common/http';
import { Insomnia } from '@awesome-cordova-plugins/insomnia/ngx';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromContentful.contentfulFeatureKey,
      fromContentful.reducer
    ),
    EffectsModule.forFeature([ContentfulEffects]),
    HttpClientModule,
  ],
  providers: [ContentfulService, Insomnia],
})
export class ContentfulModule {}
