/**
 * Queensland Fisheries FishNet API
 * Queensland Fisheries FishNet API
 *
 * OpenAPI spec version: 1.0.2
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export enum LogbookMeasureType {
  Number = 'Number',
  Quantity = 'Quantity',
}

export interface LogbookMeasure {
  id?: string;
  description?: string;
  fisherySymbols?: Array<string>;
  primary?: boolean;
  type?: LogbookMeasureType;
  instructions?: string;
  minimumValue?: number;
  maximumValue?: number;
  scale?: number;
  required?: boolean;
  increment?: number;
}
