import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import {
  preferencesConsignmentFeatureKey,
  reducer,
} from './preferences-consignment.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(preferencesConsignmentFeatureKey, reducer),
  ],
})
export class PreferenceConsignmentModule {}
