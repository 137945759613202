import { createAction, props } from '@ngrx/store';
import { IRetainCatch } from './retain-notice.reducer';

export const initRetainCatches = createAction(
  '[RetainNotice] Init Retain Catches'
);

export const addRetainCatches = createAction(
  '[RetainNotice] Add Retain Catches',
  props<{ retainCatches: Array<IRetainCatch> }>()
);

export const updateRetainCatches = createAction(
  '[RetainNotice] Update Retain Catches',
  props<{ retainCatches: Array<IRetainCatch> }>()
);
