import { ActivityLogPostEntry } from '../api/model';
import { createAction, props } from '@ngrx/store';

export const debugLog = createAction(
  '[ActivityLog] Debug log for SLA',
  props<{
    activityLog: Omit<
      ActivityLogPostEntry,
      'tripCorrelationID' | 'timestamp' | 'status'
    >;
  }>()
);

export const log = createAction(
  '[ActivityLog] Log',
  props<{ activityLog: ActivityLogPostEntry }>()
);

export const submitActivityLog = createAction(
  '[ActivityLog] Submit Activity Log'
);

export const submitActivityLogSuccess = createAction(
  '[ActivityLog] Submit Activity Log Success'
);

export const removeActivityLogs = createAction(
  '[ActivityLog] Remove Activity Logs',
  props<{ ids: string[] }>()
);

export const submitActivityLogFailure = createAction(
  '[ActivityLog] Submit Activity Log Failure',
  props<{ err: string; shouldUpdateRequestId: boolean }>()
);
