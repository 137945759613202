import { ActivityStatus, Page } from '../api/model';
import { NilFishingCreate } from '../api/model/nilFishingCreate';
import { equals } from '@qld-recreational/ramda';
import { qldDate } from '@qld-recreational/moment';

const activityLog = {
  page: Page.NilFishing,
  action: 'Submit day/s not fished',
};

export const submitNilFishingActivityLog = (
  createdDateTime: string,
  { startDate, endDate }: Omit<NilFishingCreate, 'requestId'>
) => {
  console.log(startDate, endDate, qldDate(startDate), qldDate(endDate));
  return {
    message: `User submitted day/s not commercially fished ${
      equals(qldDate(startDate), qldDate(endDate))
        ? `on <em>${qldDate(startDate)}</em>`
        : `from <em>${qldDate(startDate)}</em> to <em>${qldDate(endDate)}</em>`
    }.`,
    status: ActivityStatus.pending,
    timestamp: createdDateTime,
    ...activityLog,
  };
};

export const submitNilFishingSuccessActivityLog = {
  status: ActivityStatus.success,
  message: `day/s not commercially fished was successfully submitted.`,
  ...activityLog,
};
