import { Injectable } from '@angular/core';
import {
  CatchPageSpecies,
  ILogbookEventsState,
  selectEventById,
} from '../logbook-events/logbook-events.reducer';
import { combineLatest, Observable, of } from 'rxjs';
import {
  ILogbookDay,
  ILogbookDaysState,
  selectLogbookDayById,
} from '../logbook-days/logbook-days.reducer';
import { map, switchMap, take } from 'rxjs/operators';
import {
  ILogbooksState,
  selectFisherySymbolById,
} from '../logbooks/logbooks.reducer';
import { Store } from '@ngrx/store';
import {
  equals,
  includes,
  isNil,
  isNilOrEmpty,
  prop,
} from '@qld-recreational/ramda';
import { Logbook, LogbookClass } from '../api/model';
import { ILogbookCatch } from '../logbook-catches/logbook-catches.reducer';
import { ICDRCatch } from '../catch-disposal-record-catches/catch-disposal-record-catches.reducer';
import { UntypedFormGroup } from '@angular/forms';
import { ITripState } from '../trip/trip.reducer';
import { selectPreTrip } from '../trip/trip.selectors';
import {
  IUserBucketState,
  selectLogbookReferenceByFisherySymbol,
  selectSecondaryLogbookReferenceByClassAndFisherySymbol,
} from '../user-bucket/user-bucket.reducer';

@Injectable({
  providedIn: 'root',
})
export class LogbookService {
  constructor(
    private logbookEventStore: Store<ILogbookEventsState>,
    private logbookDayStore: Store<ILogbookDaysState>,
    private logbooksStore: Store<ILogbooksState>,
    private tripStore: Store<ITripState>,
    private userBucketStore: Store<IUserBucketState>
  ) {}

  public selectFisherySymbolByFishingEventId(
    logbookEventId: string
  ): Observable<string> {
    return this.logbookEventStore.select(selectEventById(logbookEventId)).pipe(
      switchMap(({ logbookDayId }) =>
        this.logbookDayStore.select(selectLogbookDayById(logbookDayId))
      ),
      switchMap((logbookDay) =>
        this.logbooksStore.select(selectFisherySymbolById(logbookDay.logbookId))
      )
    );
  }

  public selectLogbookDayByFishingEventId(
    logbookEventId: string
  ): Observable<ILogbookDay> {
    return this.logbookEventStore
      .select(selectEventById(logbookEventId))
      .pipe(
        switchMap(({ logbookDayId }) =>
          this.logbookDayStore.select(selectLogbookDayById(logbookDayId))
        )
      );
  }

  public getCatchForms(logbook: Logbook, species: CatchPageSpecies) {
    return logbook.fishForms.filter((form) =>
      form.primary && isNilOrEmpty(species.allowedFishForms)
        ? true
        : includes(form.id, species.allowedFishForms)
    );
  }

  public getDiscardCatchForms(logbook: Logbook, species: CatchPageSpecies) {
    return logbook.discardFishForms.filter((form) =>
      form.primary && isNilOrEmpty(species.allowedDiscardFishForms)
        ? true
        : includes(form.id, species.allowedDiscardFishForms)
    );
  }

  public getCatchMeasures(logbook: Logbook, species: CatchPageSpecies) {
    return logbook.measures.filter((measure) =>
      measure.primary && isNilOrEmpty(species.allowedMeasures)
        ? true
        : includes(measure.id, species.allowedMeasures)
    );
  }

  public getDiscardCatchMeasures(logbook: Logbook, species: CatchPageSpecies) {
    return logbook.discardMeasures.filter((measure) =>
      measure.primary && isNilOrEmpty(species.allowedDiscardMeasures)
        ? true
        : includes(measure.id, species.allowedDiscardMeasures)
    );
  }

  public isSpeciesIncomplete(
    logbookCatches: Array<ILogbookCatch>,
    logbook: Logbook,
    species: CatchPageSpecies
  ) {
    const logbookCatchesOfSpecies = logbookCatches.filter(
      (logbookCatch) =>
        equals(logbookCatch.speciesId, species.id) &&
        equals(logbookCatch.quotaSymbol, species.quotaSymbol)
    );
    return logbookCatchesOfSpecies.some(
      (logbookCatch) =>
        !isNil(logbookCatch.quantity) &&
        !equals(
          logbookCatchesOfSpecies.filter(
            (lc) =>
              equals(lc.discarded, logbookCatch.discarded) &&
              equals(lc.fishFormId, logbookCatch.fishFormId) &&
              !isNil(lc.quantity)
          ).length,
          (logbookCatch.discarded
            ? this.getDiscardCatchMeasures(logbook, species)
            : this.getCatchMeasures(logbook, species)
          ).length
        )
    );
  }

  public isFieldRequired(
    field: ILogbookCatch,
    fields: { catches: Array<ILogbookCatch | ICDRCatch> },
    form: UntypedFormGroup
  ) {
    const fieldsWithSameForm = fields.catches.filter((f) =>
      equals(f.fishFormId, field.fishFormId)
    );
    const fieldsWithSameMeasureType = fieldsWithSameForm.filter((f) =>
      equals(f.measureType, field.measureType)
    );
    const fieldsWithDifferentMeasureType = fieldsWithSameForm.filter(
      (f) => !equals(f.measureType, field.measureType)
    );
    return (
      fieldsWithSameMeasureType.every(({ id }) =>
        isNilOrEmpty(form.get(id).value)
      ) &&
      fieldsWithDifferentMeasureType.some(
        ({ id }) => !isNilOrEmpty(form.get(id).value)
      )
    );
  }

  public isFieldDisabled(
    field: ILogbookCatch,
    fields: { catches: Array<ILogbookCatch | ICDRCatch> },
    form: UntypedFormGroup
  ) {
    const fieldIdsWithSameFormAndMeasureType = fields.catches
      .filter(
        (f) =>
          !equals(f.measureId, field.measureId) &&
          equals(f.fishFormId, field.fishFormId) &&
          equals(f.measureType, field.measureType)
      )
      .map(prop('id'));
    return fieldIdsWithSameFormAndMeasureType.some(
      (id) => !isNilOrEmpty(form.get(id).value)
    );
  }

  public selectFisherySymbol(speciesId: number) {
    const logbookHasSpecies = (logbook: Logbook) =>
      logbook?.species.some((s) => equals(s.speciesId, speciesId));
    return this.tripStore.select(selectPreTrip).pipe(
      take(1),
      switchMap(({ primaryFisherySymbol, secondaryFisherySymbol }) =>
        isNil(secondaryFisherySymbol)
          ? of(primaryFisherySymbol)
          : combineLatest([
              this.userBucketStore.select(
                selectLogbookReferenceByFisherySymbol(primaryFisherySymbol)
              ),
              this.userBucketStore.select(
                selectLogbookReferenceByFisherySymbol(secondaryFisherySymbol)
              ),
              this.userBucketStore.select(
                selectSecondaryLogbookReferenceByClassAndFisherySymbol(
                  LogbookClass.SR,
                  primaryFisherySymbol
                )
              ),
              this.userBucketStore.select(
                selectSecondaryLogbookReferenceByClassAndFisherySymbol(
                  LogbookClass.SR,
                  secondaryFisherySymbol
                )
              ),
            ]).pipe(
              take(1),
              map(
                ([
                  primaryLogbook,
                  secondaryLogbook,
                  srPrimaryLogbook,
                  srSecondaryLogbook,
                ]) => {
                  if (logbookHasSpecies(primaryLogbook)) {
                    return primaryFisherySymbol;
                  }
                  if (logbookHasSpecies(secondaryLogbook)) {
                    return secondaryFisherySymbol;
                  }
                  if (logbookHasSpecies(srPrimaryLogbook)) {
                    return primaryFisherySymbol;
                  }
                  if (logbookHasSpecies(srSecondaryLogbook)) {
                    return secondaryFisherySymbol;
                  }
                  return null;
                }
              )
            )
      )
    );
  }
}
