import { Injectable } from '@angular/core';
import { EntityState } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  ICDRFishFormsMeasuresPreferenceState,
  makeSpeciesFishFormMeasureEntityID,
  preferencesCDRFishFormsMeasuresFeatureKey,
  selectCDRFishFormPreferencesState,
} from '../preference-cdr-fish-forms-measures/preference-cdr-fish-forms-measures.reducer';
import {
  IConsignmentPreference,
  IPreferencesConsignmentState,
  preferencesConsignmentFeatureKey,
  selectPreferencesConsignmentState,
} from '../preference-consignment/preferences-consignment.reducer';
import {
  IDisposalPreference,
  IPreferencesDisposalState,
  preferencesDisposalFeatureKey,
  selectPreferencesDisposalState,
} from '../preference-disposal/preferences-disposal.reducer';
import {
  IPreferencesGPSState,
  preferencesGPSFeatureKey,
  selectPreferencesGPSState,
} from '../preference-gps/preference-gps.reducer';
import {
  ILandingPlacePreference,
  ILandingPlacePreferenceState,
  preferencesLandingPlacesFeatureKey,
  selectPreferencesLandingPlacesState,
} from '../preference-landing-places/preference-landing-places.reducer';
import {
  IPreferenceRegionsSpeciesForLogbookState,
  IPreferenceRegionsSpeciesState,
  preferenceRegionsSpeciesFeatureKey,
  selectRegionsSpeciesPreferencesState,
} from '../preferences-regions-species/preferences-regions-species.reducer';

export interface PreferencesPayload {
  gps: IPreferencesGPSState;
  disposal: Array<IDisposalPreference>;
  consignment: Array<IConsignmentPreference>;
  landingPlaces: Array<ILandingPlacePreference>;
  regionsSpecies: Array<{
    id: string;
    nonquotaSpecies: number[];
    regions: Array<{
      id: string;
      quotaSpecies: number[];
    }>;
  }>;
  cdrFishFormsMeasures: Array<{
    id: string;
    species: Array<{
      id: string;
      fishformMeasures: Array<{
        fishFormID: string;
        measureID: string;
      }>;
    }>;
  }>;
}

export interface AllPreferences {
  [preferencesGPSFeatureKey]: IPreferencesGPSState;
  [preferencesDisposalFeatureKey]: IPreferencesDisposalState;
  [preferencesConsignmentFeatureKey]: IPreferencesConsignmentState;
  [preferencesLandingPlacesFeatureKey]: ILandingPlacePreferenceState;
  [preferenceRegionsSpeciesFeatureKey]: IPreferenceRegionsSpeciesState;
  [preferencesCDRFishFormsMeasuresFeatureKey]: ICDRFishFormsMeasuresPreferenceState;
}

@Injectable()
export class PreferenceService {
  private arrayToEntityState<T>(
    array: Array<T>,
    selectId: (item: T) => string | number
  ): EntityState<T> {
    return {
      ids: (array?.map(selectId) as EntityState<T>['ids']) || [],
      entities:
        array?.reduce((acc, item) => {
          acc[selectId(item)] = item;
          return acc;
        }, {} as Record<string, T>) || {},
    };
  }

  private entityToArray<T>(entity: EntityState<T>): Array<T> {
    return entity.ids.map((id) => entity.entities[id]);
  }

  public transformPreferencesFromPayload(
    preferencePayload: PreferencesPayload
  ): AllPreferences {
    const cdrFishFormAndMeasurePreferences: ICDRFishFormsMeasuresPreferenceState =
      {};

    preferencePayload.cdrFishFormsMeasures?.forEach(({ id, species }) => {
      const logbook = cdrFishFormAndMeasurePreferences[id] ?? {};

      species.forEach(({ id: speciesId, fishformMeasures }) => {
        logbook[speciesId] = this.arrayToEntityState(
          fishformMeasures,
          makeSpeciesFishFormMeasureEntityID
        );
      });

      cdrFishFormAndMeasurePreferences[id] = logbook;
    });

    const regionsSpeciesPreferences: IPreferenceRegionsSpeciesState = {};
    preferencePayload.regionsSpecies?.forEach(
      ({ id, nonquotaSpecies, regions }) => {
        const logbook: IPreferenceRegionsSpeciesForLogbookState = {
          ...{
            nonquotaSpecies: [],
            quotaSpecies: {},
          },
          ...regionsSpeciesPreferences[id],
        };

        logbook.nonquotaSpecies.push(...(nonquotaSpecies || []));

        regions.forEach((region) => {
          const regionPreference = logbook.quotaSpecies[region.id] ?? [];

          regionPreference.push(...region.quotaSpecies);

          logbook.quotaSpecies[region.id] = regionPreference;
        });

        regionsSpeciesPreferences[id] = logbook;
      }
    );

    return {
      [preferencesGPSFeatureKey]: preferencePayload.gps,
      [preferencesDisposalFeatureKey]: this.arrayToEntityState(
        preferencePayload.disposal,
        (item) => item.id
      ),
      [preferencesConsignmentFeatureKey]: this.arrayToEntityState(
        preferencePayload.consignment,
        (item) => item.id
      ),
      [preferencesLandingPlacesFeatureKey]: this.arrayToEntityState(
        preferencePayload.landingPlaces,
        (item) => item.code
      ),
      [preferenceRegionsSpeciesFeatureKey]: regionsSpeciesPreferences,
      [preferencesCDRFishFormsMeasuresFeatureKey]:
        cdrFishFormAndMeasurePreferences,
    };
  }

  public transformPreferencesToPayload(preferences: AllPreferences) {
    const regionsSpecies = Object.entries(
      preferences[preferenceRegionsSpeciesFeatureKey]
    ).map(([logbook, pref]) => ({
      id: logbook,
      nonquotaSpecies: pref.nonquotaSpecies,
      regions: Object.entries(pref.quotaSpecies).map(
        ([speciesId, speciesPref]) => ({
          id: speciesId,
          quotaSpecies: speciesPref,
        })
      ),
    }));

    const cdrFishFormsMeasures = Object.entries(
      preferences[preferencesCDRFishFormsMeasuresFeatureKey]
    ).map(([logbook, speciesPref]) => ({
      id: logbook,
      species: Object.entries(speciesPref).map(
        ([speciesId, fishformsMeasures]) => ({
          id: speciesId,
          fishformMeasures: this.entityToArray(fishformsMeasures),
        })
      ),
    }));

    const payload: PreferencesPayload = {
      gps: preferences[preferencesGPSFeatureKey],
      disposal: this.entityToArray(preferences[preferencesDisposalFeatureKey]),
      consignment: this.entityToArray(
        preferences[preferencesConsignmentFeatureKey]
      ),
      landingPlaces: this.entityToArray(
        preferences[preferencesLandingPlacesFeatureKey]
      ),
      regionsSpecies,
      cdrFishFormsMeasures,
    };
    return payload;
  }

  public getCurrentPreferences() {
    return combineLatest([
      this.gpsPreferenceStore.select(selectPreferencesGPSState),
      this.disposalPreferenceStore.select(selectPreferencesDisposalState),
      this.consignmentPreferenceStore.select(selectPreferencesConsignmentState),
      this.landingPlacesPreferenceStore.select(
        selectPreferencesLandingPlacesState
      ),
      this.regionsSpeciesPreferenceStore.select(
        selectRegionsSpeciesPreferencesState
      ),
      this.cdrFishFormsMeasuresPreferenceStore.select(
        selectCDRFishFormPreferencesState
      ),
    ]).pipe(
      map(
        ([
          preferencesGPS,
          preferencesDisposal,
          preferencesConsignment,
          preferencesLandingPlaces,
          preferenceRegionsAndSpecies,
          preferencesCDRFishFormsMeasures,
        ]) =>
          this.transformPreferencesToPayload({
            preferencesGPS,
            preferencesDisposal,
            preferencesConsignment,
            preferencesLandingPlaces,
            preferenceRegionsAndSpecies,
            preferencesCDRFishFormsMeasures,
          })
      )
    );
  }

  constructor(
    private gpsPreferenceStore: Store<IPreferencesGPSState>,
    private disposalPreferenceStore: Store<IDisposalPreference>,
    private consignmentPreferenceStore: Store<IConsignmentPreference>,
    private landingPlacesPreferenceStore: Store<ILandingPlacePreferenceState>,
    private regionsSpeciesPreferenceStore: Store<IPreferenceRegionsSpeciesState>,
    private cdrFishFormsMeasuresPreferenceStore: Store<ICDRFishFormsMeasuresPreferenceState>
  ) {}
}
