import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromLogbookDays from './logbook-days.reducer';
import { EffectsModule } from '@ngrx/effects';
import { LogbookDaysEffects } from './logbook-days.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromLogbookDays.logbookDaysFeatureKey,
      fromLogbookDays.reducer
    ),
    EffectsModule.forFeature([LogbookDaysEffects]),
  ],
})
export class LogbookDaysModule {}
