export enum TripStep {
  PreTrip = 'PreTrip',
  RecordCatch = 'RecordCatch',
  PriorEmergencyNotice = 'PriorEmergencyNotice',
  RetainWeightNotices = 'RetainWeightNotices',
  RetainNotice = 'RetainNotice',
  WeightNotices = 'WeightNotices',
  ReadyToEndTrip = 'ReadyToEndTrip',
  CDR = 'CDR',
}
