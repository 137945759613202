import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import {
  catchDisposalRecordCatchesFeatureKey,
  reducer,
} from './catch-disposal-record-catches.reducer';
import { EffectsModule } from '@ngrx/effects';
import { CatchDisposalRecordCatchesEffects } from './catch-disposal-record-catches.effects';
import { CatchDisposalRecordCatchesService } from './catch-disposal-record-catches.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(catchDisposalRecordCatchesFeatureKey, reducer),
    EffectsModule.forFeature([CatchDisposalRecordCatchesEffects]),
  ],
  providers: [CatchDisposalRecordCatchesService],
})
export class CatchDisposalRecordCatchesModule {}
