import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { equals, isNilOrEmpty } from '@qld-recreational/ramda';
import { all, isNil } from 'rambda';
import { combineLatest, Observable, of } from 'rxjs';
import { switchMap, withLatestFrom, map, filter } from 'rxjs/operators';
import { Logbook, LogbookMeasure, LogbookMeasureType } from '../api/model';
import {
  ILogbookCatchesState,
  ILogbookCatchSummary,
  ILogbookCatchSummarySection,
  selectCatches,
} from '../logbook-catches/logbook-catches.reducer';
import {
  ILogbookDaysState,
  ILogbookDaySummary,
  selectLogbookDays,
} from '../logbook-days/logbook-days.reducer';
import {
  ILogbookEvent,
  ILogbookEventsState,
  selectEventsByLogbookDayId,
} from '../logbook-events/logbook-events.reducer';
import { selectLogbookById } from '../logbooks/logbooks.reducer';
import { LogbookService } from '../shared/logbook.service';
import { ITripState } from '../trip/trip.reducer';
import { selectPreTrip } from '../trip/trip.selectors';
import {
  IUserBucketState,
  selectLogbookReferenceByFisherySymbol,
} from '../user-bucket/user-bucket.reducer';

type CatchMap = Map<number, Map<string, Map<string, number>>>;

export interface ILogbookCatchReminder {
  fishFormDescription: string;
  measureDescription: string;
  value: number | string;
}

@Injectable()
export class LogbookSummaryService {
  private readonly symbolsThatAllowMethods = new Set([
    'N1',
    'N2',
    'N4',
    'N10',
    'N11',
    'K1',
    'K2',
    'K3',
    'K4',
    'K5',
    'K6',
    'K7',
    'K8',
    'T4',
  ]);

  private readonly symbolsThatAllowQuantityMeasures = new Set(['T4']);

  constructor(
    private logbookDaysStore: Store<ILogbookDaysState>,
    private logbookEventsStore: Store<ILogbookEventsState>,
    private logbookCatchesStore: Store<ILogbookCatchesState>,
    private userBucketStore: Store<IUserBucketState>,
    private tripStore: Store<ITripState>,
    private logbookService: LogbookService
  ) {}

  private getFishingMethodsForEvents(
    logbookEvents: ILogbookEvent[],
    logbookReference: Logbook
  ): string[] {
    const fishingMethod: string[] = [];

    logbookEvents.forEach((event) => {
      const fishingMethodRef = logbookReference.fishingMethods?.find(
        (fishingMethod) => equals(fishingMethod.id, event.fishingMethod)
      );

      if (!isNilOrEmpty(fishingMethodRef)) {
        fishingMethod.push(fishingMethodRef.description);
      }
    });

    return fishingMethod;
  }

  private getCatchSummary(
    catchMap: CatchMap,
    speciesDescriptionMap: Map<number, string>
  ): ILogbookCatchSummary[] {
    return Array.from(catchMap)
      .map(([speciesId, result]) => ({
        speciesId,
        speciesDescription: speciesDescriptionMap.get(speciesId),
        fishForms: Array.from(result).map(([fishFormId, measures]) => ({
          fishFormId,
          measures,
        })),
      }))
      .sort((a, b) => (a.speciesDescription > b.speciesDescription ? 1 : -1))
      .map((catchItem) => ({
        ...catchItem,
        fishForms: catchItem.fishForms.sort(
          (a, b) =>
            Math.max(...Array.from(b.measures.values())) -
            Math.max(...Array.from(a.measures.values()))
        ),
      }));
  }

  public getLogbookCatchReminders(
    speciesId: number
  ): Observable<ILogbookCatchReminder[]> {
    return this.logbookService.selectFisherySymbol(speciesId).pipe(
      switchMap((fisherySymbol) =>
        this.userBucketStore.select(
          selectLogbookReferenceByFisherySymbol(fisherySymbol)
        )
      ),
      switchMap((logbook) => {
        if (isNil(logbook)) {
          return of([]);
        }

        const species = logbook.species?.find((s) =>
          equals(s.speciesId, speciesId)
        );

        const fishFormMap = new Map(
          logbook.fishForms?.map((f) => [f.id, f.description])
        );
        const measureMap = new Map(
          logbook.measures?.map((m) => [m.id, m.description])
        );

        return this.getLogbookCatchesSummary().pipe(
          map((catchesSummary) => catchesSummary.retained.catches),
          map((catchSummary) =>
            catchSummary.find((s) => equals(s.speciesId, species.id))
          ),
          map((catchSummary) => {
            const filteredSummary: ILogbookCatchReminder[] = [];

            catchSummary.fishForms.forEach((fishForm) => {
              const fishFormId = fishForm.fishFormId;

              fishForm.measures.forEach((value, measureId) => {
                const fishForm = fishFormMap.get(fishFormId);
                const measure = measureMap.get(measureId);

                if (isNil(fishForm) || isNil(measure)) {
                  return;
                }

                filteredSummary.push({
                  value,
                  measureDescription: measure,
                  fishFormDescription: fishForm,
                });
              });
            });
            return filteredSummary;
          }),
          map((reminders) =>
            reminders.sort((a, b) =>
              a.fishFormDescription.localeCompare(b.fishFormDescription)
            )
          )
        );
      })
    );
  }

  public getLogbookCatchesSummary(): Observable<{
    retained: ILogbookCatchSummarySection;
    discarded: ILogbookCatchSummarySection;
  }> {
    return this.logbookCatchesStore.select(selectCatches).pipe(
      withLatestFrom(this.getPrimaryFisherySymbolForTrip()),
      map(([catches, primaryFisherySymbol]) => {
        const speciesDescriptionMap = new Map(
          catches.map((catchItem) => [
            catchItem.speciesId,
            catchItem.speciesDescription,
          ])
        );

        const retainedMeasures = new Set<string>();
        const retainedMap: CatchMap = new Map();
        const discardedMeasures = new Set<string>();
        const discardedMap: CatchMap = new Map();

        catches
          .filter(
            (catchItem) =>
              catchItem.quantity > 0 &&
              (this.symbolsThatAllowQuantityMeasures.has(
                primaryFisherySymbol
              ) ||
                equals(catchItem.measureType, LogbookMeasureType.Number))
          )
          .forEach((catchItem) => {
            const measureMap = catchItem.discarded
              ? discardedMeasures
              : retainedMeasures;
            const speciesMap = catchItem.discarded ? discardedMap : retainedMap;

            measureMap.add(catchItem.measureId);
            if (speciesMap.has(catchItem.speciesId)) {
              const catches = speciesMap.get(catchItem.speciesId);

              if (catches.has(catchItem.fishFormId)) {
                const measures = catches.get(catchItem.fishFormId);

                if (measures.has(catchItem.measureId)) {
                  const measure = measures.get(catchItem.measureId);
                  measures.set(
                    catchItem.measureId,
                    measure + catchItem.quantity
                  );
                } else {
                  measures.set(catchItem.measureId, catchItem.quantity);
                }

                catches.set(catchItem.fishFormId, measures);
              } else {
                catches.set(
                  catchItem.fishFormId,
                  new Map([[catchItem.measureId, catchItem.quantity]])
                );
              }
            } else {
              speciesMap.set(
                catchItem.speciesId,
                new Map([
                  [
                    catchItem.fishFormId,
                    new Map([[catchItem.measureId, catchItem.quantity]]),
                  ],
                ])
              );
            }
          });

        return {
          retained: {
            catches: this.getCatchSummary(retainedMap, speciesDescriptionMap),
            measures: retainedMeasures,
          },
          discarded: {
            catches: this.getCatchSummary(discardedMap, speciesDescriptionMap),
            measures: discardedMeasures,
          },
        };
      })
    );
  }

  private getPrimaryFisherySymbolForTrip() {
    return this.tripStore
      .select(selectPreTrip)
      .pipe(map((trip) => trip.primaryFisherySymbol));
  }

  public getLogbookDaysSummary(): Observable<ILogbookDaySummary[]> {
    return this.logbookDaysStore.select(selectLogbookDays).pipe(
      withLatestFrom(this.getPrimaryFisherySymbolForTrip()),
      filter(([, primaryFisherySymbol]) => !!primaryFisherySymbol),
      switchMap(([days, primaryFisherySymbol]) =>
        combineLatest(
          [...days]
            .sort(
              (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
            )
            .map((day) =>
              this.logbookEventsStore
                .select(selectEventsByLogbookDayId(day.id))
                .pipe(
                  withLatestFrom(
                    this.userBucketStore.select(
                      selectLogbookReferenceByFisherySymbol(
                        primaryFisherySymbol
                      )
                    )
                  ),
                  map(([logbookEvents, logbookReference]) => {
                    const activityType = Math.min(
                      ...logbookEvents.map((event) => event.activityType)
                    );

                    return {
                      id: day.id,
                      date: day.date,
                      activityType: logbookReference.activityTypes.find(
                        (activityTypeRef) =>
                          equals(activityTypeRef.id, activityType)
                      )?.description,
                      fishingMethod: this.symbolsThatAllowMethods.has(
                        primaryFisherySymbol
                      )
                        ? this.getFishingMethodsForEvents(
                            logbookEvents,
                            logbookReference
                          )
                        : undefined,
                    };
                  })
                )
            )
        )
      )
    );
  }
}
