import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import {
  preferencesCDRFishFormsMeasuresFeatureKey,
  reducer,
} from './preference-cdr-fish-forms-measures.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(preferencesCDRFishFormsMeasuresFeatureKey, reducer),
  ],
})
export class PreferenceCDRFishFormsMeasuresModule {}
