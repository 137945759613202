import { createAction, props } from '@ngrx/store';
import {
  IDisposalPreference,
  IPreferencesDisposalState,
} from './preferences-disposal.reducer';

export const loadDisposalPreferences = createAction(
  '[Preferences Disposal] Load Disposal Preferences',
  props<{ preferences: IPreferencesDisposalState }>()
);

export const upsertDisposalPreference = createAction(
  '[Preferences Disposal] Upsert Disposal Preferences',
  props<{ disposal: IDisposalPreference }>()
);

export const deleteDisposalPreference = createAction(
  '[Preferences Disposal] Delete Disposal Preferences',
  props<{ id: string }>()
);
