<ion-menu contentId="main" menuId="main" [swipeGesture]="false">
  <ion-content [forceOverscroll]="false">
    <ion-menu-toggle>
      <ion-list>
        <ion-item lines="none" (click)="navigateTo(PageUrl.Home)">
          <ion-thumbnail>
            <ion-icon name="home-outline"></ion-icon>
          </ion-thumbnail>
          <ion-label> Home </ion-label>
        </ion-item>
        <ion-item lines="none" (click)="navigateTo(PageUrl.AuthorityDetails)">
          <ion-thumbnail>
            <ion-icon name="bag-check-outline"></ion-icon>
          </ion-thumbnail>
          <ion-label> View my authorities </ion-label>
        </ion-item>
        <ion-item lines="none" (click)="navigateTo(PageUrl.QuotaBalance)">
          <ion-thumbnail>
            <ion-icon name="wallet-outline"></ion-icon>
          </ion-thumbnail>
          <ion-label> View my quota balances </ion-label>
        </ion-item>
        <ion-item lines="none" (click)="navigateTo(PageUrl.VesselTracking)">
          <ion-thumbnail>
            <ion-icon name="boat-outline"></ion-icon>
          </ion-thumbnail>
          <ion-label> Check my vessel tracking devices </ion-label>
        </ion-item>
        <ion-item lines="none" (click)="navigateTo(PageUrl.ManualPolling)">
          <ion-thumbnail>
            <ion-icon name="clipboard-outline"></ion-icon>
          </ion-thumbnail>
          <ion-label> Vessel tracking - manual reporting </ion-label>
        </ion-item>
        <ion-item lines="none" (click)="navigateTo(PageUrl.AmendingNotice)">
          <ion-thumbnail>
            <ion-icon name="pencil-outline"></ion-icon>
          </ion-thumbnail>
          <ion-label> Amending notice </ion-label>
        </ion-item>
        <ion-item lines="none" (click)="navigateTo(PageUrl.Preferences)">
          <ion-thumbnail>
            <ion-icon name="cog-outline"></ion-icon>
          </ion-thumbnail>
          <ion-label> My preferences </ion-label>
        </ion-item>
        <ion-item lines="none" (click)="navigateTo(PageUrl.ContactFisheries)">
          <ion-thumbnail>
            <ion-icon name="call-outline"></ion-icon>
          </ion-thumbnail>
          <ion-label> Contact Fisheries </ion-label>
        </ion-item>
        <ion-item lines="none" (click)="navigateTo(PageUrl.ActivityLog)">
          <ion-thumbnail>
            <ion-icon name="reader-outline"></ion-icon>
          </ion-thumbnail>
          <ion-label> Activity Log </ion-label>
        </ion-item>
        <ion-item lines="none" (click)="navigateTo(aboutThisAppLink)">
          <ion-thumbnail>
            <ion-icon name="help-circle-outline"></ion-icon>
          </ion-thumbnail>
          <ion-label> Help </ion-label>
          <ion-spinner
            name="crescent"
            color="primary"
            slot="end"
            *ngIf="contentfulLoading$ | async"
          ></ion-spinner>
          <ion-button
            fill="clear"
            slot="end"
            (click)="reloadContentful($event)"
            *ngIf="contentfulLoadFailed$ | async"
          >
            <ion-icon
              name="refresh-outline"
              color="primary"
              slot="icon-only"
            ></ion-icon>
          </ion-button>
        </ion-item>
        <ion-item
          lines="none"
          (click)="navigateTo(PageUrl.AuthTest)"
          *ngIf="(targetEnv$ | async) !== 'prod'"
        >
          <ion-thumbnail>
            <ion-icon name="terminal-outline"></ion-icon>
          </ion-thumbnail>
          <ion-label> Auth Test </ion-label>
        </ion-item>
        <ion-item lines="none" (click)="logout()" button detail="false">
          <ion-thumbnail>
            <ion-icon color="danger" name="log-out-outline"></ion-icon>
          </ion-thumbnail>
          <ion-label color="danger"> Logout </ion-label>
        </ion-item>
        <ion-item
          button
          color="danger"
          detail="false"
          (click)="clearTestSession()"
          *ngIf="(targetEnv$ | async) !== 'prod'"
        >
          <ion-thumbnail>
            <ion-icon name="trash-outline"></ion-icon>
          </ion-thumbnail>
          Clear test session
        </ion-item>
      </ion-list>
    </ion-menu-toggle>
  </ion-content>
</ion-menu>
