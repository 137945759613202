import { createAction, props } from '@ngrx/store';
import {
  ICDRFishFormsMeasuresPreferenceState,
  SpeciesFishFormMeasurePreference,
} from './preference-cdr-fish-forms-measures.reducer';

export const loadCDRFishFormMeasurePreferences = createAction(
  '[Preferences CDR Fish Form and Measures] Load Fish form and measures',
  props<{ preferences: ICDRFishFormsMeasuresPreferenceState }>()
);

export const upsertCDRFishFormMeasurePreferences = createAction(
  '[Preferences CDR Fish Form and Measures] Upsert Fish form and measures',
  props<{
    logbook: string;
    species: string;
    preferences: SpeciesFishFormMeasurePreference[];
  }>()
);

export const deleteCDRFishFormMeasurePreferences = createAction(
  '[Preferences CDR Fish Form and Measures] Delete Fish form and measures',
  props<{
    logbook: string;
    species: string;
    preference: SpeciesFishFormMeasurePreference;
  }>()
);
