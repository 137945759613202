import { ErrorHandler, Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { environment } from '../../environments/environment';

@Injectable()
export class SentryErrorHandler extends ErrorHandler {
  constructor() {
    super();
  }
  public handleError(error) {
    if (environment.production) {
      Sentry.captureException(error.originalError || error);
    } else {
      super.handleError(error);
    }
  }
}
