import {
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from '@ngrx/store';
import { complement, equals } from '@qld-recreational/ramda';
import { switchUser } from '../auth/auth.actions';
import { clearSessionData } from '../settings/settings.actions';
import { ViewStatus } from '../shared/ViewStatus';
import {
  attemptSavePreferences,
  preferencesChanged,
  savePreferencesFailure,
  savePreferencesSuccess,
} from './preference.actions';

export const preferenceFeatureKey = 'preference';

export interface IPreferenceState {
  hasChange: boolean;
  viewStatus: ViewStatus;
}

export const initialState: IPreferenceState = {
  hasChange: false,
  viewStatus: ViewStatus.Initial,
};

export const preferenceFeaturePersistenceKeys = Object.keys(
  initialState
).filter(complement(equals('viewStatus')));

export const reducer = createReducer(
  initialState,
  on(preferencesChanged, (state) => ({ ...state, hasChange: true })),
  on(attemptSavePreferences, (state) => ({
    ...state,
    hasChange: false, // so that if any other preferencesChanged happens WHILE we're submitting, we don't lose the change and attempt to save again
    viewStatus: ViewStatus.Loading,
  })),
  on(savePreferencesSuccess, (state) => ({
    ...state,
    viewStatus: ViewStatus.Success,
  })),
  on(savePreferencesFailure, (state) => ({
    ...state,
    viewStatus: ViewStatus.Failure,
    hasChange: true, // to make sure to attempt again
  })),
  on(clearSessionData, switchUser, () => initialState)
);

const featureSelector =
  createFeatureSelector<IPreferenceState>(preferenceFeatureKey);

export const preferenceHasChangeSelector = createSelector(
  featureSelector,
  (state) => state.hasChange
);
export const preferenceViewStatusSelector = createSelector(
  featureSelector,
  (state) => state.viewStatus
);
