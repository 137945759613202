import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import * as fromPreferences from './preference.reducer';
import { PreferenceEffects } from './preference.effects';
import { PreferenceService } from './preference.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromPreferences.preferenceFeatureKey,
      fromPreferences.reducer
    ),
    EffectsModule.forFeature([PreferenceEffects]),
  ],
  providers: [PreferenceService],
})
export class PreferenceModule {}
