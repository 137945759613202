import { createAction, props } from '@ngrx/store';
import {
  ICatchDisposalRecord,
  ICDRInitSpecies,
} from './catch-disposal-record.reducer';
import { Update } from '@ngrx/entity';

export const initViewStatus = createAction(
  '[CatchDisposalRecord] Init View Status',
  props<{ id: string }>()
);

export const initSpecies = createAction(
  '[CatchDisposalRecord] Init Species',
  props<{ species: ICDRInitSpecies[] }>()
);

export const addCatchDisposalRecord = createAction(
  '[CatchDisposalRecord] Add Catch Disposal Record',
  props<{
    catchDisposalRecord: Omit<
      ICatchDisposalRecord,
      'cdrNumber' | 'cdrCatches' | 'species'
    >;
  }>()
);

export const updateCatchDisposalRecord = createAction(
  '[CatchDisposalRecord] Update Catch Disposal Record',
  props<{ catchDisposalRecord: Update<ICatchDisposalRecord> }>()
);

export const updateCatchDisposalRecords = createAction(
  '[CatchDisposalRecord] Update Catch Disposal Records',
  props<{ catchDisposalRecords: ICatchDisposalRecord[] }>()
);

export const clearCatchDisposalRecords = createAction(
  '[CatchDisposalRecord] Clear Catch Disposal Records'
);

export const deleteCatchDisposalRecord = createAction(
  '[CatchDisposalRecord] Delete Catch Disposal Record',
  props<{ id: string }>()
);

export const deleteCatchDisposalRecords = createAction(
  '[CatchDisposalRecord] Delete Catch Disposal Records',
  props<{ ids: string[] }>()
);
