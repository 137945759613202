<div class="flex-container">
  <div class="flex-inner-container ion-text-center">
    <div class="title">
      <ion-icon color="warning" name="warning-outline"></ion-icon>
      <p>Pending Qld eFisher transaction</p>
    </div>
    <div class="content ion-padding">
      <p>
        <span>Do not close or lock </span>this screen yet - your data is being
        submitted. <span>Please wait</span> until the transaction is complete
      </p>
    </div>
    <div class="swimming-fish">
      <img
        *ngFor="let i of [1, 2, 3, 4, 5, 6]"
        src="assets/images/fish.svg"
        [class]="'fish fish-' + i"
        alt=""
      />
    </div>
  </div>
</div>
