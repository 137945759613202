import {
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { equals } from '@qld-recreational/ramda';
import { switchUser } from '../auth/auth.actions';
import { clearSessionData } from '../settings/settings.actions';
import {
  addLogbookDay,
  deleteLogbookDay,
  updateLogbookDays,
} from './logbook-days.actions';
import * as moment from 'moment';
import { selectLogbookByFisherySymbol } from '../logbooks/logbooks.reducer';
import { endTripSuccessful } from '../trip/trip.actions';

export interface ILogbookDaySummary extends Pick<ILogbookDay, 'id' | 'date'> {
  activityType: string;
  fishingMethod?: string[];
}

export interface ILogbookDay {
  id: string;
  logbookId: string;
  date: string;
  completed: boolean;
  submitted: boolean;
}

export const logbookDaysFeatureKey = 'logbookDays';

export interface ILogbookDaysState extends EntityState<ILogbookDay> {}

export const adapter: EntityAdapter<ILogbookDay> = createEntityAdapter<
  ILogbookDay
>({
  selectId: (logbookDay) => logbookDay.id,
  sortComparer: (dayA, dayB) =>
    moment(dayA.date).isBefore(moment(dayB.date)) ? 1 : -1,
});

export const initialState: ILogbookDaysState = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(addLogbookDay, (state, { logbookDay }) =>
    adapter.addOne(logbookDay, state)
  ),
  on(deleteLogbookDay, (state, { id }) => adapter.removeOne(id, state)),
  on(clearSessionData, switchUser, endTripSuccessful, () => initialState),
  on(updateLogbookDays, (state, { logbookDays }) =>
    adapter.updateMany(
      logbookDays.map((logbookDay) => ({
        id: logbookDay.id,
        changes: logbookDay,
      })),
      state
    )
  )
);

const { selectAll } = adapter.getSelectors();

const selectLogbookDaysState = createFeatureSelector<ILogbookDaysState>(
  logbookDaysFeatureKey
);

export const selectLogbookDays = createSelector(
  selectLogbookDaysState,
  selectAll
);

export const selectLogbookDaysByLogbookId = (logbookId: string) =>
  createSelector(selectLogbookDays, (logbookDays) =>
    logbookDays.filter((logbookDay) => equals(logbookDay.logbookId, logbookId))
  );

export const selectLogbookDaysByDate = (date: string) =>
  createSelector(selectLogbookDays, (logbookDays) =>
    logbookDays.filter((logbookDay) => equals(logbookDay.date, date))
  );

// export const selectAllDates = createSelector(
//   selectLogbookDays,
//   (logbookDays) => [...new Set(logbookDays.map(logbookDay => logbookDay.date))],
// );

export const selectLogbookDayById = (id: string) =>
  createSelector(selectLogbookDays, (logbookDays) =>
    logbookDays.find((logbookDay) => equals(logbookDay.id, id))
  );

export const selectLogbookDayByFisherySymbolAndDate = (
  fisherySymbol: string,
  date: string
) =>
  createSelector(
    selectLogbookByFisherySymbol(fisherySymbol),
    selectLogbookDays,
    (logbook, logbookDays) =>
      logbookDays.find(
        (logbookDay) =>
          equals(logbookDay.logbookId, logbook.id) &&
          equals(logbookDay.date, date)
      )
  );
