import { createAction, props } from '@ngrx/store';
import { GeolocationAuthorizationStatus, ILocation } from './manual-polling';

export const appTrackLocation = createAction('[App] App Track Location');

export const locationReceived = createAction(
  '[Manual Polling] Location Received',
  props<{ location: ILocation }>()
);

export const stopPollingIfPollingHasStarted = createAction(
  '[Manual Polling] Stop Polling If Polling Has Started'
);

export const stopPolling = createAction('[Manual Polling] Stop Polling');

export const stopPollingFinished = createAction(
  '[Manual Polling] Stop Polling Finished'
);

export const startPolling = createAction(
  '[Manual Polling] Start Polling',
  props<{ licence: string; boatMark: string }>()
);

export const startManualPollingSuccessful = createAction(
  '[Manual Polling] Start Manual Polling Successful',
  props<{
    status: GeolocationAuthorizationStatus;
    licence: string;
    boatMark: string;
  }>()
);

export const startManualPollingFailed = createAction(
  '[Manual Polling] Start Manual Polling Failed'
);

export const updateSubmitStatus = createAction(
  '[Manual Polling] Update Submit Status',
  props<{ effectiveDateTimes: string[] }>()
);
