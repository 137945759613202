export enum PreferenceType {
  Disposal = 'disposal',
  Consignment = 'consignment',
}

export enum DisposalMethod {
  Wholesale = 1,
  RetailSales = 7,
  FishNotSoldStored = 2,
  FishNotSoldPersonalConsumption = 3,
  FishNotSoldSpoiled = 4,
}

export enum DisposalMethodDescription {
  Wholesale = 'Wholesale',
  RetailSales = 'Retail Sales',
  FishNotSoldStored = 'Fish not sold - stored',
  FishNotSoldPersonalConsumption = 'Fish not sold - personal consumption',
  FishNotSoldSpoiled = 'Fish not sold - spoiled',
}

export interface ICDRConsignment {
  CollectionAddress: string;
  DeliveryAddress: string;
  ConsigneeName: string;
  ConsignmentEmail: string;
}

export interface ICDRDisposal {
  DisposalMethod: DisposalMethod;
  DisposalEmail: string;
  WholesaleNameOfBuyer?: string;
  WholesaleDateOfSale?: string;
  WholesaleBuyerLicence?: number;
  WholesaleBuyerAddress?: string;
  StoredNameOfStorer?: string;
  StoredStorageAddress?: string;
}
