import { createAction, props } from '@ngrx/store';
import { ILogbookCatch } from './logbook-catches.reducer';

export const addLogbookCatches = createAction(
  '[LogbookCatches] Add Logbook Catches',
  props<{ logbookCatches: Array<ILogbookCatch> }>()
);

export const updateLogbookCatches = createAction(
  '[LogbookCatches] Update Logbook Catches',
  props<{ logbookCatches: Array<ILogbookCatch> }>()
);

export const removeLogbookCatches = createAction(
  '[LogbookCatches] Remove Logbook Catches',
  props<{ ids: Array<string> }>()
);
