import { Injectable } from '@angular/core';
import {
  LogbookSpecies,
  LogbookFishForm,
  LogbookMeasure,
  Logbook,
} from '../api/model';
import { v4 as uuidv4 } from 'uuid';
import { equals, includes, isNil } from '@qld-recreational/ramda';
import { Store } from '@ngrx/store';
import {
  IUserBucketState,
  selectCDRLogbookReferenceByFisherySymbol,
  selectCdrLogbooks,
} from '../user-bucket/user-bucket.reducer';
import { filter, map, take, withLatestFrom } from 'rxjs/operators';
import { Observable } from 'rxjs';
import {
  ICatchDisposalRecord,
  ICDRInitSpecies,
} from '../catch-disposal-record/catch-disposal-record.reducer';
import { ICDRCatch } from './catch-disposal-record-catches.reducer';
import {
  ICDRFishFormsMeasuresPreferenceState,
  selectCDRFishFormPreferenceForSpecies,
} from '../preference-cdr-fish-forms-measures/preference-cdr-fish-forms-measures.reducer';
import { LogbookService } from '../shared/logbook.service';
import { propEq } from 'rambda';

@Injectable()
export class CatchDisposalRecordCatchesService {
  constructor(
    private userBucketStore: Store<IUserBucketState>,
    private logbookService: LogbookService,
    private formMeasurePreferenceStore: Store<ICDRFishFormsMeasuresPreferenceState>
  ) {}

  public initCDRCatch = (
    cdrId: string,
    species: LogbookSpecies,
    fisherySymbol: string,
    fishForm: LogbookFishForm,
    measure: LogbookMeasure
  ) => ({
    cdrId,
    id: uuidv4(),
    fisherySymbol,
    logbookEventId: undefined,
    quotaSymbol: undefined,
    speciesId: species.id,
    speciesCDRId: species.speciesId,
    speciesDescription: species.description,
    fishFormId: fishForm.id,
    fishFormDescription: fishForm.description,
    measureId: measure.id,
    measureType: measure.type,
    quantity: null,
    measureDescription: measure.description,
    minimumValue: measure.minimumValue,
    maximumValue: measure.maximumValue,
    scale: measure.scale,
    measureRequired: measure.required,
    increment: measure.increment,
    description: `${fishForm.description} - ${measure.description}`,
  });

  public getAllowedFishForms(
    logbook: Logbook,
    species: LogbookSpecies
  ): LogbookFishForm[] {
    return logbook.fishForms?.filter((fishForm) =>
      isNil(species.allowedFishForms)
        ? true
        : includes(fishForm.id, species.allowedFishForms)
    );
  }

  public getAllowedMeasures(
    logbook: Logbook,
    species: LogbookSpecies
  ): LogbookMeasure[] {
    return logbook.measures?.filter((measure) =>
      isNil(species.allowedMeasures)
        ? true
        : includes(measure.id, species.allowedMeasures)
    );
  }

  public getCDRLogbookBySpecies(species: ICDRInitSpecies): Observable<Logbook> {
    return this.userBucketStore
      .select(selectCDRLogbookReferenceByFisherySymbol(species.fisherySymbol))
      .pipe(take(1));
  }

  public getCDRCatchesFromPreferences(
    species: ICDRInitSpecies,
    logbook: Logbook,
    cdr: Omit<ICatchDisposalRecord, 'cdrCatches' | 'species' | 'cdrNumber'>
  ): Observable<ICDRCatch[]> {
    const cdrLogbookSpecies = logbook.species?.find((refSpecies) =>
      equals(refSpecies.speciesId, species.speciesCDRId)
    );
    return this.formMeasurePreferenceStore
      .select(
        selectCDRFishFormPreferenceForSpecies(
          logbook.logbook,
          cdrLogbookSpecies.id.toString()
        )
      )
      .pipe(
        filter((preferences) => propEq('length', 1, preferences)),
        map((preferences) => preferences[0]),
        withLatestFrom(
          this.logbookService.selectFisherySymbol(species.speciesCDRId)
        ),
        map(([preference, fisherySymbol]) =>
          this.getAllowedFishForms(logbook, cdrLogbookSpecies)
            .filter((fishForm) => equals(fishForm.id, preference.fishFormID))
            .flatMap((fishForm) =>
              this.getAllowedMeasures(logbook, cdrLogbookSpecies)
                .filter(
                  (measure) =>
                    measure.required || equals(measure.id, preference.measureID)
                )
                .sort((a, b) => Number(a.required) - Number(b.required))
                .map((measure) =>
                  this.initCDRCatch(
                    cdr.id,
                    cdrLogbookSpecies,
                    fisherySymbol,
                    fishForm,
                    measure
                  )
                )
            )
        )
      );
  }
}
