import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  lockLogbookSuccessful,
  submitPriorEmergencyNoticeSuccessful,
  submitWeightNoticeSuccessful,
} from '../trip/trip.actions';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import {
  ICatchDisposalRecordState,
  selectCatchDisposalRecords,
  selectCatchDisposalRecordsByWeightNoticeId,
} from './catch-disposal-record.reducer';
import {
  deleteCatchDisposalRecords,
  initSpecies,
  updateCatchDisposalRecords,
} from './catch-disposal-record.actions';
import { CatchDisposalRecordService } from './catch-disposal-record.service';
import { deleteWeightNotice } from '../weight-notice/weight-notice.actions';

@Injectable()
export class CatchDisposalRecordEffects {
  public initSpeciesDescriptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(lockLogbookSuccessful),
      filter(({ noQuota }) => !noQuota),
      switchMap(() => this.cdrService.getInitSpecies()),
      map((species) => initSpecies({ species }))
    )
  );

  public updateWeightTransactionNumber$ = createEffect(() =>
    this.actions$.pipe(
      ofType(submitWeightNoticeSuccessful),
      switchMap(({ receipt, id }) =>
        this.cdrStore
          .select(selectCatchDisposalRecordsByWeightNoticeId(id))
          .pipe(
            filter((cdrs) => cdrs.length > 0),
            take(1),
            map((cdrs) =>
              cdrs.map((cdr) => ({ ...cdr, WeightNoticeReceipt: receipt }))
            )
          )
      ),
      map((catchDisposalRecords) =>
        updateCatchDisposalRecords({ catchDisposalRecords })
      )
    )
  );

  public deleteCDRs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteWeightNotice),
      switchMap(({ id }) =>
        this.cdrStore
          .select(selectCatchDisposalRecordsByWeightNoticeId(id))
          .pipe(
            filter((cdrs) => cdrs.length > 0),
            take(1),
            map((cdrs) => cdrs.map((cdr) => cdr.id))
          )
      ),
      map((ids) => deleteCatchDisposalRecords({ ids }))
    )
  );

  public updatePriorEmergencyTransactionNumber$ = createEffect(() =>
    this.actions$.pipe(
      ofType(submitPriorEmergencyNoticeSuccessful),
      switchMap(({ receipt }) =>
        this.cdrStore.select(selectCatchDisposalRecords).pipe(
          filter((cdrs) => cdrs.length > 0),
          take(1),
          map((cdrs) =>
            cdrs.map((cdr) => ({ ...cdr, PriorNoticeReceipt: receipt }))
          )
        )
      ),
      map((catchDisposalRecords) =>
        updateCatchDisposalRecords({ catchDisposalRecords })
      )
    )
  );

  constructor(
    private actions$: Actions,
    private cdrStore: Store<ICatchDisposalRecordState>,
    private cdrService: CatchDisposalRecordService
  ) {}
}
