import { createReducer, on } from '@ngrx/store';
import { complement, equals } from '@qld-recreational/ramda';
import { LandingPoint } from '../api/model';
import { switchUser } from '../auth/auth.actions';
import { clearSessionData } from '../settings/settings.actions';
import { ViewStatus } from '../shared/ViewStatus';
import * as AmendingNoticeActions from './amending-notice.actions';
import { endTripSuccessful } from '../trip/trip.actions';

export const amendingNoticeFeatureKey = 'amendingNotice';

export interface IAmendingNoticeState {
  receipt: string;
  viewStatus: ViewStatus;
  location: LandingPoint;
}

export const initialState: IAmendingNoticeState = {
  receipt: undefined,
  viewStatus: ViewStatus.Initial,
  location: undefined,
};

export const amendingNoticePersistenceKeys = Object.keys(initialState).filter(
  complement(equals('viewStatus'))
);

export const reducer = createReducer(
  initialState,

  on(AmendingNoticeActions.amendLandingLocation, (state, { payload }) => ({
    ...state,
    location: payload.landingPoint,
  })),
  on(AmendingNoticeActions.amendLandingLocationSuccess, (state, { data }) => ({
    ...state,
    receipt: data.receipt,
  })),
  on(
    AmendingNoticeActions.amendLandingLocationFailure,
    (state, action) => state
  ),
  on(clearSessionData, switchUser, endTripSuccessful, () => initialState)
);
