import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { v4 as uuidv4 } from 'uuid';
import {
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from '@ngrx/store';
import { utcNow } from '@qld-recreational/moment';
import { switchUser } from '../auth/auth.actions';
import { clearSessionData } from '../settings/settings.actions';
import { ViewStatus } from '../shared/ViewStatus';
import { BackgroundRequest } from './background-request.model';
import {
  addBackgroundRequest,
  attemptBackgroundRequest,
  deleteBackgroundRequest,
  noConnectionBackgroundRequest,
  submitBackgroundRequest,
  submitBackgroundRequestFailure,
  updateBackgroundRequest,
} from './background-request.actions';
import { complement, equals, isNil } from '@qld-recreational/ramda';
import { BackgroundRequestPath } from './background-request.service';

export const backgroundRequestsFeatureKey = 'backgroundRequests';

export interface IBackgroundRequestState
  extends EntityState<BackgroundRequest> {
  viewStatus: ViewStatus;
}

export const adapter: EntityAdapter<BackgroundRequest> = createEntityAdapter<
  BackgroundRequest
>({
  selectId: (backgroundRequest) => backgroundRequest.id,
});

export const initialState: IBackgroundRequestState = adapter.getInitialState({
  viewStatus: ViewStatus.Initial,
});

export const backgroundRequestPersistenceKeys = Object.keys(
  initialState
).filter(complement(equals('viewStatus')));

export const reducer = createReducer(
  initialState,
  on(addBackgroundRequest, (state, { backgroundRequest }) =>
    adapter.upsertOne(
      {
        ...backgroundRequest,
        ...(isNil(backgroundRequest.id) ? { id: uuidv4() } : {}),
        requestId: uuidv4(),
        status: ViewStatus.Loading,
        dateCreated: utcNow(),
      },
      state
    )
  ),
  on(updateBackgroundRequest, (state, action) =>
    adapter.updateOne(action.backgroundRequest, {
      ...state,
      viewStatus: ViewStatus.Success,
    })
  ),
  on(deleteBackgroundRequest, (state, action) =>
    adapter.removeOne(action.id, state)
  ),
  on(submitBackgroundRequestFailure, (state, action) =>
    adapter.updateOne(action.backgroundRequest, {
      ...state,
      viewStatus: ViewStatus.Failure,
    })
  ),
  on(attemptBackgroundRequest, submitBackgroundRequest, (state) => ({
    ...state,
    viewStatus: ViewStatus.Loading,
  })),
  on(noConnectionBackgroundRequest, (state) => ({
    ...state,
    viewStatus: ViewStatus.Failure,
  })),
  on(clearSessionData, switchUser, () => initialState)
);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

const selectBackgroundRequestState = createFeatureSelector<
  IBackgroundRequestState
>(backgroundRequestsFeatureKey);

export const selectBackgroundRequests = createSelector(
  selectBackgroundRequestState,
  selectAll
);

export const selectBackgroundRequestsViewStatus = createSelector(
  selectBackgroundRequestState,
  ({ viewStatus }) => viewStatus
);

export const selectManualPositionBackgroundRequest = createSelector(
  selectBackgroundRequests,
  selectBackgroundRequestsViewStatus,
  (requests, viewStatus) =>
    requests.find(
      (request) =>
        equals(request.path, BackgroundRequestPath.ManualPositions) &&
        !equals(viewStatus, ViewStatus.Loading)
    )
);
