import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  debounceTime,
  filter,
  map,
  switchMap,
  take,
  withLatestFrom,
} from 'rxjs/operators';
import { ApiService } from '../api/api.service';
import { loginSuccess } from '../auth/auth.actions';
import {
  deleteCDRFishFormMeasurePreferences,
  loadCDRFishFormMeasurePreferences,
  upsertCDRFishFormMeasurePreferences,
} from '../preference-cdr-fish-forms-measures/preference-cdr-fish-forms-measures.actions';
import {
  deleteConsignmentPreference,
  loadConsignmentPreferences,
  upsertConsignmentPreference,
} from '../preference-consignment/preferences-consignment.action';
import {
  deleteDisposalPreference,
  loadDisposalPreferences,
  upsertDisposalPreference,
} from '../preference-disposal/preferences-disposal.action';
import {
  loadGPSPreferences,
  UpdateGPSPreferences,
} from '../preference-gps/preference-gps.actions';
import {
  deleteLandingPlacesPreference,
  loadLandingPlacesPreferences,
  upsertLandingPlacesPreference,
} from '../preference-landing-places/preference-landing-places.action';
import {
  addFavouriteRegionForLogbook,
  addFavouriteSpeciesForRegion,
  deleteFavouriteRegionForLogbook,
  deleteFavouriteSpeciesForRegion,
  loadRegionsSpeciesPreferences,
} from '../preferences-regions-species/preferences-regions-species.actions';
import { PreferenceService } from './preference.service';
import {
  preferencesChanged,
  attemptSavePreferences,
  savePreferencesSuccess,
  savePreferencesFailure,
} from './preference.actions';
import { of, timer } from 'rxjs';
import { Store } from '@ngrx/store';
import {
  IPreferenceState,
  preferenceHasChangeSelector,
  preferenceViewStatusSelector,
} from './preference.reducer';
import { equals, isNilOrEmpty, not } from '@qld-recreational/ramda';
import { ViewStatus } from '../shared/ViewStatus';
import { IAuthState, selectEmail } from '../auth/auth.reducer';
import { StorageSyncActions } from '../reducers/ngrx-ionic-storage-sync';
import { BackgroundRequestService } from '../background-request/background-request.service';

@Injectable()
export class PreferenceEffects {
  private readonly SUBMIT_INTERVAL = 10 * 1_000;

  public loadPreferneces$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loginSuccess),
      switchMap(() =>
        this.apiService.getUserPreferences().pipe(
          switchMap((payload) => {
            // if the preferences are empty, meaning the user never set preferences before
            // set the preferences with the current preference from their device
            if (isNilOrEmpty(payload)) {
              return of(preferencesChanged());
            }

            const preferences =
              this.preferenceService.transformPreferencesFromPayload(payload);
            return [
              loadGPSPreferences({ preferences: preferences.preferencesGPS }),
              loadDisposalPreferences({
                preferences: preferences.preferencesDisposal,
              }),
              loadConsignmentPreferences({
                preferences: preferences.preferencesConsignment,
              }),
              loadLandingPlacesPreferences({
                preferences: preferences.preferencesLandingPlaces,
              }),
              loadRegionsSpeciesPreferences({
                preferences: preferences.preferenceRegionsAndSpecies,
              }),
              loadCDRFishFormMeasurePreferences({
                preferences: preferences.preferencesCDRFishFormsMeasures,
              }),
            ];
          })
        )
      )
    )
  );

  /**
   * Listen to all preference changes and dispatch an action to let the app know that preferences have changed
   */
  public preferencesChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        UpdateGPSPreferences, // GPS preferences
        upsertDisposalPreference, // Disposal preferences
        deleteDisposalPreference,
        upsertConsignmentPreference, // Consignment preferences
        deleteConsignmentPreference,
        upsertLandingPlacesPreference, // Landing places preferences
        deleteLandingPlacesPreference,
        addFavouriteRegionForLogbook, // Logbook regions and species preferences
        deleteFavouriteRegionForLogbook,
        addFavouriteSpeciesForRegion,
        deleteFavouriteSpeciesForRegion,
        upsertCDRFishFormMeasurePreferences, // CDR fish forms and measure preferences
        deleteCDRFishFormMeasurePreferences
      ),
      debounceTime(2000),
      map(() => preferencesChanged())
    )
  );

  public listenForChanges$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StorageSyncActions.HYDRATED),
      switchMap(() =>
        timer(0, this.SUBMIT_INTERVAL).pipe(
          withLatestFrom(this.authStore.select(selectEmail)),
          filter(([, email]) => not(isNilOrEmpty(email))),
          withLatestFrom(
            this.preferenceStore.select(preferenceViewStatusSelector)
          ),
          filter(([, viewStatus]) => !equals(viewStatus, ViewStatus.Loading)),
          withLatestFrom(
            this.preferenceStore.select(preferenceHasChangeSelector)
          ),
          filter(([, hasChange]) => hasChange),
          switchMap(() =>
            this.backgroundRequestService.checkConnectivity().pipe(take(1))
          ),
          filter((isConnected) => isConnected),
          map(() => attemptSavePreferences())
        )
      )
    )
  );

  public submitPreferences$ = createEffect(() =>
    this.actions$.pipe(
      ofType(attemptSavePreferences),
      withLatestFrom(this.preferenceService.getCurrentPreferences()),
      switchMap(([, payload]) =>
        this.apiService.saveUserPreference(payload).pipe(
          map(() => savePreferencesSuccess()),
          catchError((error) => {
            console.error(error);
            return of(savePreferencesFailure());
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private preferenceService: PreferenceService,
    private preferenceStore: Store<IPreferenceState>,
    private authStore: Store<IAuthState>,
    private backgroundRequestService: BackgroundRequestService
  ) {}
}
