import { ActivityStatus, Page } from '../api/model';
import { utcNow } from '@qld-recreational/moment';

const manualPollingGeneralActivityLog = {
  action: 'Vessel tracking - manual report',
};

export const startPollingActivityLog = (
  tripCorrelationID: string,
  boatMark: string
) => ({
  tripCorrelationID,
  page: Page.ManualPolling,
  message: `Manual reporting for <em>${boatMark}</em> is commenced`,
  status: ActivityStatus.success,
  timestamp: utcNow(),
  ...manualPollingGeneralActivityLog,
});

export const stopPollingActivityLog = (
  tripCorrelationID: string,
  boatMark: string
) => ({
  tripCorrelationID,
  page: Page.ManualPolling,
  message: `Manual reporting for <em>${boatMark}</em> is stopped`,
  status: ActivityStatus.success,
  timestamp: utcNow(),
  ...manualPollingGeneralActivityLog,
});
