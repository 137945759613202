import { createAction, props } from '@ngrx/store';
import { ILogbookDay } from './logbook-days.reducer';

export const addLogbookDay = createAction(
  '[LogbookDays] Add Logbook Day',
  props<{ logbookDay: ILogbookDay }>()
);

export const deleteLogbookDay = createAction(
  '[LogbookDays] Delete Logbook Day',
  props<{ id: string }>()
);

export const updateLogbookDays = createAction(
  '[LogbookDays] Update Logbook Days',
  props<{ logbookDays: ILogbookDay[] }>()
);
