import {
  ActivityLogPostEntry,
  ActivityStatus,
  Page,
} from '../api/model/activityLogPostEntry';
import { utcNow } from '@qld-recreational/moment';
import { environment } from '../../environments/environment';

export const loadUserBucketSuccessActivityLog = (
  page: Page = Page.Login,
  tripCorrelationID: string
): ActivityLogPostEntry => ({
  tripCorrelationID,
  page,
  action: 'Load user bucket',
  timestamp: utcNow(),
  message: `User bucket has been loaded successfully. User app version number ${environment.version} (${environment.buildNumber})`,
  status: ActivityStatus.success,
});

export const firewallBlockActivityLog = (
  page: Page = Page.Login,
  message: string
): ActivityLogPostEntry => ({
  page,
  action: 'Firewall block',
  timestamp: utcNow(),
  message: `The app has been blocked by the department’s security firewall. Please contact the Digital Solutions section and provide the following information:<br>${message}`,
  status: ActivityStatus.error,
});

export const noPCFLorCFLLog = (
  page: Page = Page.Login
): ActivityLogPostEntry => ({
  page,
  action: 'No CFIC association',
  timestamp: utcNow(),
  message: 'Login rejected - the CFL and/or PCFL is not valid.',
  status: ActivityStatus.error,
});
