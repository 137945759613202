import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import * as fromSettingsState from '../settings/settings.reducer';
import { SettingsEffects } from './settings.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromSettingsState.settingsFeatureKey,
      fromSettingsState.reducer
    ),
    EffectsModule.forFeature([SettingsEffects]),
  ],
})
export class SettingsModule {}
