import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromTrip from './trip.reducer';
import { equals, isNilOrEmpty } from '@qld-recreational/ramda';
import { ActivityEmergencyCreate, TripTypeId } from '../api/model';

export const selectTripState = createFeatureSelector<fromTrip.ITripState>(
  fromTrip.tripFeatureKey
);

export const selectTripId = createSelector(
  selectTripState,
  (state) => state.id
);

export const selectPreTripRequestId = createSelector(
  selectTripState,
  (state) => state.preTripRequestId
);

export const selectEndTripRequestId = createSelector(
  selectTripState,
  (state) => state.endTripRequestId
);

export const selectTripStep = createSelector(
  selectTripState,
  (state) => state.tripStep
);

export const selectLogbookCreate = createSelector(
  selectTripState,
  (state) => state.logbookCreate
);

export const selectPriorEmergencyCreate = createSelector(
  selectTripState,
  (state) => state.priorEmergencyNoticeCreate
);

export const selectBoatMark = createSelector(
  selectTripState,
  (state) => state.boatMark
);

export const selectCDRInfo = createSelector(
  selectTripState,
  ({ boatMark, preTrip }) => ({
    boatMark,
    primaryCommercialFishingLicence: preTrip.primaryCommercialFishingLicence,
  })
);

export const selectPreTrip = createSelector(
  selectTripState,
  (state) => state.preTrip
);

export const selectPreTripReceipt = createSelector(
  selectPreTrip,
  (preTrip) => preTrip?.receipt
);

export const selectIsPreTripCommercial = createSelector(
  selectPreTrip,
  (preTrip) => equals(preTrip?.tripTypeID, TripTypeId.Commercial)
);

export const selectPreTripLandingPoint = createSelector(
  selectTripState,
  (state) => state?.preTrip?.landingPoint
);

export const selectActivityNoticeStatus = createSelector(
  selectTripState,
  ({
    emergencyActivityNoticeEnabled,
    priorActivityNoticeEnabled,
    retainActivityNoticeEnabled,
    weightActivityNoticeEnabled,
  }) => ({
    emergencyActivityNoticeEnabled,
    priorActivityNoticeEnabled,
    retainActivityNoticeEnabled,
    weightActivityNoticeEnabled,
  })
);

export const selectIsPriorNotice = createSelector(
  selectTripState,
  ({ priorEmergencyNoticeCreate }) =>
    isNilOrEmpty(
      (priorEmergencyNoticeCreate as ActivityEmergencyCreate).landingPoint
    )
);

export const selectRetainNoticeCreate = createSelector(
  selectTripState,
  (state) => state.retainNoticeCreate
);

export const selectCatchDisposalLogbookEnabled = createSelector(
  selectTripState,
  (state) => state.catchDisposalLogbookEnabled
);

export const selectWeightActivityNoticeEnabled = createSelector(
  selectTripState,
  (state) => state.weightActivityNoticeEnabled
);

export const selectEndTripViewStatus = createSelector(
  selectTripState,
  (state) => state.endTripViewStatus
);
