import {
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from '@ngrx/store';
import { clone, equals, isNilOrEmpty } from '@qld-recreational/ramda';
import {
  endTripSuccessful,
  lockPreTrip,
  tripStepChange,
} from '../trip/trip.actions';
import { switchUser } from '../auth/auth.actions';
import { clearSessionData } from '../settings/settings.actions';
import { IPage } from '../shared/models/Page';
import { TripStep } from '../trip/trip-step';
import { lockPreTripPage, unlockPreTripPage } from './home.actions';

export const homeFeatureKey = 'home';

const TripStepToPageTitle = {
  ReportNilFishing: 'Record day/s not commercially fished',
  PreTrip: 'Pre-trip notice',
  LogbookHome: 'Logbook home',
  QuotaReporting: 'Quota reporting',
};

const TripStepToPageHref = {
  ReportNilFishing: '/nil-fishing',
  PreTrip: '/ready-to-fish',
  LogbookHome: '/logbook-home',
  QuotaReporting: '/activity-notices',
};

export interface IHomeState {
  pages: Array<IPage>;
}

export const initialState: IHomeState = {
  pages: [
    {
      href: TripStepToPageHref.ReportNilFishing,
      title: TripStepToPageTitle.ReportNilFishing,
      description:
        'Record day/s not fished outside of a commercial fishing operation',
      enabled: true,
      viewOnly: false,
    },
    {
      href: TripStepToPageHref.PreTrip,
      title: TripStepToPageTitle.PreTrip,
      description: 'Register and confirm your fishing operation purpose',
      enabled: true,
      viewOnly: false,
    },
    {
      href: TripStepToPageHref.LogbookHome,
      title: TripStepToPageTitle.LogbookHome,
      description: 'Record your catch and effort',
      enabled: false,
      viewOnly: false,
    },
    {
      href: TripStepToPageHref.QuotaReporting,
      title: TripStepToPageTitle.QuotaReporting,
      description:
        'Prepare and submit your quota activity notices and catch disposal record/s',
      enabled: false,
      viewOnly: false,
    },
  ],
};

const isNilFishing = (page: IPage) =>
  equals(page.href, TripStepToPageHref.ReportNilFishing);

const isPagePreTrip = (page: IPage) =>
  equals(page.href, TripStepToPageHref.PreTrip);

const pagesForRecordCatch = (state: IHomeState) => {
  const pages = clone(state.pages);
  pages.find((p) => equals(p.href, TripStepToPageHref.LogbookHome)).enabled =
    true;
  pages.find((p) =>
    equals(p.href, TripStepToPageHref.ReportNilFishing)
  ).enabled = false;
  pages.find((p) => equals(p.href, TripStepToPageHref.PreTrip)).viewOnly = true;
  return { pages };
};

const pagesAfterLockLogbookHome = (state: IHomeState) => {
  const pages = clone(state.pages);
  const logbookPage = pages.find((p) =>
    equals(p.href, TripStepToPageHref.LogbookHome)
  );
  if (!isNilOrEmpty(logbookPage)) {
    logbookPage.viewOnly = true;
  }
  return pages;
};

const pagesForActivityNotice = (state: IHomeState) => {
  const pages = pagesAfterLockLogbookHome(state);
  pages.find((p) => equals(p.href, TripStepToPageHref.QuotaReporting)).enabled =
    true;
  return { pages };
};

const pagesForReadyToEndTrip = (state: IHomeState) => {
  const pages = pagesAfterLockLogbookHome(state);
  return { pages };
};

const handleTripStepChange = (state: IHomeState, { tripStep }) => {
  switch (tripStep) {
    case TripStep.RecordCatch:
      return pagesForRecordCatch(state);
    case TripStep.PriorEmergencyNotice:
    case TripStep.RetainWeightNotices:
    case TripStep.RetainNotice:
    case TripStep.WeightNotices:
      return pagesForActivityNotice(state);
    case TripStep.ReadyToEndTrip:
      return pagesForReadyToEndTrip(state);
    default:
      return state;
  }
};

const togglePreTripPage = (enabled: boolean) => (state: IHomeState) => {
  const pages = clone(state.pages);

  pages.find(isPagePreTrip).enabled = enabled;

  return { pages };
};

const toggleNilFishingPage = (enabled: boolean) => (state: IHomeState) => {
  const pages = clone(state.pages);

  pages.find(isNilFishing).enabled = enabled;

  return { pages };
};

export const reducer = createReducer(
  initialState,
  on(tripStepChange, handleTripStepChange),
  on(lockPreTrip, toggleNilFishingPage(false)),
  on(lockPreTripPage, togglePreTripPage(false)),
  on(unlockPreTripPage, togglePreTripPage(true)),
  on(switchUser, clearSessionData, endTripSuccessful, () => initialState)
);

export const selectHomeState =
  createFeatureSelector<IHomeState>(homeFeatureKey);

export const selectPages = createSelector(
  selectHomeState,
  (state) => state?.pages
);

export const selectIfActivityNoticeIsDisabled = createSelector(
  selectPages,
  (pages) =>
    !pages.find((page) => equals(page.href, TripStepToPageHref.QuotaReporting))
      .enabled
);
