import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  lockLogbookSuccessful,
  submitPriorEmergencyNoticeSuccessful,
} from '../trip/trip.actions';
import { filter, map, switchMap } from 'rxjs/operators';
import { TripService } from '../trip/trip.service';
import { PriorEmergencyNoticeService } from './prior-emergency-notice.service';
import { addQuotaCatches } from './prior-emergency-notice.actions';
import { updateBackgroundRequest } from '../background-request/background-request.actions';
import { includes, isNilOrEmpty } from '@qld-recreational/ramda';
import { BackgroundRequestPath } from '../background-request/background-request.service';
import { AlertService } from '@qld-recreational/alert';
import { Store } from '@ngrx/store';
import { ITripState } from '../trip/trip.reducer';
import { ActivityEmergencyCreate } from '../api/model';

@Injectable()
export class PriorEmergencyNoticeEffects {
  public initQuotaCatches$ = createEffect(() =>
    this.actions$.pipe(
      ofType(lockLogbookSuccessful),
      filter(({ noQuota }) => !noQuota),
      switchMap(() => this.priorEmergencyNoticeService.getMyQuotaSymbols()),
      map((quotaCatches) => addQuotaCatches({ quotaCatches }))
    )
  );

  public backgroundRequestSubmitted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateBackgroundRequest),
      filter(({ backgroundRequest: { changes: { path } } }) =>
        includes(path, [
          BackgroundRequestPath.PriorNotice,
          BackgroundRequestPath.EmergencyNotice,
        ])
      ),
      map(
        ({
          backgroundRequest: {
            changes: { payload, transactionNumber },
          },
        }) => {
          const isPriorNotice = isNilOrEmpty(
            (payload as ActivityEmergencyCreate).landingPoint
          );
          this.alertService.presentSingleActionAlert(
            `${
              isPriorNotice ? 'Prior notice' : 'Emergency notice'
            } submitted successfully`,
            `Transaction number is <span>${transactionNumber}</span>`
          );
          return submitPriorEmergencyNoticeSuccessful({
            receipt: Number(transactionNumber),
          });
        }
      )
    )
  );

  constructor(
    private actions$: Actions,
    private tripService: TripService,
    private alertService: AlertService,
    private tripStore: Store<ITripState>,
    private priorEmergencyNoticeService: PriorEmergencyNoticeService
  ) {}
}
