import { createAction, props } from '@ngrx/store';
import { IQuotaCatch } from './prior-emergency-notice.reducer';
import { LandingPoint } from '../api/model';
import { LocationType } from '../shared/models/Location';

export const addQuotaCatches = createAction(
  '[PriorEmergencyNotice] Add Quota Catches',
  props<{ quotaCatches: Array<IQuotaCatch> }>()
);

export const updateQuotaCatches = createAction(
  '[PriorEmergencyNotice] Update Quota Catches',
  props<{ quotaCatches: Array<IQuotaCatch> }>()
);

export const updateEmergencyLandingPoint = createAction(
  '[PriorEmergencyNotice] Update Emergency Landing Point',
  props<{ emergencyLandingPoint: LandingPoint; locationType: LocationType }>()
);
