import {
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from '@ngrx/store';
import { ILogbookCatch } from '../logbook-catches/logbook-catches.reducer';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import {
  removeCDRCatches,
  updateCDRCatches,
} from './catch-disposal-record-catches.actions';
import { equals } from '@qld-recreational/ramda';
import { clearSessionData } from '../settings/settings.actions';
import { switchUser } from '../auth/auth.actions';
import { endTripSuccessful } from '../trip/trip.actions';

export interface ICDRCatch extends ILogbookCatch {
  cdrId: string;
  fisherySymbol: string;
  measureRequired?: boolean;
}

export const catchDisposalRecordCatchesFeatureKey =
  'catchDisposalRecordCatches';

export interface ICatchDisposalRecordCatchesState
  extends EntityState<ICDRCatch> {}

export const adapter: EntityAdapter<ICDRCatch> = createEntityAdapter<ICDRCatch>(
  {
    selectId: (cdrCatch) => cdrCatch.id,
  }
);

export const initialState: ICatchDisposalRecordCatchesState = adapter.getInitialState(
  {}
);

export const reducer = createReducer(
  initialState,
  on(updateCDRCatches, (state, { cdrCatches }) =>
    adapter.upsertMany(cdrCatches, state)
  ),
  on(removeCDRCatches, (state, { keys }) => adapter.removeMany(keys, state)),
  on(clearSessionData, switchUser, endTripSuccessful, () => initialState)
);

const { selectAll } = adapter.getSelectors();

const selectCDRCatchesState = createFeatureSelector<
  ICatchDisposalRecordCatchesState
>(catchDisposalRecordCatchesFeatureKey);

export const selectCDRCatches = createSelector(
  selectCDRCatchesState,
  selectAll
);

export const selectCDRCatchesByCDRId = (cdrId: string) =>
  createSelector(selectCDRCatches, (cdrCatches) =>
    cdrCatches.filter((cdrCatch) => equals(cdrCatch.cdrId, cdrId))
  );

export const selectCDRCatchesByCDRIdAndSpeciesCDRId = (
  cdrId: string,
  speciesCDRId: number
) =>
  createSelector(selectCDRCatchesByCDRId(cdrId), (cdrCatches) =>
    cdrCatches.filter((cdrCatch) => equals(cdrCatch.speciesCDRId, speciesCDRId))
  );
