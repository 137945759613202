import {
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ActivityLogPostEntry } from '../api/model/activityLogPostEntry';
import {
  log,
  removeActivityLogs,
  submitActivityLog,
  submitActivityLogFailure,
  submitActivityLogSuccess,
} from './activity-log.actions';
import * as moment from 'moment';
import { utcNow } from '@qld-recreational/moment';
import { clearSessionData } from '../settings/settings.actions';
import { switchUser } from '../auth/auth.actions';
import { v4 as uuidv4 } from 'uuid';
import { ViewStatus } from '../shared/ViewStatus';

export const activityLogFeatureKey = 'activityLog';

export interface IActivityLogState extends EntityState<ActivityLogPostEntry> {
  requestId: string;
  lastPushed: string;
  viewStatus: ViewStatus;
}

export const adapter: EntityAdapter<ActivityLogPostEntry> = createEntityAdapter<
  ActivityLogPostEntry
>({
  selectId: (activityLog) => `${activityLog.timestamp} - ${activityLog.action}`,
  sortComparer: (logA, logB) =>
    moment(logA.timestamp).isBefore(moment(logB.timestamp)) ? 1 : -1,
});

export const initialState: IActivityLogState = adapter.getInitialState({
  requestId: uuidv4(),
  lastPushed: utcNow(),
  viewStatus: ViewStatus.Initial,
});

export const reducer = createReducer(
  initialState,
  on(log, (state, { activityLog }) =>
    adapter.addOne({ ...activityLog }, state)
  ),
  on(submitActivityLogSuccess, (state) => ({
    ...state,
    lastPushed: utcNow(),
    requestId: uuidv4(),
    viewStatus: ViewStatus.Success,
  })),
  on(submitActivityLogFailure, (state, { shouldUpdateRequestId }) => ({
    ...state,
    ...(shouldUpdateRequestId ? { requestId: uuidv4() } : {}),
    viewStatus: ViewStatus.Failure,
  })),
  on(submitActivityLog, (state) => ({
    ...state,
    viewStatus: ViewStatus.Loading,
  })),
  on(removeActivityLogs, (state, { ids }) => adapter.removeMany(ids, state)),
  on(clearSessionData, switchUser, () => initialState)
);

const { selectAll } = adapter.getSelectors();

const selectActivityLogState = createFeatureSelector<IActivityLogState>(
  activityLogFeatureKey
);

export const selectActivityLogs = createSelector(
  selectActivityLogState,
  selectAll
);

export const selectLastPushed = createSelector(
  selectActivityLogState,
  (activityLogs) => activityLogs.lastPushed
);

const isAfterLastPushed = (
  timestamp: string,
  activityLog: ActivityLogPostEntry
): boolean => moment(activityLog.timestamp).isAfter(moment(timestamp));

const isBeforeLastPushed = (
  timestamp: string,
  activityLog: ActivityLogPostEntry
): boolean => moment(activityLog.timestamp).isBefore(moment(timestamp));

export const selectNotSubmittedActivityLogs = createSelector(
  selectActivityLogs,
  selectLastPushed,
  (activityLogs, lastPushed) =>
    activityLogs.filter((activityLog) =>
      isAfterLastPushed(lastPushed, activityLog)
    )
);

export const selectActivityLogRequestId = createSelector(
  selectActivityLogState,
  (state) => state.requestId
);

export const selectActivityLogViewStatus = createSelector(
  selectActivityLogState,
  ({ viewStatus }) => viewStatus
);

export const selectSubmittedActivityLogs = createSelector(
  selectActivityLogs,
  selectLastPushed,
  (activityLogs, lastPushed) =>
    activityLogs.filter((activityLog) =>
      isBeforeLastPushed(lastPushed, activityLog)
    )
);
