import {
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from '@ngrx/store';
import {
  login,
  loginFailure,
  loginSuccess,
  logout,
  logoutFailure,
  logoutSuccess,
} from './auth.actions';
import { ViewStatus } from '../shared/ViewStatus';
import { complement, equals } from '@qld-recreational/ramda';

export const authFeatureKey = 'auth';

export interface IAuthState {
  error: string;
  viewStatus: ViewStatus;
  email: string;
  previouslyLoggedInUserEmail: string;
}

export const initialState: IAuthState = {
  error: undefined,
  viewStatus: ViewStatus.Initial,
  email: undefined,
  previouslyLoggedInUserEmail: undefined,
};

export const authPersistenceKeys = Object.keys(initialState).filter(
  complement(equals('viewStatus'))
);

export const reducer = createReducer(
  initialState,
  on(login, logout, (state) => ({ ...state, viewStatus: ViewStatus.Loading })),
  on(loginFailure, logoutFailure, (state, { error }) => ({
    ...state,
    viewStatus: ViewStatus.Failure,
    error,
  })),
  on(loginSuccess, (state, { email }) => ({
    ...state,
    viewStatus: ViewStatus.Success,
    email,
  })),
  on(logoutSuccess, (state) => ({
    ...initialState,
    previouslyLoggedInUserEmail: state.email,
  }))
);

export const selectAuthState = createFeatureSelector<IAuthState>(
  authFeatureKey
);

export const selectPreviouslyLoggedInUserEmail = createSelector(
  selectAuthState,
  (state) => state.previouslyLoggedInUserEmail
);

export const selectViewStatus = createSelector(
  selectAuthState,
  (state) => state.viewStatus
);

export const selectEmail = createSelector(
  selectAuthState,
  (state) => state.email
);
