/**
 * Queensland Fisheries FishNet API
 * Queensland Fisheries FishNet API
 *
 * OpenAPI spec version: 1.0.2
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
export enum VmsDeviceStatus {
  Working = 'WORKING',
  Error = 'NOT_WORKING',
  Tentative = 'TENTATIVE',
  Unavailable = 'Unavailable',
}

export interface VmsDevice {
  brand?: string;
  serial?: string;
  clientId?: number;
  primaryCommercialFishingLicence?: string;
  tender?: string;
  status?: VmsDeviceStatus;
  boatMark?: string;
}
