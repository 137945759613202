import {
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from '@ngrx/store';
import { clone, equals, isNilOrEmpty, without } from '@qld-recreational/ramda';
import { QuotaRegion } from '../api/model';
import { switchUser } from '../auth/auth.actions';
import { clearSessionData } from '../settings/settings.actions';
import {
  addFavouriteRegionForLogbook,
  addFavouriteSpeciesForRegion,
  deleteFavouriteRegionForLogbook,
  deleteFavouriteSpeciesForRegion,
  loadRegionsSpeciesPreferences,
} from './preferences-regions-species.actions';

export const preferenceRegionsSpeciesFeatureKey = 'preferenceRegionsAndSpecies';

interface IPreferenceRegionsState {
  [regions: string]: number[];
}

export interface IPreferenceRegionsSpeciesForLogbookState {
  quotaSpecies: IPreferenceRegionsState;
  nonquotaSpecies: number[];
}

export interface IPreferenceRegionsSpeciesState {
  [logbook: string]: IPreferenceRegionsSpeciesForLogbookState;
}

const initialLogbookState: IPreferenceRegionsSpeciesForLogbookState = {
  quotaSpecies: {},
  nonquotaSpecies: [],
};

export const initialState: IPreferenceRegionsSpeciesState = {};

export const reducer = createReducer(
  initialState,
  on(loadRegionsSpeciesPreferences, (state, { preferences }) => ({
    ...preferences,
  })),
  on(addFavouriteRegionForLogbook, (state, { logbook, regions }) => {
    const newRegions: IPreferenceRegionsState = {};
    regions.forEach((region) => {
      newRegions[region] = state[logbook]?.quotaSpecies[region] ?? [];
    });
    return {
      ...state,
      [logbook]: {
        ...state[logbook],
        quotaSpecies: {
          ...state[logbook]?.quotaSpecies,
          ...newRegions,
        },
      },
    };
  }),
  on(addFavouriteSpeciesForRegion, (state, { logbook, region, ids }) => {
    const logbookState = clone(state?.[logbook] ?? initialLogbookState);

    if (equals(region, QuotaRegion.NonQuota) || isNilOrEmpty(region)) {
      logbookState.nonquotaSpecies = [
        ...(logbookState?.nonquotaSpecies ?? []),
        ...ids,
      ];
    } else {
      logbookState.quotaSpecies = {
        ...logbookState?.quotaSpecies,
        [region]: [...(logbookState?.quotaSpecies?.[region] ?? []), ...ids],
      };
    }

    return {
      ...state,
      [logbook]: logbookState,
    };
  }),
  on(deleteFavouriteSpeciesForRegion, (state, { logbook, region, id }) => {
    const logbookState = clone(state?.[logbook] ?? initialLogbookState);

    if (equals(region, QuotaRegion.NonQuota) || isNilOrEmpty(region)) {
      logbookState.nonquotaSpecies = without(
        [id],
        logbookState?.nonquotaSpecies ?? []
      );
    } else {
      logbookState.quotaSpecies = {
        ...logbookState?.quotaSpecies,
        [region]: without([id], logbookState?.quotaSpecies?.[region] ?? []),
      };
    }
    return {
      ...state,
      [logbook]: logbookState,
    };
  }),
  on(deleteFavouriteRegionForLogbook, (state, { logbook, region }) => {
    const quotaSpecies = Object.entries(state[logbook]?.quotaSpecies ?? {})
      .filter(([key]) => !equals(key, region))
      .reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {} as IPreferenceRegionsState);

    return {
      ...state,
      [logbook]: {
        ...state[logbook],
        quotaSpecies,
      },
    };
  }),
  on(clearSessionData, switchUser, () => initialState)
);

export const selectRegionsSpeciesPreferencesState =
  createFeatureSelector<IPreferenceRegionsSpeciesState>(
    preferenceRegionsSpeciesFeatureKey
  );

export const selectFavouriteRegionsByLogbook = (logbook: string) =>
  createSelector(selectRegionsSpeciesPreferencesState, (state) =>
    Object.keys(state[logbook]?.quotaSpecies ?? {})
  );

export const selectFavouriteSpeciesForRegion = (
  logbook: string,
  region: string
) =>
  createSelector(selectRegionsSpeciesPreferencesState, (state) => {
    const logbookState = state[logbook];
    if (equals(region, QuotaRegion.NonQuota) || isNilOrEmpty(region)) {
      return logbookState?.nonquotaSpecies ?? [];
    } else {
      return logbookState?.quotaSpecies?.[region] ?? [];
    }
  });

export const selectFavoureRegionsAndSpeciesByLogbook = (logbook: string) =>
  createSelector(selectRegionsSpeciesPreferencesState, (state) => ({
    ...initialLogbookState,
    ...state[logbook],
  }));
