import { createAction, props } from '@ngrx/store';
import { Client, Page, UserBucketReferenceData } from '../api/model';

export const loadUserBucket = createAction(
  '[UserBucket] Load UserBuckets',
  props<{ page: Page }>()
);

export const loadUserBucketSuccessful = createAction(
  '[UserBucket] Load UserBuckets Successful',
  props<{
    page: Page;
    client: Client;
    referenceData: UserBucketReferenceData;
    clientVersion: string;
  }>()
);

export const loadUserBucketFailed = createAction(
  '[UserBucket] Load UserBuckets Failed'
);
