import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import * as fromBackgroundRequest from './background-request.reducer';
import { BackgroundRequestEffects } from './background-request.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    EffectsModule.forFeature([BackgroundRequestEffects]),
    StoreModule.forFeature(
      fromBackgroundRequest.backgroundRequestsFeatureKey,
      fromBackgroundRequest.reducer
    ),
  ],
})
export class BackgroundRequestModule {}
