import { createAction, props } from '@ngrx/store';
import { IWeightCatch, IWeightNotice } from './weight-notice.reducer';

export const initWeightCatches = createAction(
  '[WeightNotice] Init Weight Catches',
  props<{ weightCatches: IWeightCatch[] }>()
);

export const addWeightNotice = createAction(
  '[WeightNotice] Add Weight Notice',
  props<{ weightNotice: Omit<IWeightNotice, 'weightCatches' | 'localId'> }>()
);

export const updateWeightNotice = createAction(
  '[WeightNotice] Update Weight Notice',
  props<{ id: string; weightCatches: IWeightCatch[] }>()
);

export const clearWeightNotice = createAction(
  '[WeightNotice] Clear Weight Notice'
);

export const deleteWeightNotices = createAction(
  '[WeightNotice] Delete Weight Notices',
  props<{ keys: string[] }>()
);

export const deleteWeightNotice = createAction(
  '[WeightNotice] Delete Weight Notice',
  props<{ id: string }>()
);
