import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import {
  catchDisposalRecordFeatureKey,
  reducer,
} from './catch-disposal-record.reducer';
import { CatchDisposalRecordService } from './catch-disposal-record.service';
import { EffectsModule } from '@ngrx/effects';
import { CatchDisposalRecordEffects } from './catch-disposal-record.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(catchDisposalRecordFeatureKey, reducer),
    EffectsModule.forFeature([CatchDisposalRecordEffects]),
  ],
  providers: [CatchDisposalRecordService],
})
export class CatchDisposalRecordModule {}
