import {
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { clearSessionData } from '../settings/settings.actions';
import { switchUser } from '../auth/auth.actions';
import { IActivityNoticeEntry } from '../shared/models/ActivityNoticeEntry';
import {
  addWeightNotice,
  clearWeightNotice,
  deleteWeightNotice,
  deleteWeightNotices,
  initWeightCatches,
  updateWeightNotice,
} from './weight-notice.actions';
import * as moment from 'moment';
import { equals, prop } from '@qld-recreational/ramda';
import {
  endTripSuccessful,
  lockWeightNotice,
  submitWeightNoticeSuccessful,
} from '../trip/trip.actions';
import { utcNow } from '@qld-recreational/moment';
import { selectCatchDisposalRecords } from '../catch-disposal-record/catch-disposal-record.reducer';
import { selectCatchDisposalLogbookEnabled } from '../trip/trip.selectors';

export const weightNoticeFeatureKey = 'weightNotice';

export type IWeightCatch = IActivityNoticeEntry;

export type IWeightNotice = {
  id: string;
  localId: number;
  createdDate: string;
  completed: boolean;
  submittedDate: string;
  receipt: number;
  weightCatches: IWeightCatch[];
};

export interface IWeightNoticeState extends EntityState<IWeightNotice> {
  initWeightCatches: IWeightCatch[];
}

export const adapter: EntityAdapter<IWeightNotice> = createEntityAdapter<
  IWeightNotice
>({
  selectId: (weightNotice) => weightNotice.id,
  sortComparer: (weightNoticeA, weightNoticeB) =>
    moment(weightNoticeA.createdDate).isBefore(
      moment(weightNoticeB.createdDate)
    )
      ? 1
      : -1,
});

export const initialState: IWeightNoticeState = adapter.getInitialState({
  initWeightCatches: null,
});

export const reducer = createReducer(
  initialState,
  on(clearSessionData, switchUser, endTripSuccessful, () => initialState),
  on(initWeightCatches, (state, { weightCatches }) => ({
    ...state,
    initWeightCatches: weightCatches,
  })),
  on(addWeightNotice, (state, { weightNotice }) =>
    adapter.addOne(
      {
        ...weightNotice,
        localId: Object.keys(state.entities).length + 1,
        weightCatches: state.initWeightCatches,
      },
      state
    )
  ),
  on(updateWeightNotice, (state, { id, weightCatches }) =>
    adapter.updateOne(
      {
        id,
        changes: { weightCatches },
      },
      state
    )
  ),
  on(lockWeightNotice, (state, { id }) =>
    adapter.updateOne(
      {
        id,
        changes: { completed: true },
      },
      state
    )
  ),
  on(submitWeightNoticeSuccessful, (state, { receipt, id }) =>
    adapter.updateOne(
      {
        id,
        changes: { submittedDate: utcNow(), receipt },
      },
      state
    )
  ),
  on(clearWeightNotice, (state) => adapter.removeAll(state)),
  on(deleteWeightNotices, (state, { keys }) => adapter.removeMany(keys, state)),
  on(deleteWeightNotice, (state, { id }) => adapter.removeOne(id, state))
);

const { selectAll, selectTotal } = adapter.getSelectors();

const selectWeightNoticesState = createFeatureSelector<IWeightNoticeState>(
  weightNoticeFeatureKey
);

export const selectWeightNotices = createSelector(
  selectWeightNoticesState,
  selectAll
);

export const selectWeightNoticeById = (weightNoticeId: string) =>
  createSelector(selectWeightNotices, (weightNotices) =>
    weightNotices.find((weightNotice) =>
      equals(weightNotice.id, weightNoticeId)
    )
  );

export const selectTransactionNumberById = (weightNoticeId: string) =>
  createSelector(
    selectWeightNotices,
    (weightNotices) =>
      weightNotices.find((weightNotice) =>
        equals(weightNotice.id, weightNoticeId)
      ).receipt
  );

export const selectNumberOfWeightNotices = createSelector(
  selectWeightNoticesState,
  selectTotal
);

export const selectNumberOfIncompleteWeightNotices = createSelector(
  selectWeightNotices,
  (weightNotices) =>
    weightNotices.filter((weightNotice) => !weightNotice.completed).length
);

export const selectIncompleteWeightNoticesIds = createSelector(
  selectWeightNotices,
  (weightNotices) =>
    weightNotices
      .filter((weightNotice) => !weightNotice.completed)
      .map(prop('id'))
);

export const selectIfAnyWeightNoticeLocked = createSelector(
  selectWeightNotices,
  (weightNotices) =>
    weightNotices.some((weightNotice) => weightNotice.completed)
);

export const selectIfAnyWeightNoticeDoesNotHaveCDR = createSelector(
  selectWeightNotices,
  selectCatchDisposalLogbookEnabled,
  selectCatchDisposalRecords,
  (weightNotices, catchDisposalLogbookEnabled, cdrs) =>
    catchDisposalLogbookEnabled &&
    weightNotices.some(
      (weightNotice) =>
        !cdrs.some((cdr) => equals(cdr.weightNoticeId, weightNotice.id))
    )
);
