import { createAction, props } from '@ngrx/store';
import {
  ActivityEmergencyCreate,
  ActivityPreTripCreate,
  ActivityPriorCreate,
  ActivityWeightCreate,
  LogbookCreate,
  Page,
} from '../api/model';
import { IActivityNoticeStatus } from '../shared/models/ActivityNoticeStatus';
import { TripStep } from './trip-step';
import { ActivityRetainedCreate } from '../api/model/activityRetainedCreate';
import { NilFishingCreate } from '../api/model/nilFishingCreate';
import { DisposalMethod } from '../shared/models/CDR';

export const lockPreTrip = createAction(
  '[PreTrip] Lock PreTrip',
  props<{
    preTrip: Omit<ActivityPreTripCreate, 'requestId' | 'clientVersion'>;
    vesselStatusMessage: string;
  }>()
);

export const submitPreTripSuccessful = createAction(
  '[PreTrip] Submit PreTrip Successful',
  props<{ receipt: number }>()
);

export const createTrip = createAction(
  '[Trip] Create Trip',
  props<{ id: string }>()
);

export const submitNilFishing = createAction(
  '[Trip] Submit Nil Fishing',
  props<{ nilFishingCreate: Omit<NilFishingCreate, 'requestId'> }>()
);

export const lockLogbook = createAction('[Trip] Lock Logbook');

export const updateActivityNoticeStatus = createAction(
  '[Trip] Update Activity Notice Status',
  props<{ activityNoticeStatus: IActivityNoticeStatus }>()
);

export const lockLogbookSuccessful = createAction(
  '[Trip] Lock Logbook Success',
  props<{
    logbookCreate: Omit<LogbookCreate, 'requestId' | 'clientVersion'>;
    noQuota: boolean;
  }>()
);

export const submitLogbookFailure = createAction(
  '[Trip] Submit Logbook Failed'
);

export const lockPriorEmergencyNotice = createAction(
  '[Trip] Lock Prior Emergency Notice'
);

export const lockPriorEmergencyNoticeSuccessful = createAction(
  '[Trip] Lock Prior Emergency Notice Success',
  props<{
    priorEmergencyNoticeCreate:
      | Omit<ActivityPriorCreate, 'requestId' | 'clientVersion'>
      | Omit<ActivityEmergencyCreate, 'requestId' | 'clientVersion'>;
  }>()
);

export const lockRetainNotice = createAction('[Trip] Lock Retain Notice');

export const lockRetainNoticeSuccessful = createAction(
  '[Trip] Lock Retain Notice Success',
  props<{
    retainNoticeCreate: Omit<
      ActivityRetainedCreate,
      'requestId' | 'clientVersion'
    >;
  }>()
);

export const submitPriorEmergencyNoticeSuccessful = createAction(
  '[Trip] Submit Prior Emergency Notice Success',
  props<{ receipt: number }>()
);

export const submitPriorEmergencyNoticeFailure = createAction(
  '[Trip] Submit Prior Emergency Notice Failed'
);

export const submitRetainNoticeSuccessful = createAction(
  '[Trip] Submit Retain Notice Success',
  props<{ receipt: number }>()
);

export const submitRetainNoticeFailure = createAction(
  '[Trip] Submit Retain Notice Failed'
);

export const lockWeightNotice = createAction(
  '[WeightNotice] Lock Weight Notice',
  props<{ id: string }>()
);

export const lockWeightNoticeSuccessful = createAction(
  '[WeightNotice] Lock Weight Notice Successful',
  props<{
    weightNoticeCreate: ActivityWeightCreate;
    localId: number;
    id: string;
  }>()
);

export const submitWeightNoticeSuccessful = createAction(
  '[Trip] Submit Weight Notice Success',
  props<{ receipt: number; id: string }>()
);

export const submitCDR = createAction(
  '[Trip] Submit Catch Disposal Records',
  props<{ id: string }>()
);

export const submitCDRSuccessful = createAction(
  '[Trip] Submit Catch Disposal Records Successful',
  props<{
    id: string;
    logbookCreate: LogbookCreate & { disposalMethod: DisposalMethod };
  }>()
);

export const submitCDRFailure = createAction(
  '[Trip] Submit Catch Disposal Records Failure',
  props<{ id: string; shouldUpdateRequestId?: boolean }>()
);

export const submitTrip = createAction(
  '[Trip] Submit Trips',
  props<{ id: string }>()
);

export const tripStepChange = createAction(
  '[Trip] Trip Step is Changed',
  props<{ tripStep: TripStep }>()
);

export const changeTripStep = createAction(
  '[Trip] Change Trip Step',
  props<{ tripStep: TripStep }>()
);

export const updateCatchDisposalLogbookEnabled = createAction(
  '[Trip] Update CDR Logbook Enabled',
  props<{ catchDisposalLogbookEnabled: boolean }>()
);

export const updateBoatMark = createAction(
  '[Trip] Update Boat Mark',
  props<{ boatMark: string }>()
);

export const disableWeightNotice = createAction('[Trip] Disable Weight Notice');

export const endTrip = createAction(
  '[Trip] End Trip',
  props<{ page: Page; pollingStartedWhenAttempt: boolean }>()
);

export const endTripSuccessful = createAction(
  '[Trip] End Trip Successful',
  props<{
    page: Page;
    tripCorrelationID: string;
    pollingStartedWhenAttempt: boolean;
  }>()
);

export const endTripFailure = createAction(
  '[Trip] End Trip Failure',
  props<{
    page: Page;
    shouldUpdateRequestId: boolean;
  }>()
);

export const resetEndTripViewStatus = createAction(
  '[Trip] Reset End Trip View Status'
);

export const resetTrip = createAction('[Trip] Reset Trip');
