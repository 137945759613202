import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromLogbookEvent from './logbook-events.reducer';
import { EffectsModule } from '@ngrx/effects';
import { LogbookEventsEffect } from './logbook-events.effect';
import { LogbookEventsService } from './logbook-events.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromLogbookEvent.logbookEventsFeatureKey,
      fromLogbookEvent.reducer
    ),
    EffectsModule.forFeature([LogbookEventsEffect]),
  ],
  providers: [LogbookEventsService],
})
export class LogbookEventsModule {}
