import {
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from '@ngrx/store';
import { VmsStatus } from '../api/model';
import { switchUser } from '../auth/auth.actions';
import { clearSessionData } from '../settings/settings.actions';
import { loadVMS, loadVMSFailed, loadVMSSuccessful } from './vms.actions';
import { ViewStatus } from '../shared/ViewStatus';
import { utcNow } from '@qld-recreational/moment';

export const vmsFeatureKey = 'vms';

export interface IVMSState {
  vmsStatus: VmsStatus;
  viewStatus: ViewStatus;
  lastUpdated?: string;
}

export const initialState: IVMSState = {
  vmsStatus: undefined,
  viewStatus: ViewStatus.Initial,
  lastUpdated: undefined,
};

export const reducer = createReducer(
  initialState,
  on(loadVMS, (state) => ({ ...state, viewStatus: ViewStatus.Loading })),
  on(loadVMSSuccessful, (state, { vmsStatus }) => ({
    vmsStatus,
    viewStatus: ViewStatus.Success,
    lastUpdated: utcNow(),
  })),
  on(loadVMSFailed, (state) => ({ ...state, viewStatus: ViewStatus.Failure })),
  on(clearSessionData, switchUser, () => initialState)
);

export const selectVMSState = createFeatureSelector<IVMSState>(vmsFeatureKey);

export const selectVMSStatus = createSelector(
  selectVMSState,
  (state) => state.vmsStatus
);

export const selectVMSViewStatus = createSelector(
  selectVMSState,
  (state) => state.viewStatus
);

export const selectVMSLastUpdated = createSelector(
  selectVMSState,
  (state) => state.lastUpdated
);
