import { Component, OnDestroy } from '@angular/core';
import { NavController, Platform } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { debounceTime, filter, switchMap, take, tap } from 'rxjs/operators';
import { complement, equals, isNil } from '@qld-recreational/ramda';
import { runIfNative } from './shared/runIfNative';
import { IUserBucketState } from './user-bucket/user-bucket.reducer';
import { IAuthState, selectEmail } from './auth/auth.reducer';
import { AuthService } from './auth/auth.service';
import { AppFlowService } from './shared/app-flow.service';
import { ISettingState } from './settings/settings.reducer';
import { selectTargetEnv } from './settings/settings.selectors';
import { v4 as uuidv4 } from 'uuid';
import {
  IManualPollingState,
  selectPollingData,
  selectPollingStarted,
} from './manual-polling/manual-polling.reducer';
import { AlertService } from '@qld-recreational/alert';
import {
  appTrackLocation,
  startPolling,
  stopPolling,
} from './manual-polling/manual-polling.actions';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { SplashScreen } from '@capacitor/splash-screen';
import { Keyboard } from '@capacitor/keyboard';
import { IAppState } from './app-state/app-state.reducer';
import { updateSessionId } from './app-state/app-state.actions';
import { IContentfulState } from './contentful/contentful.reducer';
import { init } from './contentful/contentful.actions';
import { register } from 'swiper/element/bundle';
import * as Sentry from '@sentry/browser';
import { ITripState } from './trip/trip.reducer';
import { selectBoatMark, selectPreTrip } from './trip/trip.selectors';

register();

@Component({
  selector: 'qld-recreational-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnDestroy {
  private loadContentful$$: Subscription;
  public hydrated$: Observable<boolean>;
  private tripSentryTags$$: Subscription;

  constructor(
    private platform: Platform,
    private navController: NavController,
    private authStore: Store<IAuthState>,
    private settingStore: Store<ISettingState>,
    private contentfulStore: Store<IContentfulState>,
    private appStateStore: Store<IAppState>,
    private authService: AuthService,
    private appFlowService: AppFlowService,
    private userBucketStore: Store<IUserBucketState>,
    private manualPollingStore: Store<IManualPollingState>,
    private alertService: AlertService,
    private tripStore: Store<ITripState>
  ) {
    this.initializeApp();
    this.addTagsToSentry();
  }

  public ngOnDestroy() {
    this.loadContentful$$.unsubscribe();
    this.tripSentryTags$$?.unsubscribe();
  }

  public async initializeApp() {
    this.hydrated$ = this.appStateStore.select(
      (state) => (state as any).hydrated
    );

    this.hydrated$.pipe(filter(equals(true))).subscribe(async () => {
      this.appStateStore.dispatch(updateSessionId({ sessionId: uuidv4() }));
      this.manualPollingStore.dispatch(appTrackLocation());
      await this.platform.ready();
      await SplashScreen.hide();

      runIfNative(
        () => Keyboard.setAccessoryBarVisible({ isVisible: true }),
        'ios'
      );
      this.subscribeToAppResume();
      // this.checkAppFlowLiveDeploy();
      this.loadContentful();
      this.checkManualPolling();
    });
  }

  private loadContentful() {
    this.loadContentful$$ = this.authStore
      .select(selectEmail)
      .pipe(
        filter((email) => !isNil(email)),
        tap(() => this.contentfulStore.dispatch(init()))
      )
      .subscribe();
  }

  private checkAppFlowLiveDeploy() {
    this.settingStore
      .select(selectTargetEnv)
      .pipe(filter(complement(isNil)), take(1))
      .subscribe((targetEnv) => {
        this.appFlowService.checkLiveUpdate(targetEnv);
      });
  }

  private addTagsToSentry() {
    this.tripSentryTags$$ = combineLatest([
      this.tripStore.select(selectPreTrip),
      this.tripStore.select(selectBoatMark),
    ])
      .pipe(debounceTime(1000))
      .subscribe(([info, boatMark]) => {
        Sentry.setTags({
          'trip.pcfl': info?.primaryCommercialFishingLicence,
          'trip.primaryFisherySymbol': info?.primaryFisherySymbol,
          'trip.secondaryFisherySymbol': info?.secondaryFisherySymbol,
          'trip.tripId': info?.tripCorrelationID,
          'trip.boatMark': boatMark,
        });
      });
  }

  private checkManualPolling() {
    this.manualPollingStore
      .select(selectPollingStarted)
      .pipe(
        take(1),
        filter((pollingStarted) => pollingStarted),
        switchMap(() =>
          this.manualPollingStore.select(selectPollingData).pipe(take(1))
        )
      )
      .subscribe((payload) =>
        this.alertService.presentDoubleActionAlert(
          'Vessel tracking',
          'You have commenced your vessel tracking before you quit the app, do you wish to continue?',
          () => this.manualPollingStore.dispatch(startPolling(payload)),
          'Yes',
          () => this.manualPollingStore.dispatch(stopPolling())
        )
      );
  }

  private subscribeToAppResume() {
    this.platform.resume.subscribe(() =>
      this.appStateStore.dispatch(updateSessionId({ sessionId: uuidv4() }))
    );
  }
}
