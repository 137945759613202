import {
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ILocation, LocationStatus } from './manual-polling';
import {
  locationReceived,
  startManualPollingSuccessful,
  stopPollingFinished,
  updateSubmitStatus,
} from './manual-polling.actions';
import { clearSessionData } from '../settings/settings.actions';
import { switchUser } from '../auth/auth.actions';
import { endTripSuccessful } from '../trip/trip.actions';
import { utcNow } from '@qld-recreational/moment';

export const manualPollingFeatureKey = 'manualPolling';

export interface IManualPollingState extends EntityState<ILocation> {
  pollingStarted: boolean;
  startDateTime: string;
  data?: {
    licence: string;
    boatMark: string;
  };
}

export const adapter: EntityAdapter<ILocation> = createEntityAdapter<ILocation>(
  {
    selectId: (location) => location.effectiveDateTime,
  }
);

export const initialState: IManualPollingState = adapter.getInitialState({
  startDateTime: undefined,
  pollingStarted: false,
  data: null,
});

export const reducer = createReducer(
  initialState,
  on(locationReceived, (state, { location }) =>
    adapter.addOne(location, state)
  ),
  on(startManualPollingSuccessful, (state, { licence, boatMark }) => ({
    ...state,
    pollingStarted: true,
    startDateTime: utcNow(),
    data: {
      licence,
      boatMark,
    },
  })),
  on(stopPollingFinished, (state) => ({
    ...state,
    pollingStarted: false,
    data: null,
  })),
  on(updateSubmitStatus, (state, { effectiveDateTimes }) =>
    adapter.updateMany(
      effectiveDateTimes.map((effectiveDateTime) => ({
        id: effectiveDateTime,
        changes: { status: LocationStatus.Submitted },
      })),
      state
    )
  ),
  on(clearSessionData, switchUser, endTripSuccessful, () => initialState)
);

const { selectAll } = adapter.getSelectors();

const selectManualPollingState = createFeatureSelector<IManualPollingState>(
  manualPollingFeatureKey
);

export const selectLocations = createSelector(
  selectManualPollingState,
  selectAll
);

export const selectPollingStarted = createSelector(
  selectManualPollingState,
  (state) => state.pollingStarted
);

export const selectStartDateTime = createSelector(
  selectManualPollingState,
  (state) => state.startDateTime
);

export const selectPollingData = createSelector(
  selectManualPollingState,
  (state) => state.data
);
