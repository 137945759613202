import { GpsLocation } from '../api/model';

export interface ILocation extends GpsLocation {
  status: LocationStatus;
}

export enum LocationStatus {
  Pending = 'Pending',
  Submitted = 'Submitted',
}

export enum LocationEventType {
  locationReceived,
  gpsEnabled,
  gpsDisabled,
}

export interface ILocationEvent {
  type: LocationEventType;
  data?: Location;
}

export enum GeolocationAuthorizationStatus {
  AUTHORIZATION_STATUS_NOT_DETERMINED,
  AUTHORIZATION_STATUS_RESTRICTED,
  AUTHORIZATION_STATUS_DENIED,
  AUTHORIZATION_STATUS_ALWAYS,
  AUTHORIZATION_STATUS_WHEN_IN_USE,
}
