import { createAction, props } from '@ngrx/store';
import { IPreferencesGPSState } from './preference-gps.reducer';

export const loadGPSPreferences = createAction(
  '[GPS Preferences] Load GPS Preferences',
  props<{ preferences: IPreferencesGPSState }>()
);

export const UpdateGPSPreferences = createAction(
  '[GPS Preferences] Update GPS Preferences',
  props<{ preferences: IPreferencesGPSState }>()
);
