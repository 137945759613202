import {
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { addRetainCatches, updateRetainCatches } from './retain-notice.actions';
import { clearSessionData } from '../settings/settings.actions';
import { switchUser } from '../auth/auth.actions';
import { IActivityNoticeEntry } from '../shared/models/ActivityNoticeEntry';
import {
  endTripSuccessful,
  submitRetainNoticeSuccessful,
} from '../trip/trip.actions';

export const retainNoticeFeatureKey = 'retainNotice';

export type IRetainCatch = IActivityNoticeEntry;

export interface IRetainNoticeState extends EntityState<IRetainCatch> {
  receipt: number;
}

export const adapter: EntityAdapter<IRetainCatch> = createEntityAdapter<
  IRetainCatch
>({
  selectId: (priorNotice) => priorNotice.id,
});

export const initialState: IRetainNoticeState = adapter.getInitialState({
  receipt: null,
});

export const reducer = createReducer(
  initialState,
  on(addRetainCatches, (state, { retainCatches }) =>
    adapter.addMany(retainCatches, state)
  ),
  on(updateRetainCatches, (state, { retainCatches }) =>
    adapter.updateMany(
      retainCatches.map((retainCatch) => ({
        id: retainCatch.id,
        changes: { quantity: retainCatch.quantity },
      })),
      state
    )
  ),
  on(submitRetainNoticeSuccessful, (state, { receipt }) => ({
    ...state,
    receipt,
  })),
  on(clearSessionData, switchUser, endTripSuccessful, () => initialState)
);

const { selectAll } = adapter.getSelectors();

const selectRetainNoticesState = createFeatureSelector<IRetainNoticeState>(
  retainNoticeFeatureKey
);

export const selectRetainCatches = createSelector(
  selectRetainNoticesState,
  selectAll
);

export const selectTransactionNumber = createSelector(
  selectRetainNoticesState,
  (state) => state.receipt
);
