import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { ISettingState } from '../settings/settings.reducer';
import { selectApiUrl, selectApiUrlSV } from '../settings/settings.selectors';
import { logout } from './auth.actions';
import { IAuthState } from './auth.reducer';
import * as Sentry from '@sentry/browser';
import { Preferences } from '@capacitor/preferences';
import { ToastService } from '@qld-recreational/toast';
import {
  ACCESS_TOKEN_KEY,
  AUTH_RESPONSE_KEY,
  ID_TOKEN_KEY,
  REFRESH_TOKEN_KEY,
} from './auth';

@Injectable()
export class ExpiredTokenInterceptor implements HttpInterceptor {
  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.settingStore.select(selectApiUrl).pipe(
      withLatestFrom(this.settingStore.select(selectApiUrlSV)),
      map(
        ([apiUrl, apiUrlSV]) =>
          request.url.includes(apiUrl) || request.url.includes(apiUrlSV)
      ),
      switchMap((isApiUrl) =>
        next.handle(request).pipe(
          tap({
            next: undefined,
            error: async (error: any) => {
              // If you put a wrong password, Login api sends 401 back and logs the user out.
              if (isApiUrl && !request.url.includes('login')) {
                await this.checkForExpiredToken(request, error);
              }
            },
          })
        )
      )
    );
  }

  constructor(
    private authStore: Store<IAuthState>,
    private settingStore: Store<ISettingState>,
    private toastService: ToastService
  ) {}

  private sendTokenExpireSentryError = async (
    request: HttpRequest<any>,
    error: HttpErrorResponse
  ) => {
    const accessToken = (await Preferences.get({ key: ACCESS_TOKEN_KEY }))
      .value;
    const authResponse = JSON.parse(
      (await Preferences.get({ key: AUTH_RESPONSE_KEY })).value
    );
    const idToken = (await Preferences.get({ key: ID_TOKEN_KEY })).value;
    const refreshToken = (await Preferences.get({ key: REFRESH_TOKEN_KEY }))
      .value;
    Sentry.withScope((scope) => {
      scope.setExtras({
        url: request.url,
        error,
        accessToken,
        authResponse,
        idToken,
        refreshToken,
      });
      Sentry.captureMessage('Interceptor captured error');
    });
  };

  private checkForExpiredToken = async (
    request: HttpRequest<any>,
    err: HttpErrorResponse
  ) => {
    if (err instanceof HttpErrorResponse) {
      if (err.status === 401) {
        await this.sendTokenExpireSentryError(request, err);
        this.toastService.presentWarningToast(
          'token expired, please login again'
        );
        this.authStore.dispatch(logout());
      }
    }
  };
}
