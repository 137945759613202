import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ITripState } from '../trip/trip.reducer';
import { selectCDRInfo } from '../trip/trip.selectors';
import {
  defaultIfEmpty,
  map,
  mergeMap,
  take,
  withLatestFrom,
} from 'rxjs/operators';
import {
  IPriorEmergencyNoticeState,
  selectTransactionNumber,
} from '../prior-emergency-notice/prior-emergency-notice.reducer';
import {
  IWeightNoticeState,
  selectTransactionNumberById,
} from '../weight-notice/weight-notice.reducer';
import { Observable, forkJoin } from 'rxjs';
import {
  ICatchDisposalRecord,
  ICDRInitSpecies,
} from './catch-disposal-record.reducer';
import {
  ILogbookCatchesState,
  selectCatches,
} from '../logbook-catches/logbook-catches.reducer';
import { isNilOrEmpty, propEq } from '@qld-recreational/ramda';
import { ViewStatus } from '../shared/ViewStatus';
import { v4 as uuidv4 } from 'uuid';
import {
  IUserBucketState,
  selectMySeasons,
} from '../user-bucket/user-bucket.reducer';
import { LogbookService } from '../shared/logbook.service';

@Injectable()
export class CatchDisposalRecordService {
  constructor(
    private tripStore: Store<ITripState>,
    private priorEmergencyStore: Store<IPriorEmergencyNoticeState>,
    private userBucketStore: Store<IUserBucketState>,
    private logbookCatchesStore: Store<ILogbookCatchesState>,
    private weightStore: Store<IWeightNoticeState>,
    private logbookService: LogbookService
  ) {}

  public getInitSpecies(): Observable<ICDRInitSpecies[]> {
    return this.logbookCatchesStore.select(selectCatches).pipe(
      withLatestFrom(this.userBucketStore.select(selectMySeasons)),
      take(1),
      map(([logbookCatches, seasons]) =>
        logbookCatches.filter(
          ({ quotaSymbol, discarded, quantity }) =>
            !isNilOrEmpty(quotaSymbol) &&
            !discarded &&
            !isNilOrEmpty(quantity) &&
            seasons.find(propEq('quotaSymbol', quotaSymbol))
              ?.catchDisposalLogbookEnabled
        )
      ),
      mergeMap((logbookCatches) =>
        forkJoin(
          logbookCatches.map((logbookCatch) =>
            this.logbookService
              .selectFisherySymbolByFishingEventId(logbookCatch.logbookEventId)
              .pipe(
                take(1),
                map(
                  (fisherySymbol): ICDRInitSpecies => ({
                    id: logbookCatch.speciesId,
                    speciesCDRId: logbookCatch.speciesCDRId,
                    speciesDescription: logbookCatch.speciesDescription,
                    fisherySymbol,
                  })
                )
              )
          )
        ).pipe(defaultIfEmpty([] as ICDRInitSpecies[]))
      ),
      map((species) =>
        Array.from(new Set(species.map((s) => JSON.stringify(s)))).map((s) =>
          JSON.parse(s)
        )
      )
    );
  }

  public getInitCDR(
    id: string,
    weightNoticeId: string
  ): Observable<Omit<ICatchDisposalRecord, 'cdrNumber' | 'species'>> {
    return this.tripStore.select(selectCDRInfo).pipe(
      take(1),
      withLatestFrom(
        this.priorEmergencyStore.select(selectTransactionNumber),
        this.weightStore.select(selectTransactionNumberById(weightNoticeId))
      ),
      map(
        ([
          { boatMark, primaryCommercialFishingLicence },
          PriorNoticeReceipt,
          WeightNoticeReceipt,
        ]) => ({
          id,
          weightNoticeId,
          completed: false,
          submitted: false,
          dateSubmitted: undefined,
          boatMark,
          primaryCommercialFishingLicence,
          PriorNoticeReceipt,
          WeightNoticeReceipt,
          CollectionAddress: undefined,
          DeliveryAddress: undefined,
          ConsignmentEmail: undefined,
          ConsigneeName: undefined,
          DisposalEmail: undefined,
          DisposalMethod: undefined,
          WholesaleNameOfBuyer: undefined,
          WholesaleDateOfSale: undefined,
          WholesaleBuyerLicence: undefined,
          WholesaleBuyerAddress: undefined,
          StoredNameOfStorer: undefined,
          StoredStorageAddress: undefined,
          viewStatus: ViewStatus.Initial,
          requestId: uuidv4(),
          touched: false,
        })
      )
    );
  }
}
