import { createReducer, on } from '@ngrx/store';
import { EnvMap } from './settings';
import {
  changeEnv,
  loadEnvs,
  switchContentfulContext,
} from './settings.actions';
import { equals } from '@qld-recreational/ramda';
import { environment } from '../../environments/environment';

export const settingsFeatureKey = 'settings';

export interface ISettingState {
  targetEnv: string;
  contentfulContext: string;
  isDebugMode: boolean;
  environments: EnvMap;
}

export const initialState: ISettingState = {
  targetEnv: environment.targetEnv,
  contentfulContext: environment.contentfulContext,
  isDebugMode: false,
  environments: {},
};

export const reducer = createReducer(
  initialState,
  on(loadEnvs, (state, { environments }) => {
    const shouldUpdateTargetEnv =
      !equals(
        JSON.stringify(state.environments),
        JSON.stringify(environments)
      ) ||
      !Object.keys(environments).includes(state.targetEnv) ||
      environment.production;

    return {
      ...state,
      environments,
      contentfulContext: environment.contentfulContext,
      targetEnv: shouldUpdateTargetEnv
        ? environment.targetEnv
        : state.targetEnv,
    };
  }),
  on(switchContentfulContext, (state, { contentfulContext }) => ({
    ...state,
    contentfulContext,
    nextSyncToken: undefined,
  })),
  on(changeEnv, (state, { env }) => ({ ...state, targetEnv: env }))
);
