import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  changeTripStep,
  disableWeightNotice,
  lockPriorEmergencyNoticeSuccessful,
  submitWeightNoticeSuccessful,
} from '../trip/trip.actions';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { WeightNoticeService } from './weight-notice.service';
import {
  deleteWeightNotices,
  initWeightCatches,
} from './weight-notice.actions';
import { updateBackgroundRequest } from '../background-request/background-request.actions';
import { equals, isEmpty } from '@qld-recreational/ramda';
import { BackgroundRequestPath } from '../background-request/background-request.service';
import { TripStep } from '../trip/trip-step';
import { Store } from '@ngrx/store';
import {
  IWeightNoticeState,
  selectIncompleteWeightNoticesIds,
  selectNumberOfWeightNotices,
} from './weight-notice.reducer';

@Injectable()
export class WeightNoticeEffects {
  public initWeightCatches$ = createEffect(() =>
    this.actions$.pipe(
      ofType(lockPriorEmergencyNoticeSuccessful),
      switchMap(() =>
        this.weightNoticeService.constructInitWeightNoticeFromPriorEmergency()
      ),
      map((weightCatches) => initWeightCatches({ weightCatches }))
    )
  );

  public backgroundRequestSubmitted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateBackgroundRequest),
      filter(({ backgroundRequest: { changes: { path } } }) =>
        equals(path, BackgroundRequestPath.WeightNotice)
      ),
      map(
        ({
          backgroundRequest: {
            changes: { transactionNumber, payloadId },
          },
        }) =>
          submitWeightNoticeSuccessful({
            receipt: Number(transactionNumber),
            id: payloadId,
          })
      )
    )
  );

  public deleteIncompleteWeightNotices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(changeTripStep),
      filter(({ tripStep }) => equals(tripStep, TripStep.RetainNotice)),
      switchMap(() =>
        this.weightNoticeStore
          .select(selectIncompleteWeightNoticesIds)
          .pipe(take(1))
      ),
      filter(
        (incompleteWeightNoticeIds) => !isEmpty(incompleteWeightNoticeIds)
      ),
      map((keys) => deleteWeightNotices({ keys }))
    )
  );

  public disableWeightNotice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(changeTripStep),
      filter(({ tripStep }) => equals(tripStep, TripStep.RetainNotice)),
      switchMap(() =>
        this.weightNoticeStore.select(selectNumberOfWeightNotices).pipe(take(1))
      ),
      filter((numberOfWeightNotice) => equals(numberOfWeightNotice, 0)),
      map(() => disableWeightNotice())
    )
  );

  constructor(
    private actions$: Actions,
    private weightNoticeService: WeightNoticeService,
    private weightNoticeStore: Store<IWeightNoticeState>
  ) {}
}
