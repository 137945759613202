import { ActivityStatus, Page } from '../api/model';

const activityLog = {
  page: Page.RetainNotice,
  action: 'Submit retain notice',
};

export const submitRetainNoticeActivityLog = ({
  tripCorrelationID,
  createdDateTime,
}: {
  tripCorrelationID: string;
  createdDateTime: string;
}) => ({
  tripCorrelationID,
  message: 'User submitted a retained fish notice.',
  status: ActivityStatus.pending,
  timestamp: createdDateTime,
  ...activityLog,
});

export const submitRetainNoticeSuccessActivityLog = (
  tripCorrelationID: string
) => ({
  tripCorrelationID,
  status: ActivityStatus.success,
  message: 'Retained fish notice was successfully submitted.',
  ...activityLog,
});
