/**
 * Queensland Fisheries FishNet API
 * Queensland Fisheries FishNet API
 *
 * OpenAPI spec version: 1.0.2
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { FishFormConversion } from './fishFormConversion';

export enum QuotaRegion {
  NonQuota = 'Non Quota',
}

export enum QuotaType {
  ITQ = 'ITQ',
  INTQ = 'INTQ',
  Competitive = 'Competitive',
}

export interface UserBucketReferenceDataSeasons {
  quotaSymbol?: string;
  quotaRegion?: QuotaRegion;
  quotaDescription?: string;
  quotaType?: QuotaType;
  open?: string;
  close?: string;
  totalAllowableCatch?: number;
  totalUsage?: number;
  priorActivityNoticeEnabled?: boolean;
  emergencyActivityNoticeEnabled?: boolean;
  weightActivityNoticeEnabled?: boolean;
  weightActivityNoticeContentfulPageID?: string;
  retainActivityNoticeEnabled?: boolean;
  catchDisposalLogbookEnabled?: boolean;
  prior?: Array<FishFormConversion>;
  weight?: Array<FishFormConversion>;
}
