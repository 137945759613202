import { ActivityStatus, Page } from '../api/model';

const activityLog = {
  page: Page.WeightNotice,
  action: 'Submit weight notice',
};

export const submitWeightNoticeActivityLog = ({
  tripCorrelationID,
  createdDateTime,
  localId,
}: {
  tripCorrelationID: string;
  createdDateTime: string;
  localId: number;
}) => ({
  tripCorrelationID,
  message: `User submitted weight notice <em>${localId}</em>.`,
  status: ActivityStatus.pending,
  timestamp: createdDateTime,
  ...activityLog,
});

export const submitWeightNoticeSuccessActivityLog = (
  tripCorrelationID: string,
  localId: number
) => ({
  tripCorrelationID,
  status: ActivityStatus.success,
  message: `Weight notice <em>${localId}</em> was successfully submitted.`,
  ...activityLog,
});
