import { createAction, props } from '@ngrx/store';
import { IPreferenceRegionsSpeciesState } from './preferences-regions-species.reducer';

export const loadRegionsSpeciesPreferences = createAction(
  '[PreferenceRegionsSpecies] Load Regions Species Preferences',
  props<{ preferences: IPreferenceRegionsSpeciesState }>()
);

export const addFavouriteRegionForLogbook = createAction(
  '[PreferenceRegionsSpecies] Add Favourite Region for Logbook',
  props<{ logbook: string; regions: string[] }>()
);

export const deleteFavouriteRegionForLogbook = createAction(
  '[PreferenceRegionsSpecies] Delete Favourite Region for Logbook',
  props<{ logbook: string; region: string }>()
);

export const addFavouriteSpeciesForRegion = createAction(
  '[PreferenceRegionsSpecies] Add Favourite Species for Region for Logbook',
  props<{ logbook: string; region: string; ids: number[] }>()
);

export const deleteFavouriteSpeciesForRegion = createAction(
  '[PreferenceRegionsSpecies] Delete Favourite Species for Region for Logbook',
  props<{ logbook: string; region: string; id: number }>()
);
