import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VersionMismatchPageComponent } from './version-mismatch-page.component';
import { VersionMismatchPageRoutingModule } from './version-mismatch-page-routing.module';
import { IonicModule } from '@ionic/angular';
import { VersionMismatchService } from './version-mismatch.service';
import { VersionMismatchGuard } from './version-mismatch.guard';

@NgModule({
  declarations: [VersionMismatchPageComponent],
  imports: [CommonModule, VersionMismatchPageRoutingModule, IonicModule],
  providers: [VersionMismatchService, VersionMismatchGuard],
})
export class VersionMismatchModule {}
