import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { IAuthState, selectEmail } from './auth/auth.reducer';
import { isNilOrEmpty } from '@qld-recreational/ramda';
import {
  IUserBucketState,
  selectClient,
} from './user-bucket/user-bucket.reducer';
import { equals } from 'rambda';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private authStore: Store<IAuthState>,
    private userBucketStore: Store<IUserBucketState>,
    private router: Router
  ) {}

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<UrlTree | boolean> {
    return this.authStore
      .select((state: any) => state.hydrated)
      .pipe(
        filter(equals(true)),
        switchMap(() =>
          this.authStore.select(selectEmail).pipe(
            withLatestFrom(this.userBucketStore.select(selectClient)),
            take(1),
            map(([email, client]) =>
              !isNilOrEmpty(email) && !isNilOrEmpty(client)
                ? true
                : this.router.parseUrl('/login')
            )
          )
        )
      );
  }
}
