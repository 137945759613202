import { createAction, props } from '@ngrx/store';
import { CatchPageSpecies, ILogbookEvent } from './logbook-events.reducer';

export const addLogbookEvent = createAction(
  '[LogbookEvents] Add Logbook Event',
  props<{ logbookEvent: ILogbookEvent }>()
);

export const addLogbookEventSuccess = createAction(
  '[LogbookEvents] Add Logbook Event Success',
  props<{ logbookEvent: ILogbookEvent }>()
);

export const toggleSecondaryLogbookOff = createAction(
  '[LogbookEvents] Toggle Secondary Logbook Off',
  props<{ logbookEventId: string; isTep: boolean }>()
);

export const updateLogbookEvent = createAction(
  '[LogbookEvents] Update Logbook Event',
  props<{ logbookEvent: Partial<ILogbookEvent> }>()
);

export const deleteLogbookEvent = createAction(
  '[LogbookEvents] Delete Logbook Event',
  props<{ id: string }>()
);

export const deleteLogbookEvents = createAction(
  '[LogbookEvents] Delete Logbook Events',
  props<{ ids: string[] }>()
);

export const deleteTepEventOfLogbook = createAction(
  '[LogbookEvents] Delete Tep Event Of Logbook',
  props<{ id: string }>()
);

export const updateLogbookEvents = createAction(
  '[LogbookEvents] Update Logbook Events',
  props<{ logbookEvents: Array<ILogbookEvent> }>()
);

export const addLogbookEventPendingSpecies = createAction(
  '[LogbookEvents] Add Logbook Event Pending Species',
  props<{
    id: string;
    speciesAdded: { species: CatchPageSpecies; region: string }[];
    isSecondaryCatchPage: boolean;
  }>()
);
