import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { retainNoticeFeatureKey, reducer } from './retain-notice.reducer';
import { EffectsModule } from '@ngrx/effects';
import { RetainNoticeEffects } from './retain-notice.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(retainNoticeFeatureKey, reducer),
    EffectsModule.forFeature([RetainNoticeEffects]),
  ],
})
export class RetainNoticeModule {}
