/**
 * Queensland Fisheries FishNet API
 * Queensland Fisheries FishNet API
 *
 * OpenAPI spec version: 1.0.2
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AuthorityQuota } from './authorityQuota';
import { FisherySymbol } from './fisherySymbol';
import { PreTrip } from './preTrip';
import { ActivityCatchCreate } from './activityCatchCreate';
import { ActivityCatch } from './activityCatch';

export enum AuthorityTypeId {
  PCFL = 32,
  CFL = 3,
  FilletingPermit = 9,
}

export enum AuthorityStatus {
  Active = 'Active',
  Suspended = 'Suspended',
  Cancelled = 'Cancelled',
  Surrendered = 'Surrendered',
}

export interface Authority {
  licence?: string;
  status?: AuthorityStatus;
  issueDate?: string;
  ownerName?: string;
  validToDate?: string;
  authorityType?: string;
  authorityTypeId?: AuthorityTypeId;
  boatMarks?: Array<string>;
  fisherySymbols?: Array<FisherySymbol>;
  fisherySymbolsDisplay: Array<string>;
  permissions?: Array<Authority.PermissionsEnum>;
  currentPreTrip?: PreTrip;
  retainedCatch?: Array<ActivityCatch>;
  quota?: Array<AuthorityQuota>;
}

// tslint:disable-next-line:no-namespace
export namespace Authority {
  export type PermissionsEnum = 'ActivityNoticeManualReport';
  export const PermissionsEnum = {
    ActivityNoticeManualReport: 'ActivityNoticeManualReport' as PermissionsEnum,
  };
}
