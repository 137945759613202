import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromHomeState from './home.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromHomeState.homeFeatureKey, fromHomeState.reducer),
  ],
})
export class HomeModule {}
