import { createAction, props } from '@ngrx/store';
import { INotification } from '@qld-recreational/models';

export const deleteNotification = createAction(
  '[Notification Page] Delete Notification',
  props<{ id: string }>()
);

export const setReadNotificationIds = createAction(
  '[Notification Page] Set Read Notification Ids',
  props<{ readNotificationIds: string[] }>()
);

export const addReadNotificationId = createAction(
  '[Notification Page] Add Read Notification Id',
  props<{ id: string }>()
);

export const loadNewNotifications = createAction(
  '[Notification Page] Load New Notifications'
);

export const loadNewNotificationsSuccess = createAction(
  '[Notification API] Load New Notification Success',
  props<{ notifications: INotification[] }>()
);

export const loadNewNotificationsFailure = createAction(
  '[Notification API] Load New Notification Failure'
);
