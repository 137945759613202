import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { storageSync } from './ngrx-ionic-storage-sync';

// tslint:disable-next-line:no-empty-interface
export interface State {}

export function logger(reducer: ActionReducer<State>): ActionReducer<State> {
  return (state: State, action: any): State => {
    const newState = reducer(state, action);
    console.log('prev state', state);
    console.log('action', action);
    console.log('next state', newState);
    return newState;
  };
}

export const ionicStorageSyncReducer = storageSync({
  hydratedStateKey: 'hydrated', // Add this key to the state
});

export const reducers: ActionReducerMap<State> = {};

const ionicStorageMetaReducer = (
  reducer: ActionReducer<any>
): ActionReducer<any, any> => {
  return ionicStorageSyncReducer(reducer);
};

export const metaReducers: MetaReducer<State>[] = [ionicStorageMetaReducer];
