import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { map, take } from 'rxjs/operators';
import {
  IUserBucketState,
  selectClientVersion,
} from '../user-bucket/user-bucket.reducer';
import { VersionMismatchService } from './version-mismatch.service';

@Injectable()
export class VersionMismatchGuard implements CanActivate {
  constructor(
    private store: Store<IUserBucketState>,
    private versionController: VersionMismatchService,
    private router: Router
  ) {}

  canActivate() {
    return this.store.select(selectClientVersion).pipe(
      take(1),
      map((clientVersion) =>
        this.versionController.clientIsOlder(clientVersion)
          ? true
          : this.router.parseUrl('home')
      )
    );
  }
}
