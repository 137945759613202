/**
 * Queensland Fisheries FishNet API
 * Queensland Fisheries FishNet API
 *
 * OpenAPI spec version: 1.0.2
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { LogbookFieldValues } from './logbookFieldValues';

export interface LogbookField {
  id?: string;
  description?: string;
  type?: LogbookField.TypeEnum;
  fisherySymbols?: Array<string>;
  activityTypes?: Array<number>;
  fishingMethods?: Array<string>;
  eventable?: boolean;
  eventNomenclature?: string;
  placeholder?: string;
  instructions?: string;
  minimumValue?: number;
  maximumValue?: number;
  scale?: number;
  values?: Array<LogbookFieldValues>;
  optional?: boolean;
}

// tslint:disable-next-line:no-namespace
export namespace LogbookField {
  export type TypeEnum =
    | 'Integer'
    | 'Decimal'
    | 'DateTime'
    | 'Date'
    | 'Time'
    | 'TimeRange'
    | 'Selection'
    | 'SelectionTypeahead'
    | 'GridSiteLocation'
    | 'GpsLocation'
    | 'LatLngLocation'
    | 'LandingPoint'
    | 'Boolean'
    | 'Species'
    | 'Text';
  export const TypeEnum = {
    Integer: 'Integer' as TypeEnum,
    Decimal: 'Decimal' as TypeEnum,
    DateTime: 'DateTime' as TypeEnum,
    Date: 'Date' as TypeEnum,
    Time: 'Time' as TypeEnum,
    TimeRange: 'TimeRange' as TypeEnum,
    Selection: 'Selection' as TypeEnum,
    SelectionTypeahead: 'SelectionTypeahead' as TypeEnum,
    GpsLocation: 'GpsLocation' as TypeEnum,
    GridSiteLocation: 'GridSiteLocation' as TypeEnum,
    LatLngLocation: 'LatLngLocation' as TypeEnum,
    LandingPoint: 'LandingPoint' as TypeEnum,
    Boolean: 'Boolean' as TypeEnum,
    Species: 'Species' as TypeEnum,
    Text: 'Text' as TypeEnum,
  };
}
