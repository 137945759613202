import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { reducer, weightNoticeFeatureKey } from './weight-notice.reducer';
import { EffectsModule } from '@ngrx/effects';
import { WeightNoticeEffects } from './weight-notice.effects';
import { WeightNoticeService } from './weight-notice.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(weightNoticeFeatureKey, reducer),
    EffectsModule.forFeature([WeightNoticeEffects]),
  ],
  providers: [WeightNoticeService],
})
export class WeightNoticeModule {}
