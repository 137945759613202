import {
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from '@ngrx/store';
import { hasOnBoarded, updateSessionId } from './app-state.actions';
import { clearSessionData } from '../settings/settings.actions';

export const appStateFeatureKey = 'appState';

export interface IAppState {
  hasOnBoarded: boolean;
  sessionId: string;
}

export const initialState: IAppState = {
  hasOnBoarded: false,
  sessionId: undefined,
};

export const reducer = createReducer(
  initialState,
  on(hasOnBoarded, (state) => ({ ...state, hasOnBoarded: true })),
  on(updateSessionId, (state, { sessionId }) => ({ ...state, sessionId })),
  on(clearSessionData, () => initialState)
);

export const selectAppState = createFeatureSelector<IAppState>(
  appStateFeatureKey
);

export const selectHasOnBoarded = createSelector(
  selectAppState,
  (state) => state.hasOnBoarded
);

export const selectSessionId = createSelector(
  selectAppState,
  (state) => state.sessionId
);
