import {
  ActivityCatchCreate,
  UserBucketReferenceDataSeasons,
} from '../api/model';
import { ILogbookCatch } from '../logbook-catches/logbook-catches.reducer';
import { equals, includes, isNil, isNilOrEmpty } from '@qld-recreational/ramda';
import { IQuotaCatch } from './prior-emergency-notice.reducer';
import { v4 as uuidv4 } from 'uuid';
import { IQuotaConversions } from './prior-emergency-notice';

export const constructQuotaCatches = (
  allQuotaSymbols: UserBucketReferenceDataSeasons[],
  retainedCatchFromPreviousTrip: ActivityCatchCreate,
  catches: Array<ILogbookCatch & { fisherySymbol: string }>,
  quotaConversions: IQuotaConversions
) => {
  const quotaSymbols = allQuotaSymbols.filter(
    ({ quotaSymbol, prior }) =>
      retainedCatchFromPreviousTrip?.some((retainedCatch) =>
        equals(retainedCatch.quotaSymbol, quotaSymbol)
      ) ||
      catches.some(
        (logbookCatch) =>
          !logbookCatch.discarded &&
          equals(quotaSymbol, logbookCatch.quotaSymbol)
      )
  );
  const quotaFishingSymbolMap = new Map(
    [...(retainedCatchFromPreviousTrip ?? []), ...(catches ?? [])].map(
      ({ fisherySymbol, quotaSymbol }) => [quotaSymbol, fisherySymbol]
    )
  );
  const quotaCatches: IQuotaCatch[] = getInitQuotaCatches(
    quotaSymbols,
    quotaFishingSymbolMap
  );
  catches.forEach(
    ({ quantity, fisherySymbol, quotaSymbol, fishFormId, measureId }) => {
      const quotaCodes = findQuotaCodesByFishFormCodeAndQuotaSymbol(
        quotaConversions,
        fisherySymbol,
        quotaSymbol,
        fishFormId,
        measureId
      );
      if (equals(quotaCodes, null)) {
        return;
      }
      const [measureCode, fishFormCode] = quotaCodes;
      const matchedQuota = quotaCatches.find(
        (quotaCatch) =>
          equals(quotaCatch.quotaSymbol, quotaSymbol) &&
          equals(quotaCatch.fishFormCode, fishFormCode) &&
          equals(quotaCatch.measureCode, measureCode)
      );
      const matchedQuotasWithEmptyMeasure = quotaCatches.filter(
        (quotaCatch) =>
          equals(quotaCatch.quotaSymbol, quotaSymbol) &&
          equals(quotaCatch.fishFormCode, fishFormCode) &&
          isNilOrEmpty(measureCode)
      );
      if (!isNilOrEmpty(matchedQuotasWithEmptyMeasure)) {
        matchedQuotasWithEmptyMeasure.forEach(
          (matchedQuotaWithEmptyMeasure) =>
            (matchedQuotaWithEmptyMeasure.isEmptyMeasureMapping = true)
        );
        return;
      }
      if (isNil(matchedQuota)) {
        return;
      }
      matchedQuota.quantity = (matchedQuota.quantity ?? 0) + quantity;
    }
  );
  retainedCatchFromPreviousTrip?.forEach((retainedCatch) => {
    const matchedQuota = quotaCatches.find(
      (quotaCatch) =>
        equals(quotaCatch.quotaSymbol, retainedCatch.quotaSymbol) &&
        equals(quotaCatch.fishFormCode, retainedCatch.fishFormCode) &&
        equals(quotaCatch.measureCode, retainedCatch.measureCode)
    );
    if (isNil(matchedQuota)) {
      return;
    }
    matchedQuota.quantity =
      (matchedQuota.quantity ?? 0) + retainedCatch.quantity;
  });
  return quotaCatches;
};

export const getInitQuotaCatches = (
  quotaSymbols: UserBucketReferenceDataSeasons[],
  fishingSymbolMap: Map<string, string>
) => {
  return quotaSymbols.reduce(
    (accumulator, quotaSymbol) =>
      accumulator.concat(
        quotaSymbol.prior.map(
          ({
            fishFormCode,
            fishFormDescription,
            measureCode,
            measureDescription,
            minimumValue,
            maximumValue,
            scale,
            increment,
          }): IQuotaCatch => ({
            id: uuidv4(),
            quotaSymbol: quotaSymbol.quotaSymbol,
            quotaDescription: quotaSymbol.quotaDescription,
            fishFormCode,
            fishFormDescription,
            measureCode,
            measureDescription,
            minimumValue,
            maximumValue,
            scale,
            increment,
            quantity: null,
            emergencyActivityNoticeEnabled:
              quotaSymbol.emergencyActivityNoticeEnabled,
            priorActivityNoticeEnabled: quotaSymbol.priorActivityNoticeEnabled,
            retainActivityNoticeEnabled:
              quotaSymbol.retainActivityNoticeEnabled,
            weightActivityNoticeEnabled:
              quotaSymbol.weightActivityNoticeEnabled,
            weightActivityNoticeContentfulPageID:
              quotaSymbol.weightActivityNoticeContentfulPageID,
            fisherySymbol: fishingSymbolMap.get(quotaSymbol.quotaSymbol),
          })
        )
      ),
    []
  );
};

export const findQuotaCodesByFishFormCodeAndQuotaSymbol = (
  quotaConversions: IQuotaConversions,
  fisherySymbol: string,
  quotaSymbol: string,
  fishFormId: string,
  measureId: string
) => {
  const quotaConversion = quotaConversions.find(
    (conversion) =>
      includes(fisherySymbol, conversion.fisherySymbols) &&
      conversion.quota?.some((q) => includes(quotaSymbol, q.quotaSymbols))
  );

  if (isNilOrEmpty(quotaConversion)) {
    return null;
  }

  const quota = quotaConversion.quota.find(
    (q) =>
      includes(quotaSymbol, q.quotaSymbols) &&
      equals(measureId, q.measureCode) &&
      q.fishForms.some((fishForm) => equals(fishForm.fishFormCode, fishFormId))
  );

  if (isNilOrEmpty(quota)) {
    return null;
  }

  const fishFormConversion = quota.fishForms.find((fishForm) =>
    equals(fishForm.fishFormCode, fishFormId)
  );

  return [quota.quotaMeasureCode, fishFormConversion.quotaFishFormCode];
};
