import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { StoreModule } from '@ngrx/store';
import * as fromTrip from './trip.reducer';
import { TripService } from './trip.service';
import { EffectsModule } from '@ngrx/effects';
import { TripEffects } from './trip.effects';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    StoreModule.forFeature(fromTrip.tripFeatureKey, fromTrip.reducer),
    EffectsModule.forFeature([TripEffects]),
  ],
  providers: [TripService],
})
export class TripModule {}
