import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { updateBackgroundRequest } from '../background-request/background-request.actions';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { equals } from '@qld-recreational/ramda';
import { BackgroundRequestPath } from '../background-request/background-request.service';
import {
  addLogbookDay,
  deleteLogbookDay,
  updateLogbookDays,
} from './logbook-days.actions';
import { LogbookCreate } from '../api/model';
import {
  ILogbookDaysState,
  selectLogbookDaysByDate,
} from './logbook-days.reducer';
import { Store } from '@ngrx/store';
import { IActivityLogState } from '../activity-log/activity-log.reducer';
import { debugLog } from '../activity-log/activity-log.actions';

@Injectable()
export class LogbookDaysEffects {
  public backgroundRequestSubmitted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateBackgroundRequest),
      filter(
        ({
          backgroundRequest: {
            changes: { path },
          },
        }) => equals(path, BackgroundRequestPath.Logbook)
      ),
      map(
        ({
          backgroundRequest: {
            changes: { payload },
          },
        }) => (payload as LogbookCreate).logbooks[0].days[0].date
      ),
      switchMap((date) =>
        this.logbookDaysStore
          .select(selectLogbookDaysByDate(date))
          .pipe(take(1))
      ),
      map((logbookDays) =>
        updateLogbookDays({
          logbookDays: logbookDays.map((logbookDay) => ({
            ...logbookDay,
            submitted: true,
          })),
        })
      )
    )
  );

  public logDaysCreated$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(addLogbookDay),
        tap(({ logbookDay }) => {
          this.activityLogStore.dispatch(
            debugLog({
              activityLog: {
                page: 'Logbook Home',
                action: 'Create logbook day',
                message: `Created a new logbook day for ${logbookDay.date} with id ${logbookDay.id} for ${logbookDay.logbookId}`,
              },
            })
          );
        })
      ),
    {
      dispatch: false,
    }
  );

  public logDaysDeleted$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(deleteLogbookDay),
        tap(({ id }) => {
          this.activityLogStore.dispatch(
            debugLog({
              activityLog: {
                page: 'Logbook Home',
                action: 'Delete logbook day',
                message: `Deleted logbook day ${id}`,
              },
            })
          );
        })
      ),
    {
      dispatch: false,
    }
  );

  constructor(
    private actions$: Actions,
    private logbookDaysStore: Store<ILogbookDaysState>,
    private activityLogStore: Store<IActivityLogState>
  ) {}
}
