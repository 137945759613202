import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { utcNow } from '@qld-recreational/moment';
import { equals, isNil, isNilOrEmpty } from '@qld-recreational/ramda';
import { timer } from 'rxjs';
import {
  catchError,
  filter,
  map,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { log } from '../activity-log/activity-log.actions';
import { IActivityLogState } from '../activity-log/activity-log.reducer';
import { ViewStatus } from '../shared/ViewStatus';
import {
  attemptBackgroundRequest,
  deleteBackgroundRequest,
  noConnectionBackgroundRequest,
  submitBackgroundRequest,
  submitBackgroundRequestFailure,
  updateBackgroundRequest,
} from './background-request.actions';
import {
  IBackgroundRequestState,
  selectBackgroundRequests,
  selectBackgroundRequestsViewStatus,
} from './background-request.reducer';
import { BackgroundRequestService } from './background-request.service';
import { AuthService } from '../auth/auth.service';
import { PendingTransactionModalService } from '../pending-transaction-modal/pending-transaction-modal.service';
import { BackgroundRequest } from './background-request.model';
import { ModalController } from '@ionic/angular';
import { IAuthState, selectEmail } from '../auth/auth.reducer';
import { v4 as uuidv4 } from 'uuid';
import { StorageSyncActions } from '../reducers/ngrx-ionic-storage-sync';

@Injectable()
export class BackgroundRequestEffects {
  private readonly SUBMIT_INTERVAL = 10 * 1_000;

  constructor(
    private actions$: Actions,
    private backgroundRequestService: BackgroundRequestService,
    private pendingTransactionModalService: PendingTransactionModalService,
    private authService: AuthService,
    private authStore: Store<IAuthState>,
    private activityLogStore: Store<IActivityLogState>,
    private backgroundRequestStore: Store<IBackgroundRequestState>,
    private modalController: ModalController
  ) {}

  public attemptBackgroundRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(attemptBackgroundRequest),
      switchMap(({ backgroundRequest }) =>
        this.backgroundRequestService
          .checkConnectivity()
          .pipe(
            map((isConnected) =>
              isConnected
                ? submitBackgroundRequest({ backgroundRequest })
                : noConnectionBackgroundRequest()
            )
          )
      )
    )
  );

  public submitBackgroundRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(submitBackgroundRequest),
      this.pendingTransactionModalService.checkAndPresentPendingTransactionModal(),
      switchMap(({ backgroundRequest }) =>
        this.backgroundRequestService.submit(backgroundRequest).pipe(
          take(1),
          switchMap(async ({ backgroundRequest, transactionNumber }) => {
            await this.pendingTransactionModalService.dismissPendingTransactionModal();
            return updateBackgroundRequest({
              backgroundRequest: {
                id: backgroundRequest.id,
                changes: {
                  status: ViewStatus.Success,
                  activityLogData: backgroundRequest.activityLogData,
                  transactionNumber,
                  payload: backgroundRequest.payload,
                  path: backgroundRequest.path,
                  payloadId: backgroundRequest.payloadId,
                },
              },
            });
          }),
          catchError(async (error) => {
            await this.pendingTransactionModalService.dismissPendingTransactionModal();
            return submitBackgroundRequestFailure({
              backgroundRequest: {
                id: backgroundRequest.id,
                changes: {
                  ...(isNil(error.status) || equals(error?.status, 400)
                    ? {}
                    : { requestId: uuidv4() }),
                  payload: backgroundRequest.payload,
                  path: backgroundRequest.path,
                  status: ViewStatus.Failure,
                },
              },
            });
          })
        )
      )
    )
  );

  public submitBackgroundRequests$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(StorageSyncActions.HYDRATED),
      switchMap(() =>
        timer(0, this.SUBMIT_INTERVAL).pipe(
          withLatestFrom(this.authStore.select(selectEmail)),
          filter(([_, email]) => !isNilOrEmpty(email)),
          switchMap(() =>
            this.backgroundRequestStore
              .select(selectBackgroundRequestsViewStatus)
              .pipe(take(1))
          ),
          filter(
            (backgroundRequestsViewStatus) =>
              !equals(backgroundRequestsViewStatus, ViewStatus.Loading)
          ),
          switchMap(() =>
            this.backgroundRequestStore
              .select(selectBackgroundRequests)
              .pipe(take(1))
          ),
          filter((backgroundRequests) => backgroundRequests.length > 0),
          map((backgroundRequests) =>
            attemptBackgroundRequest({
              backgroundRequest: backgroundRequests[0],
            })
          )
        )
      )
    );
  });

  public updateBackgroundRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(updateBackgroundRequest),
      tap(({ backgroundRequest }) => {
        const { activityLogData, transactionNumber } =
          backgroundRequest.changes;
        if (isNil(activityLogData)) {
          return;
        }
        this.activityLogStore.dispatch(
          log({
            activityLog: {
              ...activityLogData,
              message: `${activityLogData.message}${
                transactionNumber
                  ? `<br/>Transaction number: <em>${transactionNumber}</em>`
                  : ``
              }`,
              timestamp: utcNow(),
            },
          })
        );
      }),
      map(({ backgroundRequest }) =>
        deleteBackgroundRequest({ id: backgroundRequest.id.toString() })
      )
    );
  });
}
