import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'qld-commercial-live-update-modal',
  templateUrl: './live-update-modal.component.html',
  styleUrls: ['./live-update-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LiveUpdateModalComponent implements OnInit {
  @Input() public downloadSubject: Subject<number>;
  public downloaded = 0;

  constructor(private cdr: ChangeDetectorRef) {}

  public ngOnInit() {
    this.downloadSubject.subscribe((downloaded) => {
      this.downloaded = downloaded;
      this.cdr.detectChanges();
    });
  }
}
