import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Deploy } from 'cordova-plugin-ionic/dist/ngx';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { LiveUpdateModalComponent } from '../live-update-modal/live-update-modal.component';

@Injectable({
  providedIn: 'root',
})
export class AppFlowService {
  constructor(
    private deploy: Deploy,
    private modalController: ModalController
  ) {}

  public async checkLiveUpdate(targetEnv: string) {
    if (environment.production) {
      await this.updateAppFlowConfig(targetEnv);
      await this.performManualUpdate();
    }
  }

  private async updateAppFlowConfig(targetEnv: string) {
    await this.deploy.configure({ channel: targetEnv });
  }

  private async performManualUpdate() {
    const update = await this.deploy.checkForUpdate();
    if (!update.available) {
      return;
    }
    const downloadSubject = new Subject<number>();
    await this.presentLiveUpdateModal(downloadSubject);
    await this.deploy.downloadUpdate((progress) => {
      downloadSubject.next(progress);
    });
    await this.deploy.extractUpdate();
    downloadSubject.complete();
    await this.modalController.dismiss();
    await this.deploy.reloadApp();
  }

  private async presentLiveUpdateModal(downloadSubject: Subject<number>) {
    const modal = await this.modalController.create({
      component: LiveUpdateModalComponent,
      componentProps: {
        downloadSubject,
      },
    });
    await modal.present();
  }
}
