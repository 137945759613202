import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { BackgroundRequest } from './background-request.model';
import { ManualPosition } from '../api/model';

export const addBackgroundRequest = createAction(
  '[BackgroundRequest] Add BackgroundRequest',
  props<{ backgroundRequest: BackgroundRequest }>()
);

export const updateBackgroundRequest = createAction(
  '[BackgroundRequest] Update BackgroundRequest',
  props<{ backgroundRequest: Update<BackgroundRequest> }>()
);

export const deleteBackgroundRequest = createAction(
  '[BackgroundRequest] Delete BackgroundRequest',
  props<{ id: string }>()
);

export const submitBackgroundRequestFailure = createAction(
  '[BackgroundRequest/API] Submit BackgroundRequest Failure',
  props<{ backgroundRequest: Update<BackgroundRequest> }>()
);

export const submitBackgroundRequest = createAction(
  '[BackgroundRequest] Submit BackgroundRequest',
  props<{ backgroundRequest: BackgroundRequest }>()
);

export const attemptBackgroundRequest = createAction(
  '[BackgroundRequest] Attempt BackgroundRequest',
  props<{ backgroundRequest: BackgroundRequest }>()
);

export const noConnectionBackgroundRequest = createAction(
  '[BackgroundRequest] No Connection BackgroundRequest'
);
