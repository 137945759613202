import { createAction, props } from '@ngrx/store';
import { EnvMap } from './settings';
import { ContentfulContext } from '../contentful/contentful.reducer';

export const loadEnvs = createAction(
  '[Settings] Load ENVs',
  props<{ environments: EnvMap }>()
);

export const clearSessionData = createAction('[Settings] Clear session data');

export const changeEnv = createAction(
  '[Settings] Change ENV',
  props<{ env: string }>()
);

export const switchContentfulContext = createAction(
  '[Settings] Switch Contentful Context',
  props<{ contentfulContext: ContentfulContext }>()
);
