import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, rootEffectsInit } from '@ngrx/effects';

import { map, switchMap, take } from 'rxjs/operators';
import { loadEnvs } from './settings.actions';
import { HttpClient } from '@angular/common/http';
import { EnvMap } from './settings';

@Injectable()
export class SettingsEffects {
  public loadEnvs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(rootEffectsInit),
      switchMap(() =>
        this.http.get<EnvMap>('assets/envs-commercial.json').pipe(take(1))
      ),
      map((environments) => loadEnvs({ environments }))
    )
  );

  constructor(private actions$: Actions, private http: HttpClient) {}
}
