import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PendingTransactionModalComponent } from './pending-transaction-modal.component';
import { PendingTransactionModalService } from './pending-transaction-modal.service';
import { IonicModule } from '@ionic/angular';

@NgModule({
  declarations: [PendingTransactionModalComponent],
  imports: [CommonModule, IonicModule],
  providers: [PendingTransactionModalService],
})
export class PendingTransactionModalModule {}
