import { Injectable } from '@angular/core';
import { combineLatest, from, Observable, of } from 'rxjs';
import {
  Entry,
  Asset,
  SyncCollection,
  EntryCollection,
  FieldsType,
} from 'contentful';
import {
  map,
  mapTo,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { isEmpty, isNil } from '@qld-recreational/ramda';
import { Store } from '@ngrx/store';
import { IContentfulState, selectNextSyncToken } from './contentful.reducer';
import {
  getAssetFile,
  getId,
  IContentfulEntry,
  ISyncCollection,
} from './contentful';
import {
  assetsDownloaded,
  clearNextSyncToken,
  nextSyncTokenUpdate,
  updateAssetsCached,
  updateAssetsDownloaded,
  updateAssetsToCache,
  updateEntriesCached,
  updateEntriesToCache,
} from './contentful.actions';
import { Insomnia } from '@awesome-cordova-plugins/insomnia/ngx';
import { ISettingState } from '../settings/settings.reducer';
import {
  selectApiUrlSV,
  selectContentfulContext,
} from '../settings/settings.selectors';
import { ApiService } from '../api/api.service';
import { StorageService } from '@qld-recreational/storage';

interface ICacheAsset {
  blob: Blob;
  type: string;
  id: string;
}

@Injectable({
  providedIn: 'root',
})
export class ContentfulService {
  constructor(
    private http: HttpClient,
    private settingStore: Store<ISettingState>,
    private contentfulStore: Store<IContentfulState>,
    private storage: StorageService,
    private insomnia: Insomnia,
    private apiService: ApiService
  ) {}

  public preventScreenSleep() {
    this.insomnia.keepAwake();
  }

  public allowScreenSleep() {
    this.insomnia.allowSleepAgain();
  }

  public checkRemoteUpdate(): Observable<ISyncCollection> {
    const fetchAllEntriesIfNeedToUpdate = (syncCollection: ISyncCollection) =>
      !isEmpty(syncCollection.entries)
        ? this.settingStore.select(selectApiUrlSV).pipe(
            withLatestFrom(
              this.contentfulStore.select(selectContentfulContext)
            ),
            take(1),
            switchMap(([apiUrl, contentfulContext]) =>
              this.apiService.getAWSWithParams(
                `${apiUrl}/contentful/get-entries`,
                { contentfulContext }
              )
            ),
            map((entries) => ({
              ...syncCollection,
              entries: entries.items,
            }))
          )
        : of(syncCollection);

    const updateEntriesAssetsToDownload = (syncCollection: ISyncCollection) => {
      const entriesToCache = syncCollection.entries.length;
      const assetsToCache = syncCollection.assets.length;
      this.contentfulStore.dispatch(updateEntriesToCache({ entriesToCache }));
      this.contentfulStore.dispatch(updateAssetsToCache({ assetsToCache }));
    };

    const sync = (nextSyncToken: string): Observable<ISyncCollection> =>
      this.settingStore.select(selectApiUrlSV).pipe(
        withLatestFrom(this.contentfulStore.select(selectContentfulContext)),
        take(1),
        switchMap(([apiUrl, contentfulContext]) =>
          this.apiService.getAWSWithParams(`${apiUrl}/contentful/sync`, {
            ...(isNil(nextSyncToken) ? {} : { nextSyncToken }),
            contentfulContext,
          })
        ),
        switchMap((syncCollection) =>
          fetchAllEntriesIfNeedToUpdate(syncCollection)
        ),
        tap(updateEntriesAssetsToDownload)
      );

    return this.contentfulStore
      .select(selectNextSyncToken)
      .pipe(take(1))
      .pipe(switchMap((nextSyncToken) => sync(nextSyncToken)));
  }

  public syncEntries(
    syncCollection: ISyncCollection
  ): Observable<ISyncCollection> {
    const store = (entries: Array<Entry<any>>): Observable<Array<any>> =>
      combineLatest(
        entries.map((entry) =>
          this.storage
            .set(getId(entry), JSON.stringify(entry))
            .then(() => this.contentfulStore.dispatch(updateEntriesCached()))
        )
      );

    const sync = (): Observable<ISyncCollection> => {
      const { entries, deletedEntries } = syncCollection;
      if (isEmpty(entries) && isEmpty(deletedEntries)) {
        return of(syncCollection);
      }
      const updateEntries = store(entries);
      const deleteEntries = deletedEntries.map((entry) =>
        this.storage.remove(getId(entry))
      );
      return combineLatest([].concat(updateEntries).concat(deleteEntries)).pipe(
        mapTo(syncCollection)
      );
    };

    return sync();
  }

  public syncAssets(syncCollection: ISyncCollection): Observable<string> {
    const { assets, deletedAssets } = syncCollection;
    if (isEmpty(assets) && isEmpty(deletedAssets)) {
      return of(syncCollection.nextSyncToken);
    }
    const updateAssets = this.fetchAssets(assets).pipe(
      tap(() => this.contentfulStore.dispatch(assetsDownloaded())),
      switchMap((assetsToCache) => this.cacheAssets(assetsToCache))
    );
    const deleteAssets = deletedAssets.map((asset) =>
      from(this.storage.remove(getId(asset)))
    );
    return combineLatest([].concat(updateAssets).concat(deleteAssets)).pipe(
      mapTo(syncCollection.nextSyncToken)
    );
  }

  private fetchAssets(assets: Array<Asset>): Observable<Array<ICacheAsset>> {
    const assetsToFetch = assets
      .filter((asset) => !isNil(getAssetFile(asset)?.url))
      .map((asset) => this.constructAssetUrl(asset));
    return combineLatest(
      assetsToFetch.map((asset) =>
        this.http.get(asset.url, { responseType: 'blob' }).pipe(
          map((blob) => ({ blob, type: asset.type, id: asset.id })),
          tap(() => this.contentfulStore.dispatch(updateAssetsDownloaded()))
        )
      )
    ) as Observable<Array<ICacheAsset>>;
  }

  private cacheAssets(assets: Array<ICacheAsset>) {
    const fileReaderPromise = (blob: Blob, type: string) =>
      new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        type.includes('image')
          ? reader.readAsDataURL(blob)
          : reader.readAsText(blob);
      });
    return combineLatest(
      assets.map(async (asset) => {
        const result = await fileReaderPromise(asset.blob, asset.type);
        return this.storage
          .set(asset.id, result as string)
          .then(() => this.contentfulStore.dispatch(updateAssetsCached()));
      })
    );
  }

  public getEntryById<T = FieldsType>(
    entryId: string
  ): Promise<IContentfulEntry<T>> {
    return this.storage.get(entryId).then((value) => JSON.parse(value));
  }

  private constructAssetUrl(asset: Asset): {
    id: string;
    url: string;
    type: string;
  } {
    return {
      id: getId(asset),
      type: getAssetFile(asset).contentType,
      url: `https:${getAssetFile(asset).url}?w=${window.innerWidth}`,
    };
  }

  public clearCache(): Observable<void> {
    return from(this.storage.clear()).pipe(
      tap(() => this.contentfulStore.dispatch(clearNextSyncToken()))
    );
  }

  public updateNextToken(nextSyncToken: string) {
    this.contentfulStore.dispatch(nextSyncTokenUpdate({ nextSyncToken }));
  }
}
