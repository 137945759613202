import {
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from '@ngrx/store';
import {
  complement,
  equals,
  includes,
  intersection,
  isEmpty,
  isNilOrEmpty,
  mergeAll,
  prop,
  where,
} from '@qld-recreational/ramda';
import { switchUser } from '../auth/auth.actions';
import { clearSessionData } from '../settings/settings.actions';
import {
  loadUserBucket,
  loadUserBucketFailed,
  loadUserBucketSuccessful,
} from './user-bucket.actions';
import { ViewStatus } from '../shared/ViewStatus';
import {
  AuthorityTypeId,
  Client,
  LogbookClass,
  UserBucketReferenceData,
} from '../api/model';
import { utcNow } from '@qld-recreational/moment';
import { selectPreTrip } from '../trip/trip.selectors';
import { isNil } from 'rambda';

export const userBucketFeatureKey = 'userBucket';

export interface IUserBucketState {
  client: Client;
  referenceData: UserBucketReferenceData;
  clientVersion: string;
  viewStatus: ViewStatus;
  lastUpdated: string;
}

export const initialState: IUserBucketState = {
  client: undefined,
  referenceData: undefined,
  clientVersion: undefined,
  viewStatus: ViewStatus.Initial,
  lastUpdated: undefined,
};

export const userBucketPersistenceKeys = Object.keys(initialState).filter(
  complement(equals('viewStatus'))
);

export const reducer = createReducer(
  initialState,
  on(loadUserBucket, (state) => ({
    ...state,
    viewStatus: ViewStatus.Loading,
  })),
  on(
    loadUserBucketSuccessful,
    (state, { client, referenceData, clientVersion }) => ({
      ...state,
      client,
      referenceData,
      clientVersion,
      viewStatus: ViewStatus.Success,
      lastUpdated: utcNow(),
    })
  ),
  on(loadUserBucketFailed, (state) => ({
    ...state,
    viewStatus: ViewStatus.Failure,
  })),
  on(clearSessionData, () => initialState),
  on(switchUser, (state) => ({ ...initialState, viewStatus: state.viewStatus }))
);

export const selectUserBucketState =
  createFeatureSelector<IUserBucketState>(userBucketFeatureKey);

export const selectClient = createSelector(
  selectUserBucketState,
  (state) => state.client
);

export const selectLastUpdated = createSelector(
  selectUserBucketState,
  (state) => state.lastUpdated
);

export const selectReferenceData = createSelector(
  selectUserBucketState,
  (state) => state.referenceData
);

export const selectCdrLogbooks = createSelector(
  selectReferenceData,
  (state) => state.cdrLogbooks
);

export const selectTripTypes = createSelector(
  selectReferenceData,
  (referenceData) => referenceData.tripTypes
);

export const selectClientAndReferenceData = createSelector(
  selectUserBucketState,
  ({ client, referenceData }) => ({ client, referenceData })
);

export const selectCurrentAuthority = createSelector(
  selectUserBucketState,
  selectPreTrip,
  ({ client, referenceData }, { primaryCommercialFishingLicence }) =>
    client?.authorities.find((authority) =>
      equals(authority.licence, primaryCommercialFishingLicence)
    )
);

export const selectAuthorities = createSelector(
  selectClient,
  (client) => client.authorities
);

export const selectAuthorityByPCFL = (pcfl: string) =>
  createSelector(selectAuthorities, (authorities) =>
    authorities.find((authority) => equals(authority.licence, pcfl))
  );

export const selectFisherySymbolsOfPCFLAuthorities = createSelector(
  selectAuthorities,
  (authorities) => {
    return authorities
      .filter((authority) =>
        equals(authority.authorityTypeId, AuthorityTypeId.PCFL)
      )
      .map((authority) => {
        return authority?.fisherySymbols
          ?.filter((symbol) => symbol.primarySymbol || symbol.secondarySymbol)
          .map(({ id }) => id);
      })
      .flat();
  }
);

export const selectUserBucketViewStatus = createSelector(
  selectUserBucketState,
  (state) => state.viewStatus
);

export const selectClientVersion = createSelector(
  selectUserBucketState,
  (state) => state.clientVersion
);

export const selectLogbookReferenceByFisherySymbol = (fisherySymbol: string) =>
  createSelector(selectReferenceData, ({ logbooks }) =>
    logbooks.find((logbook) => logbook.fisherySymbols?.includes(fisherySymbol))
  );

export const selectSecondaryLogbookReferenceByClassAndFisherySymbol = (
  logbookClass: LogbookClass,
  fisherySymbol: string
) =>
  createSelector(selectReferenceData, ({ secondaryLogbooks }) =>
    secondaryLogbooks.find(
      (logbook) =>
        equals(logbook.logbookClass, logbookClass) &&
        logbook.fisherySymbols?.includes(fisherySymbol)
    )
  );

export const selectTEPLogbookReference = createSelector(
  selectReferenceData,
  ({ secondaryLogbooks }) =>
    secondaryLogbooks.find((logbook) =>
      equals(logbook.logbookClass, LogbookClass.TEP)
    )
);

export const selectLogbooksReferenceByFisherySymbols = (
  fisherySymbols: Array<string>
) =>
  createSelector(selectReferenceData, ({ logbooks, secondaryLogbooks }) => [
    ...logbooks.filter(
      (logbook) =>
        !isEmpty(intersection(logbook?.fisherySymbols ?? [], fisherySymbols))
    ),
    ...secondaryLogbooks.filter(
      (logbook) =>
        !isEmpty(intersection(logbook?.fisherySymbols ?? [], fisherySymbols))
    ),
  ]);

export const selectLogbookReferenceForFavouriteSpecies = createSelector(
  selectReferenceData,
  ({ logbooks, secondaryLogbooks }) => {
    return [
      ...logbooks,
      ...secondaryLogbooks.filter((logbook) =>
        equals(logbook.logbookClass, LogbookClass.SR)
      ),
    ];
  }
);

export const selectMySeasons = createSelector(
  selectCurrentAuthority,
  selectUserBucketState,
  ({ quota }, { referenceData: { seasons } }) => {
    return seasons.filter((season) =>
      quota?.some((q) => equals(q.quotaSymbol, season.quotaSymbol))
    );
  }
);

export const selectRetainedCatch = createSelector(
  selectCurrentAuthority,
  ({ retainedCatch }) => retainedCatch
);

export const selectSpeciesByRetainedCatch = createSelector(
  selectRetainedCatch,
  selectReferenceData,
  (retainedCatch, { cdrLogbooks, seasons }) => {
    if (isNilOrEmpty(retainedCatch)) {
      return [];
    }
    const quotaSymbols = retainedCatch.map((retained) =>
      prop('quotaSymbol', retained)
    );

    const quotaFisheryMap = new Map(
      retainedCatch.map((retained) => [
        retained.quotaSymbol,
        retained.fisherySymbol,
      ])
    );

    return cdrLogbooks
      .map((cdrLogbook) => cdrLogbook.species)
      .flat()
      .filter((species) => !isNil(species.quotaSymbols))
      .filter((species) => {
        const matchedQuotaSymbols = intersection(
          quotaSymbols,
          species.quotaSymbols
        );
        return (
          !isNilOrEmpty(species.quotaSymbols) &&
          !isNilOrEmpty(matchedQuotaSymbols) &&
          seasons
            .filter(where({ quotaSymbol: includes(matchedQuotaSymbols) }))
            .some((season) => season.catchDisposalLogbookEnabled)
        );
      })
      .map((species) => ({
        ...species,
        fisherySymbol: quotaFisheryMap.get(species.quotaSymbols[0]),
      }));
  }
);

export const selectCDRLogbookReferenceByFisherySymbol = (
  fisherySymbol: string
) =>
  createSelector(selectUserBucketState, ({ referenceData: { cdrLogbooks } }) =>
    cdrLogbooks.find((cdrLogbook) =>
      cdrLogbook.fisherySymbols.some((symbol) => equals(symbol, fisherySymbol))
    )
  );

export const selectQuotaSymbols = createSelector(
  selectReferenceData,
  ({ quota }) =>
    quota.map(({ quotaSymbol, quotaRegion }) => ({
      quotaSymbol,
      quotaRegion,
    }))
);

export const selectMyQuotaSymbolsForPriorEmergencyNotice = createSelector(
  selectMySeasons,
  (seasons) =>
    seasons.filter(
      (season) =>
        season.emergencyActivityNoticeEnabled ||
        season.priorActivityNoticeEnabled
    )
);

export const selectActivityNoticeEnabled = createSelector(
  selectCurrentAuthority,
  ({ fisherySymbols }) =>
    mergeAll(
      [
        'emergencyActivityNoticeEnabled',
        'priorActivityNoticeEnabled',
        'retainActivityNoticeEnabled',
        'weightActivityNoticeEnabled',
      ].map((key) => ({
        [key]: fisherySymbols.some((fisherySymbol) => fisherySymbol[key]),
      }))
    ) as {
      emergencyActivityNoticeEnabled: boolean;
      priorActivityNoticeEnabled: boolean;
      retainActivityNoticeEnabled: boolean;
      weightActivityNoticeEnabled: boolean;
    }
);
