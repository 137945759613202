import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { submitRetainNoticeSuccessful } from '../trip/trip.actions';
import { filter, map, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { updateBackgroundRequest } from '../background-request/background-request.actions';
import { equals } from '@qld-recreational/ramda';
import { BackgroundRequestPath } from '../background-request/background-request.service';
import { addRetainCatches, initRetainCatches } from './retain-notice.actions';
import { Store } from '@ngrx/store';
import {
  IPriorEmergencyNoticeState,
  selectValidQuotaCatches,
} from '../prior-emergency-notice/prior-emergency-notice.reducer';
import {
  IWeightNoticeState,
  selectNumberOfWeightNotices,
} from '../weight-notice/weight-notice.reducer';
import { IRetainCatch } from './retain-notice.reducer';

@Injectable()
export class RetainNoticeEffects {
  public initRetainCatches$ = createEffect(() =>
    this.actions$.pipe(
      ofType(initRetainCatches),
      switchMap(() =>
        this.priorEmergencyNoticeStore
          .select(selectValidQuotaCatches)
          .pipe(take(1))
      ),
      map((quotaCatches) =>
        quotaCatches.filter(
          (quotaCatch) => quotaCatch.retainActivityNoticeEnabled
        )
      ),
      withLatestFrom(
        this.weightNoticeStore.select(selectNumberOfWeightNotices)
      ),
      map(([retainCatches, numberOfWeightNotice]) =>
        this.resetQuantityIfWeightNotice(retainCatches, numberOfWeightNotice)
      ),
      map((retainCatches) => addRetainCatches({ retainCatches }))
    )
  );

  public backgroundRequestSubmitted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateBackgroundRequest),
      filter(
        ({
          backgroundRequest: {
            changes: { path },
          },
        }) => equals(path, BackgroundRequestPath.RetainNotice)
      ),
      map(
        ({
          backgroundRequest: {
            changes: { transactionNumber },
          },
        }) =>
          submitRetainNoticeSuccessful({ receipt: Number(transactionNumber) })
      )
    )
  );

  private resetQuantityIfWeightNotice(
    retainCatches: IRetainCatch[],
    numberOfWeightNotice: number
  ) {
    console.log('resetQuantityIfWeightNotice', retainCatches);
    if (numberOfWeightNotice === 0) {
      return retainCatches.map((retainCatch) => ({
        ...retainCatch,
        maximumValue: retainCatch.quantity,
      }));
    }
    return retainCatches.map((retainCatch) => ({
      ...retainCatch,
      quantity: 0,
    }));
  }

  constructor(
    private actions$: Actions,
    private priorEmergencyNoticeStore: Store<IPriorEmergencyNoticeState>,
    private weightNoticeStore: Store<IWeightNoticeState>
  ) {}
}
