import { ActivityStatus, Page } from '../api/model';

const activityLog = (isPriorNotice: boolean) => ({
  page: isPriorNotice ? Page.PriorNotice : Page.EmergencyNotice,
  action: isPriorNotice ? 'Submit prior notice' : 'Submit emergency notice',
});

export const submitPriorEmergencyNoticeActivityLog = ({
  tripCorrelationID,
  createdDateTime,
  isPriorNotice,
}: {
  tripCorrelationID: string;
  createdDateTime: string;
  isPriorNotice: boolean;
}) => ({
  tripCorrelationID,
  message: `User submitted ${
    isPriorNotice ? 'prior notice' : 'emergency notice'
  }.`,
  status: ActivityStatus.pending,
  timestamp: createdDateTime,
  ...activityLog(isPriorNotice),
});

export const submitPriorEmergencyNoticeSuccessActivityLog = (
  tripCorrelationID: string,
  isPriorNotice: boolean
) => ({
  tripCorrelationID,
  status: ActivityStatus.success,
  message: `${
    isPriorNotice ? 'Prior notice' : 'Emergency notice'
  } was successfully submitted.`,
  ...activityLog(isPriorNotice),
});
