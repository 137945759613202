import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  addLogbookEvent,
  addLogbookEventSuccess,
  deleteLogbookEvent,
  deleteLogbookEvents,
  deleteTepEventOfLogbook,
  toggleSecondaryLogbookOff,
  updateLogbookEvent,
} from './logbook-events.actions';
import {
  concatMap,
  filter,
  map,
  mergeMap,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { Store } from '@ngrx/store';
import {
  ILogbookCatchesState,
  selectCatchesByLogbookEventId,
} from '../logbook-catches/logbook-catches.reducer';
import { removeLogbookCatches } from '../logbook-catches/logbook-catches.actions';
import { equals, isEmpty } from '@qld-recreational/ramda';
import { deleteLogbookDay } from '../logbook-days/logbook-days.actions';
import {
  ILogbookEventsState,
  selectEventById,
  selectEventsByLogbookDayId,
} from './logbook-events.reducer';
import { combineLatest, forkJoin } from 'rxjs';
import { LogbookEventsService } from './logbook-events.service';
import { debugLog, log } from '../activity-log/activity-log.actions';
import { qldDate, utcNow } from '@qld-recreational/moment';
import {
  ILogbookDaysState,
  selectLogbookDayById,
} from '../logbook-days/logbook-days.reducer';
import {
  ILogbooksState,
  selectLogbookById,
} from '../logbooks/logbooks.reducer';
import {
  IUserBucketState,
  selectSecondaryLogbookReferenceByClassAndFisherySymbol,
} from '../user-bucket/user-bucket.reducer';
import { LogbookClass, Page } from '../api/model';
import { IActivityLogState } from '../activity-log/activity-log.reducer';

@Injectable()
export class LogbookEventsEffect {
  constructor(
    private actions$: Actions,
    private logbookCatchesStore: Store<ILogbookCatchesState>,
    private logbookEventsStore: Store<ILogbookEventsState>,
    private logbookEventsService: LogbookEventsService,
    private logbookDaysStore: Store<ILogbookDaysState>,
    private logbooksStore: Store<ILogbooksState>,
    private userBucketStore: Store<IUserBucketState>,
    private activityLogStore: Store<IActivityLogState>
  ) {}

  public deleteLogbookEventsIfTepToggledoff$ = createEffect(() =>
    this.actions$.pipe(
      ofType(toggleSecondaryLogbookOff),
      filter(({ isTep }) => isTep),
      map(({ logbookEventId }) =>
        deleteTepEventOfLogbook({ id: logbookEventId })
      )
    )
  );

  public toggleSecondaryLogbookOff$ = createEffect(() =>
    this.actions$.pipe(
      ofType(toggleSecondaryLogbookOff),
      switchMap(({ logbookEventId, isTep }) =>
        this.logbookEventsStore.select(selectEventById(logbookEventId)).pipe(
          take(1),
          switchMap((event) =>
            this.logbookDaysStore
              .select(selectLogbookDayById(event.logbookDayId))
              .pipe(take(1))
          ),
          switchMap((logbookDay) =>
            this.logbooksStore
              .select(selectLogbookById(logbookDay.logbookId))
              .pipe(
                take(1),
                switchMap((logbook) =>
                  this.userBucketStore.select(
                    selectSecondaryLogbookReferenceByClassAndFisherySymbol(
                      isTep ? LogbookClass.TEP : LogbookClass.SR,
                      logbook.fisherySymbol
                    )
                  )
                ),
                map((logbook) =>
                  log({
                    activityLog: {
                      action: 'Toggle Secondary Logbook Off',
                      message: `${
                        logbook.logbook
                      } deactivated by user for logbook date ${qldDate(
                        logbookDay.date
                      )}.`,
                      timestamp: utcNow(),
                      page: Page.Logbook,
                    },
                  })
                )
              )
          )
        )
      )
    )
  );

  public updateLogbookCatches$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateLogbookEvent),
      filter(({ logbookEvent }) => equals(logbookEvent.allowsCatch, false)),
      switchMap(({ logbookEvent: { id } }) =>
        this.logbookCatchesStore
          .select(selectCatchesByLogbookEventId(id))
          .pipe(take(1))
      ),
      map((logbookCatches) => logbookCatches.map(({ id }) => id)),
      map((ids) => removeLogbookCatches({ ids }))
    )
  );

  public deleteLogbookEvents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteLogbookDay),
      switchMap(({ id }) =>
        this.logbookEventsStore
          .select(selectEventsByLogbookDayId(id))
          .pipe(take(1))
      ),
      map((logbookEvents) => logbookEvents.map(({ id }) => id)),
      map((ids) => deleteLogbookEvents({ ids }))
    )
  );

  public deleteLogbookCatchesByLogbookEvents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteLogbookEvents),
      mergeMap(({ ids }) =>
        forkJoin(
          ids.map((id) =>
            this.logbookEventsStore
              .select(selectCatchesByLogbookEventId(id))
              .pipe(take(1))
          )
        )
      ),
      map((logbookCatches) => logbookCatches.flat()),
      map((logbookCatches) => logbookCatches.map(({ id }) => id)),
      filter((ids) => !isEmpty(ids)),
      map((ids) => removeLogbookCatches({ ids }))
    )
  );

  public deleteLogbookCatchesByLogbookEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteLogbookEvent),
      switchMap(({ id }) =>
        this.logbookEventsStore
          .select(selectCatchesByLogbookEventId(id))
          .pipe(take(1))
      ),
      map((logbookCatches) => logbookCatches.map(({ id }) => id)),
      filter((ids) => !isEmpty(ids)),
      map((ids) => removeLogbookCatches({ ids }))
    )
  );

  public addLogbookEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addLogbookEvent),
      switchMap(({ logbookEvent }) =>
        this.logbookEventsService.getLogbooksByEvent(logbookEvent).pipe(
          switchMap(([logbook, secondaryLogbook]) =>
            combineLatest([
              this.logbookEventsService.getEventRegionsAndSpecies(logbook),
              this.logbookEventsService.getEventRegionsAndSpecies(
                secondaryLogbook
              ),
            ])
          ),
          map(([pendingSpecies, pendingSecondarySpecies]) =>
            addLogbookEventSuccess({
              logbookEvent: {
                ...logbookEvent,
                pendingSpecies,
                pendingSecondarySpecies,
              },
            })
          )
        )
      )
    )
  );

  public logAddLogbookEvent$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(addLogbookEventSuccess),
        concatMap(({ logbookEvent }) =>
          this.logbookDaysStore
            .select(selectLogbookDayById(logbookEvent.logbookDayId))
            .pipe(
              take(1),
              tap((logbookDay) => {
                this.activityLogStore.dispatch(
                  debugLog({
                    activityLog: {
                      page: 'Logbook Home',
                      action: 'Create logbook entry',
                      message: `Created a new event for ${logbookDay.date} with id ${logbookEvent.id} for ${logbookDay.logbookId}`,
                    },
                  })
                );
              })
            )
        )
      ),
    {
      dispatch: false,
    }
  );

  public logDeleteLogbookEvent$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(deleteLogbookEvent, deleteLogbookEvents),
        tap((action) => {
          const deletedEvents =
            action.type === deleteLogbookEvent.type
              ? action.id
              : action.ids.join(' ,');

          this.activityLogStore.dispatch(
            debugLog({
              activityLog: {
                page: 'Logbook Home',
                action: `Delete logbook ${
                  action.type === deleteLogbookEvent.type ? 'event' : 'events'
                }`,
                message: `Deleted ${deletedEvents}`,
              },
            })
          );
        })
      ),
    {
      dispatch: false,
    }
  );
}
