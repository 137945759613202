import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  IPriorEmergencyNoticeState,
  selectValidQuotaCatches,
} from '../prior-emergency-notice/prior-emergency-notice.reducer';
import {
  IUserBucketState,
  selectMySeasons,
} from '../user-bucket/user-bucket.reducer';
import { map, take, withLatestFrom } from 'rxjs/operators';
import { equals, isNil } from '@qld-recreational/ramda';
import { Observable } from 'rxjs';
import { IWeightCatch } from './weight-notice.reducer';

@Injectable()
export class WeightNoticeService {
  constructor(
    private priorEmergencyStore: Store<IPriorEmergencyNoticeState>,
    private userBucketStore: Store<IUserBucketState>
  ) {}

  public constructInitWeightNoticeFromPriorEmergency(): Observable<
    IWeightCatch[]
  > {
    return this.priorEmergencyStore.select(selectValidQuotaCatches).pipe(
      take(1),
      map((quotaCatches) =>
        quotaCatches.filter(
          (quotaCatch) => quotaCatch.weightActivityNoticeEnabled
        )
      ),
      withLatestFrom(this.userBucketStore.select(selectMySeasons)),
      map(([quotaCatches, seasons]) =>
        quotaCatches.map((quotaCatch) => {
          const weights = seasons.find((season) =>
            equals(season.quotaSymbol, quotaCatch.quotaSymbol)
          ).weight;
          const weight = weights.find((w) =>
            equals(w.fishFormCode, quotaCatch.fishFormCode)
          );
          if (isNil(weight)) {
            return [];
          }
          const {
            measureCode,
            measureDescription,
            minimumValue,
            maximumValue,
            scale,
            increment,
          } = weight;
          return [
            {
              ...quotaCatch,
              measureCode,
              measureDescription,
              maximumValue,
              minimumValue,
              scale,
              increment,
              quantity: null,
            },
          ];
        })
      ),
      map((weights) => weights.flat())
    );
  }
}
