import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import {
  preferenceRegionsSpeciesFeatureKey,
  reducer,
} from './preferences-regions-species.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(preferenceRegionsSpeciesFeatureKey, reducer),
  ],
})
export class PreferenceRegionsSpeciesModule {}
