import {
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from '@ngrx/store';
import { ICDRDisposal } from '../shared/models/CDR';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { equals } from '@qld-recreational/ramda';
import {
  deleteDisposalPreference,
  loadDisposalPreferences,
  upsertDisposalPreference,
} from './preferences-disposal.action';
import { clearSessionData } from '../settings/settings.actions';
import { switchUser } from '../auth/auth.actions';

export const preferencesDisposalFeatureKey = 'preferencesDisposal';

export interface IDisposalPreference extends ICDRDisposal {
  id: string;
}

export interface IPreferencesDisposalState
  extends EntityState<IDisposalPreference> {}

export const adapter: EntityAdapter<IDisposalPreference> = createEntityAdapter<
  IDisposalPreference
>({
  selectId: (disposal) => disposal.id,
});

export const initialState: IPreferencesDisposalState = adapter.getInitialState(
  {}
);

export const reducer = createReducer(
  initialState,
  on(loadDisposalPreferences, (state, { preferences }) => ({
    ...preferences,
  })),
  on(upsertDisposalPreference, (state, { disposal }) =>
    adapter.upsertOne(disposal, state)
  ),
  on(deleteDisposalPreference, (state, { id }) => adapter.removeOne(id, state)),
  on(clearSessionData, switchUser, () => initialState)
);

export const selectPreferencesDisposalState = createFeatureSelector<
  IPreferencesDisposalState
>(preferencesDisposalFeatureKey);

export const { selectAll } = adapter.getSelectors();

export const selectDisposalPreferences = createSelector(
  selectPreferencesDisposalState,
  selectAll
);

export const selectDisposalPreferenceById = (disposalId: string) =>
  createSelector(selectDisposalPreferences, (disposals) =>
    disposals.find(({ id }) => equals(id, disposalId))
  );
