import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  filter,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { loginSuccess, logout } from '../auth/auth.actions';
import { MESSAGES } from '../messages';
import {
  loadUserBucket,
  loadUserBucketFailed,
  loadUserBucketSuccessful,
} from './user-bucket.actions';
import { of } from 'rxjs';
import { ToastService } from '@qld-recreational/toast';
import { ApiService } from '../api/api.service';
import { Store } from '@ngrx/store';
import { IActivityLogState } from '../activity-log/activity-log.reducer';
import { log } from '../activity-log/activity-log.actions';
import {
  firewallBlockActivityLog,
  loadUserBucketSuccessActivityLog,
  noPCFLorCFLLog,
} from './user-bucket-activity-log';
import { ITripState } from '../trip/trip.reducer';
import { selectPreTrip, selectTripId } from '../trip/trip.selectors';
import {
  AuthorityTypeId,
  Client,
  Page,
  UserBucketReferenceData,
} from '../api/model';
import { equals, isNil } from '@qld-recreational/ramda';
import { Preferences } from '@capacitor/preferences';
import { HttpErrorResponse } from '@angular/common/http';
import { Capacitor, HttpResponse } from '@capacitor/core';
import {
  IUserBucketState,
  selectClient,
  selectClientVersion,
  selectReferenceData,
} from './user-bucket.reducer';
import { BackgroundRequestService } from '../background-request/background-request.service';
import { NavController } from '@ionic/angular';

@Injectable()
export class UserBucketEffects {
  public loadUserBucket$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadUserBucket),
      withLatestFrom(this.userBucketStore.select(selectReferenceData)),
      switchMap(([{ page }, referenceData]) =>
        this.getUserBucket(page, referenceData)
      )
    )
  );

  public loginSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loginSuccess),
      withLatestFrom(
        this.userBucketStore.select(selectReferenceData),
        this.userBucketStore.select(selectClient),
        this.userBucketStore.select(selectClientVersion),
        this.tripStore.select(selectPreTrip).pipe(map(Boolean))
      ),
      tap(([, , , , isOnTrip]) => {
        if (isOnTrip) {
          this.navController.navigateForward('home', {
            replaceUrl: true,
            animated: false,
          });
        }
      }),
      map(([, referenceData, client, clientVersion, isOnTrip]) =>
        isOnTrip
          ? loadUserBucketSuccessful({
              page: Page.Login,
              referenceData,
              client,
              clientVersion,
            })
          : loadUserBucket({ page: Page.Login })
      )
    )
  );

  public loadUserBucketSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadUserBucketSuccessful),
        tap(() => Preferences.set({ key: 'loggedIn', value: 'true' })),
        withLatestFrom(this.tripStore.select(selectTripId)),
        tap(([{ page }, tripCorrelationID]) =>
          this.activityLogStore.dispatch(
            log({
              activityLog: loadUserBucketSuccessActivityLog(
                page,
                tripCorrelationID
              ),
            })
          )
        )
      ),
    { dispatch: false }
  );

  private getUserBucket = (
    page?: Page,
    prevReferenceData?: UserBucketReferenceData
  ) =>
    this.bgService.checkConnectivity().pipe(
      switchMap((connected) => {
        if (!connected) {
          this.toastService.presentFailureToast(MESSAGES.noInternet);
          return of(loadUserBucketFailed());
        }
        return this.apiService.getUserBucket().pipe(
          map((userBucket) => ({
            client: userBucket.clients?.[0],
            referenceData: equals(page, Page.QuotaBalance)
              ? {
                  ...prevReferenceData,
                  seasons: userBucket.referenceData.seasons,
                  quota: userBucket.referenceData.quota,
                }
              : userBucket.referenceData,
            clientVersion: userBucket.clientVersion ?? '0.0.0',
          })),
          switchMap(({ client, referenceData, clientVersion }) => {
            if (this.noActiveCFL(client)) {
              this.toastService.presentFailureToast(MESSAGES.noActiveCFL);
              this.activityLogStore.dispatch(
                log({
                  activityLog: noPCFLorCFLLog(page),
                })
              );
              return [loadUserBucketFailed(), logout()];
            }
            if (this.noActivePCFL(client)) {
              this.toastService.presentFailureToast(MESSAGES.noActivePCFL);
              this.activityLogStore.dispatch(
                log({
                  activityLog: noPCFLorCFLLog(page),
                })
              );
              return [loadUserBucketFailed(), logout()];
            }
            return of(
              loadUserBucketSuccessful({
                page,
                client,
                referenceData,
                clientVersion,
              })
            );
          }),
          catchError((err: HttpErrorResponse | HttpResponse) => {
            if (err.status !== 401) {
              this.toastService.presentFailureToast(
                MESSAGES.loadUserBucketFailure
              );
              this.handleFirewallBlock(err, page);
            }
            return of(loadUserBucketFailed());
          })
        );
      })
    );

  private noActiveCFL(client: Client) {
    return (
      isNil(client) ||
      isNil(client.authorities) ||
      !client.authorities.some(({ authorityTypeId }) =>
        equals(authorityTypeId, AuthorityTypeId.CFL)
      )
    );
  }

  private noActivePCFL(client: Client) {
    return !client.authorities.some(({ authorityTypeId }) =>
      equals(authorityTypeId, AuthorityTypeId.PCFL)
    );
  }

  private handleFirewallBlock(
    err: HttpErrorResponse | HttpResponse,
    page: Page
  ) {
    const error = Capacitor.isNativePlatform()
      ? (err as HttpResponse)?.data
      : (err as HttpErrorResponse)?.error;
    if (
      !isNil(error) &&
      equals(typeof error, 'string') &&
      error.includes('support ID')
    ) {
      this.activityLogStore.dispatch(
        log({
          activityLog: firewallBlockActivityLog(page, error),
        })
      );
    }
  }

  constructor(
    private actions$: Actions,
    private toastService: ToastService,
    private apiService: ApiService,
    private activityLogStore: Store<IActivityLogState>,
    private tripStore: Store<ITripState>,
    private userBucketStore: Store<IUserBucketState>,
    private bgService: BackgroundRequestService,
    private navController: NavController
  ) {}
}
