import {
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { switchUser } from '../auth/auth.actions';
import { clearSessionData } from '../settings/settings.actions';
import {
  addLogbookCatches,
  removeLogbookCatches,
  updateLogbookCatches,
} from './logbook-catches.actions';
import { equals } from '@qld-recreational/ramda';
import { LogbookCreateLogbookCatch, LogbookMeasureType } from '../api/model';
import { endTripSuccessful } from '../trip/trip.actions';
import { toggleSecondaryLogbookOff } from '../logbook-events/logbook-events.actions';

export interface ILogbookCatchSummary
  extends Pick<ILogbookCatch, 'speciesId' | 'speciesDescription'> {
  fishForms: {
    fishFormId: string;
    measures: Map<string, number>;
  }[];
}

export interface ILogbookCatchSummarySection {
  catches: ILogbookCatchSummary[];
  measures: Set<string>;
}

export interface ILogbookCatch extends LogbookCreateLogbookCatch {
  id: string;
  logbookEventId: string;
  quotaSymbol: string;
  speciesCDRId: number;
  speciesDescription: string;
  fishFormDescription: string;
  measureDescription: string;
  measureType: LogbookMeasureType;
  minimumValue?: number;
  maximumValue?: number;
  scale?: number;
  increment?: number;
  isSecondaryCatch?: boolean;
}

export const logbookCatchesFeatureKey = 'logbookCatches';

export interface ILogbookCatchesState extends EntityState<ILogbookCatch> {}

export const adapter: EntityAdapter<ILogbookCatch> = createEntityAdapter<
  ILogbookCatch
>({
  selectId: (logbookCatch) => logbookCatch.id,
  sortComparer: (catchA, catchB) =>
    catchA.quotaSymbol > catchB.quotaSymbol ? -1 : 1,
});

export const initialState: ILogbookCatchesState = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(addLogbookCatches, (state, { logbookCatches }) =>
    adapter.addMany(logbookCatches, state)
  ),
  on(updateLogbookCatches, (state, { logbookCatches }) =>
    adapter.upsertMany(logbookCatches, state)
  ),
  on(toggleSecondaryLogbookOff, (state, { isTep, logbookEventId }) => {
    if (isTep) {
      return state;
    }

    return adapter.removeMany(
      (entity) =>
        equals(entity.logbookEventId, logbookEventId) &&
        entity.isSecondaryCatch,
      state
    );
  }),
  on(removeLogbookCatches, (state, { ids }) => adapter.removeMany(ids, state)),
  on(clearSessionData, switchUser, endTripSuccessful, () => initialState)
);

const { selectAll } = adapter.getSelectors();

export const selectLogbookCatchesState = createFeatureSelector<
  ILogbookCatchesState
>(logbookCatchesFeatureKey);

export const selectCatches = createSelector(
  selectLogbookCatchesState,
  selectAll
);

export const selectCatchesByLogbookEventId = (logbookEventId: string) =>
  createSelector(selectCatches, (catches) =>
    catches.filter((logbookCatch) =>
      equals(logbookCatch.logbookEventId, logbookEventId)
    )
  );

export const selectPrimaryCatchesByLogbookEventId = (logbookEventId: string) =>
  createSelector(selectCatchesByLogbookEventId(logbookEventId), (catches) =>
    catches.filter((logbookCatch) => !logbookCatch.isSecondaryCatch)
  );

export const selectSecondaryCatchesByLogbookEventId = (
  logbookEventId: string
) =>
  createSelector(selectCatchesByLogbookEventId(logbookEventId), (catches) =>
    catches.filter((logbookCatch) => logbookCatch.isSecondaryCatch)
  );

export const selectCatchesByLogbookEventIdSpeciesIdAndQuotaSymbol = (
  logbookEventId: string,
  speciesId: string,
  quotaSymbol: string
) =>
  createSelector(selectCatches, (catches) =>
    catches.filter(
      (logbookCatch) =>
        equals(logbookCatch.logbookEventId, logbookEventId) &&
        equals(logbookCatch.speciesId, Number(speciesId)) &&
        equals(logbookCatch.quotaSymbol, quotaSymbol)
    )
  );
