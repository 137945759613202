import {
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { switchUser } from '../auth/auth.actions';
import { clearSessionData } from '../settings/settings.actions';
import { equals } from '@qld-recreational/ramda';
import {
  addCatchDisposalRecord,
  clearCatchDisposalRecords,
  deleteCatchDisposalRecord,
  deleteCatchDisposalRecords,
  initSpecies,
  initViewStatus,
  updateCatchDisposalRecord,
  updateCatchDisposalRecords,
} from './catch-disposal-record.actions';
import { ViewStatus } from '../shared/ViewStatus';
import {
  endTripSuccessful,
  submitCDR,
  submitCDRFailure,
  submitCDRSuccessful,
} from '../trip/trip.actions';
import { utcNow } from '@qld-recreational/moment';
import { ICDRConsignment, ICDRDisposal } from '../shared/models/CDR';
import { v4 as uuidv4 } from 'uuid';

export interface ICatchDisposalRecord extends ICDRDisposal, ICDRConsignment {
  id: string;
  weightNoticeId: string;
  completed: boolean;
  submitted: boolean;
  dateSubmitted: string;

  cdrNumber: number;
  primaryCommercialFishingLicence: string;
  boatMark: string;
  PriorNoticeReceipt: number;
  WeightNoticeReceipt: number;

  species: {
    speciesCDRId: number;
    speciesDescription: string;
    fisherySymbol?: string;
  }[];

  viewStatus: ViewStatus;
  requestId: string;
  touched: boolean;
}

export interface ICDRInitSpecies {
  id: number;
  speciesCDRId: number;
  speciesDescription: string;
  fisherySymbol: string;
}

export const catchDisposalRecordFeatureKey = 'catchDisposalRecord';

export interface ICatchDisposalRecordState
  extends EntityState<ICatchDisposalRecord> {
  initSpecies: ICDRInitSpecies[];
  currentCdrNumber: number;
}

export const adapter: EntityAdapter<ICatchDisposalRecord> =
  createEntityAdapter<ICatchDisposalRecord>({
    selectId: (cdr) => cdr.id,
  });

export const initialState: ICatchDisposalRecordState = adapter.getInitialState({
  initSpecies: undefined,
  currentCdrNumber: 1,
});

export const reducer = createReducer(
  initialState,
  on(initSpecies, (state, { species }) => ({
    ...state,
    currentCdrNumber: 1,
    initSpecies: species,
  })),
  on(addCatchDisposalRecord, (state, { catchDisposalRecord }) =>
    adapter.addOne(
      {
        ...catchDisposalRecord,
        cdrNumber: state.currentCdrNumber,
        species: state.initSpecies,
      },
      { ...state, currentCdrNumber: state.currentCdrNumber + 1 }
    )
  ),
  on(updateCatchDisposalRecord, (state, { catchDisposalRecord }) =>
    adapter.updateOne(catchDisposalRecord, state)
  ),
  on(updateCatchDisposalRecords, (state, { catchDisposalRecords }) =>
    adapter.upsertMany(catchDisposalRecords, state)
  ),
  on(submitCDR, (state, { id }) =>
    adapter.updateOne(
      { id, changes: { viewStatus: ViewStatus.Loading } },
      state
    )
  ),
  on(submitCDRSuccessful, (state, { id }) =>
    adapter.updateOne(
      {
        id,
        changes: {
          submitted: true,
          dateSubmitted: utcNow(),
          viewStatus: ViewStatus.Success,
        },
      },
      state
    )
  ),
  on(submitCDRFailure, (state, { id, shouldUpdateRequestId }) =>
    adapter.updateOne(
      {
        id,
        changes: {
          ...(shouldUpdateRequestId ? { requestId: uuidv4() } : {}),
          viewStatus: ViewStatus.Failure,
        },
      },
      state
    )
  ),
  on(initViewStatus, (state, { id }) =>
    adapter.updateOne(
      { id, changes: { viewStatus: ViewStatus.Initial } },
      state
    )
  ),
  on(deleteCatchDisposalRecord, (state, { id }) =>
    adapter.removeOne(id, state)
  ),
  on(deleteCatchDisposalRecords, (state, { ids }) =>
    adapter.removeMany(ids, state)
  ),
  on(clearCatchDisposalRecords, (state) => adapter.removeAll(state)),
  on(clearSessionData, switchUser, endTripSuccessful, () => initialState)
);

const { selectAll } = adapter.getSelectors();

const selectCatchDisposalRecordState =
  createFeatureSelector<ICatchDisposalRecordState>(
    catchDisposalRecordFeatureKey
  );

export const selectCatchDisposalRecords = createSelector(
  selectCatchDisposalRecordState,
  selectAll
);

export const selectInitSpecies = createSelector(
  selectCatchDisposalRecordState,
  (state) => state.initSpecies
);

export const selectCatchDisposalRecordById = (id: string) =>
  createSelector(selectCatchDisposalRecords, (cdrs) =>
    cdrs.find((cdr) => equals(cdr.id, id))
  );

export const selectViewStatusById = (id: string) =>
  createSelector(selectCatchDisposalRecordById(id), (cdr) => cdr?.viewStatus);

export const selectCatchDisposalRecordsByWeightNoticeId = (
  weightNoticeId: string
) =>
  createSelector(selectCatchDisposalRecords, (cdrs) =>
    filterCatchDisposalRecordsByWeightNoticeId(weightNoticeId, cdrs)
  );

export const filterCatchDisposalRecordsByWeightNoticeId = (
  weightNoticeId: string,
  cdrs: ICatchDisposalRecord[]
) => cdrs.filter((cdr) => equals(cdr.weightNoticeId, weightNoticeId));

export const selectNumberOfNotSubmittedCDRs = createSelector(
  selectCatchDisposalRecords,
  (cdrs) => cdrs.filter((cdr) => !cdr.submitted).length
);
