import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { UserBucketEffects } from './user-bucket.effects';
import { StoreModule } from '@ngrx/store';
import * as fromUserBucketState from './user-bucket.reducer';
import { UserBucketService } from './user-bucket.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromUserBucketState.userBucketFeatureKey,
      fromUserBucketState.reducer
    ),
    EffectsModule.forFeature([UserBucketEffects]),
  ],
  providers: [UserBucketService],
})
export class UserBucketModule {}
