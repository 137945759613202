import {
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import {
  addQuotaCatches,
  updateEmergencyLandingPoint,
  updateQuotaCatches,
} from './prior-emergency-notice.actions';
import {
  endTripSuccessful,
  submitPriorEmergencyNoticeSuccessful,
} from '../trip/trip.actions';
import { clearSessionData } from '../settings/settings.actions';
import { switchUser } from '../auth/auth.actions';
import { ActivityEmergencyCreateLandingPoint } from '../api/model';
import { LocationType } from '../shared/models/Location';
import { IActivityNoticeEntry } from '../shared/models/ActivityNoticeEntry';
import { isNil } from '@qld-recreational/ramda';

export const priorEmergencyNoticeFeatureKey = 'priorEmergencyNotice';

export interface IQuotaCatch extends IActivityNoticeEntry {
  isEmptyMeasureMapping?: boolean;
}

export interface IPriorEmergencyNoticeState extends EntityState<IQuotaCatch> {
  receipt: number;
  emergencyLandingPoint: ActivityEmergencyCreateLandingPoint;
  locationType: LocationType;
}

export const adapter: EntityAdapter<IQuotaCatch> = createEntityAdapter<
  IQuotaCatch
>({
  selectId: (priorNotice) => priorNotice.id,
});

export const initialState: IPriorEmergencyNoticeState = adapter.getInitialState(
  {
    receipt: null,
    emergencyLandingPoint: null,
    locationType: LocationType.LandingPlace,
  }
);

export const reducer = createReducer(
  initialState,
  on(addQuotaCatches, (state, { quotaCatches }) =>
    adapter.addMany(quotaCatches, state)
  ),
  on(updateQuotaCatches, (state, { quotaCatches }) =>
    adapter.updateMany(
      quotaCatches.map((quotaCatch) => ({
        id: quotaCatch.id,
        changes: { quantity: quotaCatch.quantity },
      })),
      state
    )
  ),
  on(
    updateEmergencyLandingPoint,
    (state, { emergencyLandingPoint, locationType }) => ({
      ...state,
      emergencyLandingPoint,
      locationType,
    })
  ),
  on(submitPriorEmergencyNoticeSuccessful, (state, { receipt }) => ({
    ...state,
    receipt,
  })),
  on(clearSessionData, switchUser, endTripSuccessful, () => initialState)
);

const { selectAll } = adapter.getSelectors();

const selectPriorEmergencyNoticesState = createFeatureSelector<
  IPriorEmergencyNoticeState
>(priorEmergencyNoticeFeatureKey);

export const selectQuotaCatches = createSelector(
  selectPriorEmergencyNoticesState,
  selectAll
);

export const selectValidQuotaCatches = createSelector(
  selectQuotaCatches,
  (quotaCatches) =>
    quotaCatches.filter((quotaCatch) => !isNil(quotaCatch.quantity))
);

export const selectEmergencyLandingPoint = createSelector(
  selectPriorEmergencyNoticesState,
  ({ emergencyLandingPoint }) => emergencyLandingPoint
);

export const selectLocationType = createSelector(
  selectPriorEmergencyNoticesState,
  ({ locationType }) => locationType
);

export const selectTransactionNumber = createSelector(
  selectPriorEmergencyNoticesState,
  (state) => state.receipt
);
