<div class="container">
  <div class="flex-container">
    <div class="percentage">
      {{ downloaded / 100 | percent }}
    </div>
    <ion-progress-bar
      color="primary"
      [value]="downloaded / 100"
    ></ion-progress-bar>

    <div class="loading-message">
      <p>Downloading App Live Update...</p>
    </div>
  </div>
</div>
