import { createAction, props } from '@ngrx/store';
import {
  ILandingPlacePreference,
  ILandingPlacePreferenceState,
} from './preference-landing-places.reducer';

export const loadLandingPlacesPreferences = createAction(
  '[Preferences Landing Places] Load Landing Places',
  props<{ preferences: ILandingPlacePreferenceState }>()
);

export const upsertLandingPlacesPreference = createAction(
  '[Preferences Landing Places] Upsert Landing Places',
  props<{ landingPlaces: ILandingPlacePreference[] }>()
);

export const deleteLandingPlacesPreference = createAction(
  '[Preferences Landing Places] Delete Landing Places',
  props<ILandingPlacePreference>()
);
