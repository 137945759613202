import {
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from '@ngrx/store';
import { switchUser } from '../auth/auth.actions';
import { clearSessionData } from '../settings/settings.actions';
import {
  loadGPSPreferences,
  UpdateGPSPreferences,
} from './preference-gps.actions';

export enum CoordinateFormat {
  DD = 'Decimal Degrees',
  DMS = 'Degrees Minutes Seconds',
  DDM = 'Degrees Decimal Minutes',
}

export const preferencesGPSFeatureKey = 'preferencesGPS';

export interface IPreferencesGPSState {
  coordinateFormat: CoordinateFormat;
}

export const initialState: IPreferencesGPSState = {
  coordinateFormat: CoordinateFormat.DMS,
};

export const reducer = createReducer(
  initialState,
  on(loadGPSPreferences, UpdateGPSPreferences, (state, { preferences }) => ({
    ...preferences,
  })),
  on(clearSessionData, switchUser, () => initialState)
);

export const selectPreferencesGPSState = createFeatureSelector<
  IPreferencesGPSState
>(preferencesGPSFeatureKey);

export const selectCoordinateFormat = createSelector(
  selectPreferencesGPSState,
  (state) => state.coordinateFormat
);
