import {
  Asset,
  ChainModifiers,
  DeletedAsset,
  DeletedEntry,
  Entry,
  EntryCollection,
  EntryFieldTypes,
  EntrySkeletonType,
  FieldsType,
  SyncCollection,
} from 'contentful';
import { JsonObject } from 'type-fest';

export enum ContentfulContentType {
  species = 'speciesPage',
}

export enum ContentfulEntries {
  AboutThisApp = 'BDKgJ1igvL6FC2VhqGcAq',
}

export type ContentfulEntryCollection<T extends EntrySkeletonType> =
  EntryCollection<T> & Stringifiable;

interface Stringifiable {
  stringifySafe: () => string;
}

export type IContentfulEntry<T extends FieldsType> = Entry<
  EntrySkeletonType<T>,
  'WITHOUT_UNRESOLVABLE_LINKS'
>;
export type IContentfulAsset = Asset<'WITHOUT_UNRESOLVABLE_LINKS'>;

export type ExtractFields<T> = IContentfulEntry<T>['fields'];

type IContentfulEntryLinkField<T> = EntryFieldTypes.EntryLink<
  EntrySkeletonType<T>
>;
type IContentfulEntryArrayField<T> = EntryFieldTypes.Array<
  IContentfulEntryLinkField<T>
>;

export type ISyncCollection = SyncCollection<
  EntrySkeletonType<FieldsType, string>,
  ChainModifiers,
  string
> &
  Stringifiable;

export interface IReferenceFields {
  sections: IContentfulEntryArrayField<ISection>;
  placeholder: EntryFieldTypes.Text;
  title: EntryFieldTypes.Text;
}

export type IReference = ExtractFields<IReferenceFields>;

export interface ISectionFields {
  title: EntryFieldTypes.Text;
  icon: EntryFieldTypes.AssetLink;
  links: IContentfulEntryArrayField<ISectionLinkTypesFields>;
}

export type ISectionLinkTypesFields =
  | IExternalLinkFields
  | IContentFields
  | ISectionFields
  | IMapContentFields;

export type ISectionEntry = IContentfulEntry<ISectionFields>;
export type ISection = ExtractFields<ISectionFields>;

export interface IExternalLinkFields {
  title: EntryFieldTypes.Text;
  externalLink: EntryFieldTypes.Text;
  icon: EntryFieldTypes.AssetLink;
}

export type IExternalLinkEntry = IContentfulEntry<IExternalLinkFields>;
export type IExternalLink = ExtractFields<IExternalLinkFields>;

export interface IContentFields {
  title: EntryFieldTypes.Text;
  content: EntryFieldTypes.RichText;
  icon: EntryFieldTypes.AssetLink;
}

export type IContentEntry = IContentfulEntry<IContentFields>;
export type IContent = ExtractFields<IContentFields>;

export interface IMapContentFields {
  title: EntryFieldTypes.Text;
  featureLayers: EntryFieldTypes.Array<EntryFieldTypes.AssetLink>;
  queryFeatureLayers: EntryFieldTypes.Array<EntryFieldTypes.AssetLink>;
  icon: EntryFieldTypes.AssetLink;
  baseLayers: EntryFieldTypes.Array<EntryFieldTypes.AssetLink>;
  attributes: EntryFieldTypes.Object<IAttributes>;
}

export type IMapContentEntry = IContentfulEntry<IMapContentFields>;
export type IMapContent = ExtractFields<IMapContentFields>;

export interface IAttributes extends JsonObject {
  [key: string]: Array<IAttribute>;
}

export interface IAttribute extends JsonObject {
  attributeName: string;
  displayName: string;
}

export const getAssetFile = (asset: Asset) => {
  const file = asset?.fields?.file;
  return file?.hasOwnProperty('en-US') ? file['en-US'] : file;
};

export const getId = (
  content: IContentfulEntry<unknown> | Asset | DeletedEntry | DeletedAsset
) => content?.sys.id;

export enum ContentfulSyncStatus {
  Initial = 'Initial',
  UpdateChecked = 'UpdateChecked',
  EntriesSynced = 'EntriesSynced',
  AssetsDownloaded = 'AssetsDownloaded',
  AssetsSynced = 'AssetsSynced',
  Failure = 'Failure',
}
