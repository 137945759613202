import { createAction, props } from '@ngrx/store';
import {
  IConsignmentPreference,
  IPreferencesConsignmentState,
} from './preferences-consignment.reducer';

export const loadConsignmentPreferences = createAction(
  '[Preferences Consignment] Load Consignment Preferences',
  props<{ preferences: IPreferencesConsignmentState }>()
);

export const upsertConsignmentPreference = createAction(
  '[Preferences Consignment] Upsert Consignment Preferences',
  props<{ consignment: IConsignmentPreference }>()
);

export const deleteConsignmentPreference = createAction(
  '[Preferences Consignment] Delete Consignment Preferences',
  props<{ id: string }>()
);
