import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ToastService } from '@qld-recreational/toast';
import { of } from 'rxjs';
import {
  catchError,
  debounceTime,
  filter,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { MESSAGES } from '../messages';
import { AuthenticationError } from './auth';
import {
  loginFailure,
  login,
  loginSuccess,
  logout,
  logoutFailure,
  logoutSuccess,
  switchUser,
} from './auth.actions';
import { IAuthState, selectPreviouslyLoggedInUserEmail } from './auth.reducer';
import { and, equals, isNilOrEmpty } from '@qld-recreational/ramda';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { Preferences } from '@capacitor/preferences';
import { IManualPollingState } from '../manual-polling/manual-polling.reducer';
import { stopPollingIfPollingHasStarted } from '../manual-polling/manual-polling.actions';

@Injectable()
export class AuthEffects {
  public login$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(login),
      switchMap(() =>
        this.authService.login().pipe(
          switchMap(() => this.authService.getEmail()),
          map((email) => loginSuccess({ email })),
          tap((_) =>
            this.toastService.presentSuccessToast(
              MESSAGES.authSuccess,
              'half-width-toast'
            )
          ),
          catchError((error) => {
            if (!equals(error.message, AuthenticationError.UserDecline)) {
              this.toastService.presentFailureToast(MESSAGES.authFailure);
            }
            return of(loginFailure({ error }));
          })
        )
      )
    );
  });

  public logout$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(logout),
      debounceTime(500),
      switchMap(() =>
        this.authService.logout().pipe(
          map(logoutSuccess),
          catchError((error) => of(logoutFailure({ error })))
        )
      )
    );
  });

  public logoutSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(logoutSuccess),
      tap(() => Preferences.set({ key: 'loggedIn', value: 'false' })),
      tap(() => this.router.navigateByUrl('login', { replaceUrl: true })),
      map(() => stopPollingIfPollingHasStarted())
    )
  );

  public logoutFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(logoutFailure),
        tap(() =>
          this.toastService.presentFailureToast(MESSAGES.failedToLogout)
        )
      ),
    { dispatch: false }
  );

  public switchUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loginSuccess),
      withLatestFrom(this.authStore.select(selectPreviouslyLoggedInUserEmail)),
      map(([{ email }, previouslyLoggedInUserEmail]) =>
        and(
          !isNilOrEmpty(previouslyLoggedInUserEmail),
          !equals(email, previouslyLoggedInUserEmail)
        )
      ),
      filter((isUserChanged) => isUserChanged),
      map(switchUser)
    );
  });

  constructor(
    private actions$: Actions,
    private authStore: Store<IAuthState>,
    private manualPollingStore: Store<IManualPollingState>,
    private authService: AuthService,
    private toastService: ToastService,
    private router: Router
  ) {}
}
