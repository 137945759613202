import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromLogbooks from './logbooks.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromLogbooks.logbooksFeatureKey,
      fromLogbooks.reducer
    ),
  ],
})
export class LogbooksModule {}
