import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  ILogbookCatchesState,
  selectCatches,
  selectPrimaryCatchesByLogbookEventId,
  selectSecondaryCatchesByLogbookEventId,
} from '../logbook-catches/logbook-catches.reducer';
import {
  IEventableFieldsValue,
  ILogbookEvent,
  ILogbookEventsState,
  selectEventsByLogbookDayId,
} from '../logbook-events/logbook-events.reducer';
import {
  ILogbookDay,
  ILogbookDaysState,
  selectLogbookDayById,
  selectLogbookDaysByLogbookId,
} from '../logbook-days/logbook-days.reducer';
import {
  ILogbook,
  ILogbooksState,
  selectLogbookById,
  selectLogbooks,
} from '../logbooks/logbooks.reducer';
import {
  IUserBucketState,
  selectAuthorityByPCFL,
  selectCDRLogbookReferenceByFisherySymbol,
  selectLogbookReferenceByFisherySymbol,
  selectMyQuotaSymbolsForPriorEmergencyNotice,
  selectRetainedCatch,
  selectSecondaryLogbookReferenceByClassAndFisherySymbol,
  selectTEPLogbookReference,
} from '../user-bucket/user-bucket.reducer';
import { forkJoin, Observable, of } from 'rxjs';
import {
  ActivityCatch,
  ActivityEmergencyCreate,
  ActivityLogPostEntry,
  ActivityPriorCreate,
  ActivityStatus,
  ActivityWeightCreate,
  Logbook,
  LogbookClass,
  LogbookCreate,
  LogbookCreateField,
  LogbookCreateLogbook,
  LogbookCreateLogbookCatch,
  LogbookCreateLogbookDays,
  LogbookCreateLogbookEvents,
  LogbookField,
  Page,
  TripTypeId,
} from '../api/model';
import { ITripState } from './trip.reducer';
import { selectPreTrip } from './trip.selectors';
import {
  catchError,
  defaultIfEmpty,
  filter,
  map,
  mergeMap,
  switchMap,
  take,
  withLatestFrom,
} from 'rxjs/operators';
import {
  clone,
  complement,
  equals,
  includes,
  isNil,
  isNilOrEmpty,
  not,
  omit,
  prop,
} from '@qld-recreational/ramda';
import {
  IPriorEmergencyNoticeState,
  IQuotaCatch,
  selectEmergencyLandingPoint,
  selectValidQuotaCatches,
} from '../prior-emergency-notice/prior-emergency-notice.reducer';
import { qldDate, qldDateTime, utcNow } from '@qld-recreational/moment';
import { ActivityRetainedCreate } from '../api/model/activityRetainedCreate';
import {
  IRetainNoticeState,
  selectRetainCatches,
} from '../retain-notice/retain-notice.reducer';
import {
  IWeightNoticeState,
  selectWeightNoticeById,
} from '../weight-notice/weight-notice.reducer';
import {
  ICatchDisposalRecordCatchesState,
  ICDRCatch,
  selectCDRCatchesByCDRId,
} from '../catch-disposal-record-catches/catch-disposal-record-catches.reducer';
import {
  ICatchDisposalRecord,
  ICatchDisposalRecordState,
  selectCatchDisposalRecordById,
  selectInitSpecies,
} from '../catch-disposal-record/catch-disposal-record.reducer';
import { ISettingState } from '../settings/settings.reducer';
import { IAuthState } from '../auth/auth.reducer';
import { environment } from '../../environments/environment';
import { DisposalMethod } from '../shared/models/CDR';
import { log } from '../activity-log/activity-log.actions';
import { propEq } from 'rambda';
import * as Sentry from '@sentry/browser';

@Injectable()
export class TripService {
  constructor(
    private logbookCatchesStore: Store<ILogbookCatchesState>,
    private logbookEventsStore: Store<ILogbookEventsState>,
    private logbookDaysStore: Store<ILogbookDaysState>,
    private logbooksStore: Store<ILogbooksState>,
    private tripStore: Store<ITripState>,
    private settingStore: Store<ISettingState>,
    private authStore: Store<IAuthState>,
    private priorNoticeStore: Store<IPriorEmergencyNoticeState>,
    private retainNoticeStore: Store<IRetainNoticeState>,
    private weightNoticeStore: Store<IWeightNoticeState>,
    private userBucketStore: Store<IUserBucketState>,
    private cdrStore: Store<ICatchDisposalRecordState>,
    private cdrCatchStore: Store<ICatchDisposalRecordCatchesState>
  ) {}

  public ifCDRShouldBeEnabled() {
    return this.cdrStore.select(selectInitSpecies).pipe(
      filter(complement(isNil)),
      take(1),
      map((initCDRSpecies) => !isNilOrEmpty(initCDRSpecies)),
      withLatestFrom(
        this.userBucketStore
          .select(selectRetainedCatch)
          .pipe(map((retainedCatches) => !isNilOrEmpty(retainedCatches)))
      ),
      map(
        ([hasReportedSpecies, hasRetainedCatches]) =>
          hasReportedSpecies || hasRetainedCatches
      )
    );
  }

  public constructSubmitCDRPayload(
    id: string
  ): Observable<LogbookCreate & { disposalMethod: DisposalMethod }> {
    return this.getTripInfo().pipe(
      withLatestFrom(
        this.getCDRLogbooks(id),
        this.cdrStore.select(selectCatchDisposalRecordById(id))
      ),
      take(1),
      map(([tripInfo, logbooks, { requestId, DisposalMethod }]) => ({
        ...tripInfo,
        clientVersion: environment.buildNumber,
        requestId,
        createdDateTime: utcNow(),
        logbooks,
        disposalMethod: DisposalMethod,
      }))
    );
  }

  public getCDRLog(
    tripCorrelationID: string,
    disposalType: DisposalMethod,
    success = false
  ) {
    const timestamp = utcNow();
    return log({
      activityLog: {
        tripCorrelationID,
        timestamp,
        action: 'CDR ',
        page: Page.WeightNotice,
        message: success
          ? `${DisposalMethod[disposalType]} CDR for ${qldDate(
              timestamp
            )} was successfully submitted.`
          : `User submitted CDR on ${qldDateTime(timestamp)}.`,
        status: success ? ActivityStatus.success : ActivityStatus.pending,
      },
    });
  }

  public getTripPurpose(tripTypeId: string): string {
    switch (tripTypeId) {
      case TripTypeId.Commercial:
        return 'Commercial';
      case TripTypeId.Charter:
        return 'Charter';
      case TripTypeId.Recreational:
        return 'Recreational';
      default:
        return 'Unknown';
    }
  }

  private getCDRLogbooks(cdrId: string): Observable<LogbookCreateLogbook[]> {
    return this.cdrStore.select(selectCatchDisposalRecordById(cdrId)).pipe(
      switchMap((cdr) =>
        this.cdrCatchStore.select(selectCDRCatchesByCDRId(cdr.id)).pipe(
          switchMap((cdrCatches) =>
            of(Array.from(new Set(cdrCatches.map(prop('fisherySymbol'))))).pipe(
              switchMap((fisherySymbols) =>
                forkJoin(
                  fisherySymbols.map((fisherySymbol) =>
                    this.userBucketStore
                      .select(
                        selectCDRLogbookReferenceByFisherySymbol(fisherySymbol)
                      )
                      .pipe(
                        take(1),
                        map((cdrLogbook) => {
                          const catchesToReport = cdrCatches
                            .filter((cdrCatch) =>
                              cdrLogbook.species.some((s) =>
                                equals(s.id, cdrCatch.speciesId)
                              )
                            )
                            .map(this.getCDRCatch)
                            .flat();

                          return {
                            fisherySymbol,
                            logbookClass: LogbookClass.CD,
                            days: [
                              {
                                date: utcNow(),
                                events: [
                                  {
                                    activityType: cdr.DisposalMethod,
                                    fishingMethod:
                                      cdrLogbook.fishingMethods[0].id,
                                    fields: this.getCDRLogbookFields(
                                      cdr,
                                      cdrLogbook
                                    ),
                                    catch: catchesToReport,
                                  },
                                ],
                              },
                            ],
                          };
                        })
                      )
                  )
                )
              )
            )
          )
        )
      ),
      take(1)
    );
  }

  private getCDRCatch(cdrCatch: ICDRCatch) {
    const { speciesId, measureId, fishFormId, quantity } = cdrCatch;
    return isNil(quantity)
      ? []
      : [{ speciesId, measureId, fishFormId, quantity }];
  }

  private getCDRLogbookFields(
    cdr: ICatchDisposalRecord,
    cdrLogbook: Logbook
  ): LogbookCreateField[] {
    return cdrLogbook.fields
      .map((field) =>
        isNil(cdr[field.id])
          ? []
          : [
              {
                id: field.id,
                value: cdr[field.id].toString(),
              },
            ]
      )
      .flat();
  }

  public logbookHasNoQuotaCatch() {
    return this.logbookCatchesStore.select(selectCatches).pipe(
      withLatestFrom(
        this.userBucketStore
          .select(selectMyQuotaSymbolsForPriorEmergencyNotice)
          .pipe(
            map((myQuotaSymbols) =>
              myQuotaSymbols.map((quotaSymbol) => quotaSymbol.quotaSymbol)
            )
          ),
        this.userBucketStore.select(selectRetainedCatch)
      ),
      map(
        ([logbookCatches, myQuotaSymbols, retainedCatches]) =>
          logbookCatches
            .filter((logbookCatch) => !isNil(logbookCatch.quantity))
            .every(
              (logbookCatch) =>
                logbookCatch.discarded ||
                !includes(logbookCatch.quotaSymbol, myQuotaSymbols)
            ) && (retainedCatches ?? []).every(propEq('quantity', 0))
      )
    );
  }

  public constructSubmitWeightNoticePayload(id: string): Observable<{
    weightNoticeCreate: Omit<
      ActivityWeightCreate,
      'requestId' | 'clientVersion'
    >;
    localId: number;
    id: string;
  }> {
    return this.weightNoticeStore.select(selectWeightNoticeById(id)).pipe(
      take(1),
      withLatestFrom(this.getTripInfo()),
      map(
        ([
          weightNotice,
          {
            tripCorrelationID,
            primaryCommercialFishingLicence,
            commercialFisherLicence,
          },
        ]) => ({
          weightNoticeCreate: {
            createdDateTime: utcNow(),
            tripCorrelationID,
            primaryCommercialFishingLicence,
            commercialFisherLicence,
            catch: weightNotice.weightCatches.map(this.getActivityCatch),
          },
          localId: weightNotice.localId,
          id: weightNotice.id,
        })
      )
    );
  }

  public constructSubmitRetainNoticePayload(): Observable<
    Omit<ActivityRetainedCreate, 'requestId' | 'clientVersion'>
  > {
    return this.retainNoticeStore.select(selectRetainCatches).pipe(
      take(1),
      withLatestFrom(this.getTripInfo()),
      map(
        ([
          retainCatches,
          {
            tripCorrelationID,
            primaryCommercialFishingLicence,
            commercialFisherLicence,
          },
        ]) => ({
          createdDateTime: utcNow(),
          tripCorrelationID,
          primaryCommercialFishingLicence,
          commercialFisherLicence,
          catch: retainCatches.map(this.getActivityCatch),
        })
      )
    );
  }

  public constructSubmitPriorEmergencyNoticePayload(): Observable<
    | Omit<ActivityPriorCreate, 'requestId'>
    | Omit<ActivityEmergencyCreate, 'requestId' | 'clientVersion'>
  > {
    return this.priorNoticeStore.select(selectValidQuotaCatches).pipe(
      take(1),
      withLatestFrom(
        this.getTripInfo(),
        this.priorNoticeStore.select(selectEmergencyLandingPoint)
      ),
      map(
        ([
          quotaCatches,
          {
            tripCorrelationID,
            primaryCommercialFishingLicence,
            commercialFisherLicence,
          },
          emergencyLandingPoint,
        ]) => ({
          createdDateTime: utcNow(),
          tripCorrelationID,
          primaryCommercialFishingLicence,
          commercialFisherLicence,
          ...(isNil(emergencyLandingPoint)
            ? {}
            : { landingPoint: emergencyLandingPoint }),
          catch: quotaCatches.map(this.getActivityCatch),
        })
      )
    );
  }

  private getActivityCatch(quotaCatch: IQuotaCatch): ActivityCatch {
    const { quotaSymbol, fishFormCode, measureCode, quantity, fisherySymbol } =
      quotaCatch;
    return { quotaSymbol, fishFormCode, measureCode, quantity, fisherySymbol };
  }

  private getTripInfo() {
    return this.tripStore.select(selectPreTrip).pipe(
      take(1),
      map(
        ({
          tripCorrelationID,
          primaryCommercialFishingLicence,
          commercialFisherLicence,
        }) => ({
          tripCorrelationID,
          primaryCommercialFishingLicence,
          commercialFisherLicence,
        })
      )
    );
  }

  public constructSubmitLogbookPayload(
    date?: string
  ): Observable<Omit<LogbookCreate, 'requestId' | 'clientVersion'>> {
    return this.getTripInfo().pipe(
      withLatestFrom(
        this.getLogbooks(date),
        this.getLogbooks(date, LogbookClass.SR),
        this.getLogbooks(date, LogbookClass.TEP)
      ),
      map(
        ([
          {
            tripCorrelationID,
            primaryCommercialFishingLicence,
            commercialFisherLicence,
          },
          logbooks,
          srLogbooks,
          tepLogbooks,
        ]) => ({
          tripCorrelationID,
          primaryCommercialFishingLicence,
          commercialFisherLicence,
          logbooks: [...logbooks, ...srLogbooks, ...tepLogbooks],
        })
      ),
      catchError((err) => {
        throw err;
      })
    );
  }

  private getLogbooks = (
    date?: string,
    logbookClass: LogbookClass = undefined
  ): Observable<Array<LogbookCreateLogbook>> =>
    this.logbooksStore.select(selectLogbooks).pipe(
      take(1),
      mergeMap((logbooks) =>
        forkJoin(
          logbooks.map((logbook) =>
            this.userBucketStore
              .select(
                selectSecondaryLogbookReferenceByClassAndFisherySymbol(
                  logbookClass,
                  logbook.fisherySymbol
                )
              )
              .pipe(
                take(1),
                switchMap((secondaryLogbook) => {
                  if (!isNil(logbookClass) && isNil(secondaryLogbook)) {
                    return of(null);
                  }
                  return this.getLogbookDays(logbook, date, logbookClass);
                })
              )
          )
        ).pipe(
          map((logbookDays) =>
            logbookDays
              .map((days, i) => {
                if (isNilOrEmpty(days)) {
                  return [];
                }
                return [
                  {
                    fisherySymbol: logbooks[i].fisherySymbol,
                    days,
                    ...(isNil(logbookClass) ? {} : { logbookClass }),
                  },
                ];
              })
              .flat()
          )
        )
      )
    );

  private getLogbookDays = (
    logbook: ILogbook,
    date?: string,
    logbookClass: LogbookClass = undefined
  ): Observable<Array<LogbookCreateLogbookDays>> =>
    this.logbookDaysStore.select(selectLogbookDaysByLogbookId(logbook.id)).pipe(
      take(1),
      map((logbookDays) =>
        isNilOrEmpty(date)
          ? logbookDays
          : logbookDays.filter((logbookDay) => equals(logbookDay.date, date))
      ),
      mergeMap((logbookDays) =>
        forkJoin(
          logbookDays.map((logbookDay) =>
            this.getLogbookEvents(
              logbook.fisherySymbol,
              logbookDay,
              logbookClass
            )
          )
        ).pipe(
          map((logbookEvents) =>
            logbookEvents
              .map((events, i) => {
                if (isNilOrEmpty(events)) {
                  return [];
                }
                return [
                  {
                    date: logbookDays[i].date,
                    events,
                  },
                ];
              })
              .flat()
          )
        )
      )
    );

  private getLogbookEvents = (
    fisherySymbol: string,
    logbookDay: ILogbookDay,
    logbookClass: LogbookClass
  ): Observable<Array<LogbookCreateLogbookEvents>> =>
    this.logbookEventsStore
      .select(selectEventsByLogbookDayId(logbookDay.id))
      .pipe(
        switchMap((logbookEvents) =>
          this.getEventsByLogbookClass(
            fisherySymbol,
            logbookEvents,
            logbookClass
          )
        ),
        take(1),
        mergeMap((logbookEvents) => {
          if (logbookEvents.length > 0) {
            return forkJoin(
              logbookEvents.map((event) =>
                this.getEventFields(logbookClass, event).pipe(take(1))
              )
            ).pipe(
              map((fieldsArray) =>
                fieldsArray
                  .map((fields, i) => ({
                    id: logbookEvents[i].id,
                    logbookDayId: logbookEvents[i].logbookDayId,
                    activityType: logbookEvents[i].activityType,
                    fishingMethod: logbookEvents[i].fishingMethod,
                    ...(isNilOrEmpty(logbookEvents[i].gearCode)
                      ? {}
                      : {
                          gearCode: logbookEvents[i].gearCode,
                        }),
                    fields,
                    ...(logbookEvents[i].eventableFields?.some(
                      (eventableField) =>
                        includes(
                          logbookEvents[i].activityType,
                          eventableField.activityTypes
                        )
                    ) &&
                    not(isNilOrEmpty(logbookEvents[i].eventableFieldsValue))
                      ? {
                          eventableFields: this.getEventableFields(
                            logbookEvents[i].eventableFieldsValue
                          ),
                        }
                      : {}),
                  }))
                  .flat()
              ),
              mergeMap((events) =>
                forkJoin(
                  events.map((event) =>
                    this.getLogbookCatches(event, logbookClass)
                  )
                ).pipe(
                  map((logbookCatches) =>
                    logbookCatches.map((logbookCatch, i) => ({
                      ...omit(['id', 'logbookDayId'], events[i]),
                      catch: logbookCatch,
                    }))
                  )
                )
              )
            );
          }
          return of([]);
        })
      );

  private getEventsByLogbookClass(
    fisherySymbol: string,
    logbookEvents: ILogbookEvent[],
    logbookClass: LogbookClass
  ): Observable<ILogbookEvent[]> {
    switch (logbookClass) {
      case undefined:
        return of(
          logbookEvents.filter((logbookEvent) => !logbookEvent.parentEventId)
        );
      case LogbookClass.SR:
        return this.updateSRLogbookFishingMethodByFisherySymbol(
          fisherySymbol,
          logbookEvents.filter(
            (logbookEvent) =>
              !logbookEvent.parentEventId && logbookEvent.isSecondaryEnabled
          )
        );
      case LogbookClass.TEP:
        return of(
          logbookEvents.filter(
            (logbookEvent) =>
              logbookEvent.parentEventId &&
              logbookEvents.find((event) =>
                equals(event.id, logbookEvent.parentEventId)
              ).isTEPEnabled
          )
        );
    }
  }

  private updateSRLogbookFishingMethodByFisherySymbol(
    fisherySymbol: string,
    logbookEvents: ILogbookEvent[]
  ) {
    const secondaryLogbookEvents = clone(logbookEvents);
    return this.userBucketStore
      .select(
        selectSecondaryLogbookReferenceByClassAndFisherySymbol(
          LogbookClass.SR,
          fisherySymbol
        )
      )
      .pipe(
        take(1),
        map((secondaryLogbook) => {
          secondaryLogbookEvents.forEach(
            (logbookEvent) =>
              (logbookEvent.fishingMethod =
                secondaryLogbook.fishingMethods.find((fishingMethod) =>
                  fishingMethod.fisherySymbols.includes(fisherySymbol)
                ).id)
          );
          return secondaryLogbookEvents;
        })
      );
  }

  private getEventableFields(
    fieldsValue: Array<IEventableFieldsValue>
  ): Array<Array<LogbookCreateField>> {
    const eventableFields: Array<Array<LogbookCreateField>> = [];
    fieldsValue
      .map((fieldValue) =>
        omit(['id', 'locationType', 'completed', 'isEmpty'], fieldValue)
      )
      .forEach((fieldValue) => {
        const eventableField: Array<LogbookCreateField> = [];
        Object.entries(fieldValue).forEach(([key, value]) =>
          eventableField.push({ id: key, value: value as string })
        );
        eventableFields.push(eventableField);
      });
    return eventableFields;
  }

  private getLogbookCatches = (
    { id }: { id: string },
    logbookClass: LogbookClass
  ): Observable<Array<LogbookCreateLogbookCatch>> => {
    if (equals(logbookClass, LogbookClass.TEP)) {
      return of([]);
    }
    return this.logbookCatchesStore
      .select(
        equals(logbookClass, LogbookClass.SR)
          ? selectSecondaryCatchesByLogbookEventId(id)
          : selectPrimaryCatchesByLogbookEventId(id)
      )
      .pipe(
        take(1),
        map((logbookCatches) =>
          logbookCatches
            .filter(({ quantity }) => !isNil(quantity))
            .map((logbookCatch) => {
              const {
                speciesId,
                measureId,
                fishFormId,
                discarded,
                quantity,
                grade,
              } = logbookCatch;
              return {
                speciesId,
                measureId,
                fishFormId,
                discarded,
                quantity,
                grade,
              };
            })
        )
      );
  };

  private getEventFields = (
    logbookClass: LogbookClass,
    event: ILogbookEvent
  ): Observable<Array<LogbookCreateField>> =>
    this.logbookDaysStore.select(selectLogbookDayById(event.logbookDayId)).pipe(
      switchMap((logbookDay) =>
        this.logbooksStore.select(selectLogbookById(logbookDay.logbookId))
      ),
      switchMap((logbook) =>
        this.userBucketStore.select(
          event.parentEventId
            ? selectTEPLogbookReference
            : isNil(logbookClass)
            ? selectLogbookReferenceByFisherySymbol(logbook.fisherySymbol)
            : selectSecondaryLogbookReferenceByClassAndFisherySymbol(
                logbookClass,
                logbook.fisherySymbol
              )
        )
      ),
      map(({ fields }) =>
        fields
          ?.filter((field) =>
            event.parentEventId ? true : this.filterField(event, field)
          )
          .map((field) =>
            isNilOrEmpty(event[field.id])
              ? []
              : [
                  {
                    id: field.id,
                    value: event[field.id],
                  },
                ]
          )
          .flat()
      )
    );

  private filterField(event: ILogbookEvent, field: LogbookField) {
    let activityTypeIncluded = true;
    if (!isNil(field.activityTypes)) {
      activityTypeIncluded = includes(
        Number(event.activityType),
        field.activityTypes
      );
    }
    let fishingMethodIncluded = true;
    if (!isNil(field.fishingMethods)) {
      fishingMethodIncluded = includes(
        event.fishingMethod,
        field.fishingMethods
      );
    }
    return activityTypeIncluded && fishingMethodIncluded;
  }

  private getQuotaAccounts() {
    return this.tripStore
      .select(selectPreTrip)
      .pipe(
        switchMap((pretrip) =>
          this.userBucketStore
            .select(
              selectAuthorityByPCFL(pretrip.primaryCommercialFishingLicence)
            )
            .pipe(map((authority) => authority?.quota))
        )
      );
  }

  public getLogbookCatchesAndQuotaAccountForTrip() {
    return this.logbookCatchesStore.select(selectCatches).pipe(
      map((catches) =>
        Array.from(new Set(catches.map(prop('quotaSymbol')).filter(Boolean)))
      ),
      withLatestFrom(
        this.getQuotaAccounts().pipe(
          map(
            (quotaAccounts) => new Set(quotaAccounts.map(prop('quotaSymbol')))
          )
        )
      )
    );
  }
}
