import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { IAuthState } from '../auth/auth.reducer';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';
import { clearSessionData } from '../settings/settings.actions';
import { loadUserBucket } from '../user-bucket/user-bucket.actions';
import { IUserBucketState } from '../user-bucket/user-bucket.reducer';
import { IVMSState } from '../vms/vms.reducer';
import { loadVMS } from '../vms/vms.actions';
import { Page } from '../api/model';
import { logout } from '../auth/auth.actions';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { equals, includes, isNil } from '@qld-recreational/ramda';
import { ISettingState } from '../settings/settings.reducer';
import { MESSAGES } from '../messages';
import {
  IWeightNoticeState,
  selectIfAnyWeightNoticeLocked,
} from '../weight-notice/weight-notice.reducer';
import { ToastService } from '@qld-recreational/toast';
import { ITripState } from '../trip/trip.reducer';
import { selectTargetEnv } from '../settings/settings.selectors';
import {
  ContentfulEntries,
  ContentfulSyncStatus,
} from '../contentful/contentful';
import {
  IContentfulState,
  selectViewStatus,
} from '../contentful/contentful.reducer';
import { retry } from '../contentful/contentful.actions';

enum PageUrl {
  Home = 'home',
  QuotaBalance = 'quota-balance',
  AmendingNotice = 'amending-notice',
  Preferences = 'preferences',
  ActivityLog = 'activity-log',
  VesselTracking = 'vessel-tracking',
  ManualPolling = 'manual-polling',
  ContactFisheries = 'contact-fisheries',
  AuthorityDetails = 'authority-details',
  AuthTest = 'auth-test',
}

@Component({
  selector: 'qld-commercial-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SideMenuComponent implements OnInit {
  public contentfulLoading$: Observable<boolean>;
  public targetEnv$: Observable<string>;
  public contentfulLoadFailed$: Observable<boolean>;
  public PageUrl = PageUrl;

  get aboutThisAppLink() {
    return `reference/${ContentfulEntries.AboutThisApp}`;
  }

  constructor(
    private authService: AuthService,
    private toastService: ToastService,
    private settingStore: Store<ISettingState>,
    private authStore: Store<IAuthState>,
    private weightNoticeStore: Store<IWeightNoticeState>,
    private router: Router,
    private settingsStore: Store<ISettingState>,
    private contentfulStore: Store<IContentfulState>,
    private userBucketStore: Store<IUserBucketState>,
    private vmsStore: Store<IVMSState>,
    private tripStore: Store<ITripState>
  ) {}

  public ngOnInit(): void {
    this.targetEnv$ = this.settingStore.select(selectTargetEnv);
    this.contentfulLoading$ = this.contentfulStore
      .select(selectViewStatus)
      .pipe(
        map(
          (viewStatus) =>
            !isNil(viewStatus) &&
            !includes(viewStatus, [
              ContentfulSyncStatus.AssetsSynced,
              ContentfulSyncStatus.Failure,
            ])
        )
      );
    this.contentfulLoadFailed$ = this.contentfulStore
      .select(selectViewStatus)
      .pipe(
        map((viewStatus) => equals(viewStatus, ContentfulSyncStatus.Failure))
      );
  }

  public logout() {
    this.authStore.dispatch(logout());
  }

  public navigateTo(url: PageUrl | string) {
    switch (url) {
      case PageUrl.AmendingNotice:
        this.handleNavigateToAmendNotice();
        return;
      default:
        this.router.navigateByUrl(url);
    }
  }

  private handleNavigateToAmendNotice() {
    this.weightNoticeStore
      .select(selectIfAnyWeightNoticeLocked)
      .pipe(take(1))
      .subscribe((IfAnyWeightNoticeLocked) => {
        if (!IfAnyWeightNoticeLocked) {
          this.router.navigateByUrl(PageUrl.AmendingNotice);
          return;
        }
        this.toastService.presentWarningToast(MESSAGES.unableToAmendNotice);
      });
  }

  public clearTestSession() {
    this.settingsStore.dispatch(clearSessionData());
    this.userBucketStore.dispatch(loadUserBucket({ page: Page.Home }));
    this.vmsStore.dispatch(loadVMS({ page: Page.Home }));
  }

  public reloadContentful(e: MouseEvent) {
    e.stopPropagation();
    this.contentfulStore.dispatch(retry());
  }
}
