import { createAction, props } from '@ngrx/store';
import {
  ICatchDisposalRecord,
  ICDRInitSpecies,
} from '../catch-disposal-record/catch-disposal-record.reducer';
import { ICDRCatch } from './catch-disposal-record-catches.reducer';

export const updateCDRCatches = createAction(
  '[CatchDisposalRecord] Update Catch Disposal Record Catches',
  props<{ cdrCatches: ICDRCatch[] }>()
);

export const initCDRCatchesForSpecies = createAction(
  '[CatchDisposalRecord] Init Catch Disposal Record Catch For Species',
  props<{
    species: ICDRInitSpecies;
    catchDisposalRecord: Omit<
      ICatchDisposalRecord,
      'cdrCatches' | 'species' | 'cdrNumber'
    >;
  }>()
);

export const removeCDRCatches = createAction(
  '[CatchDisposalRecord] Delete Catch Disposal Record Catches',
  props<{ keys: string[] }>()
);
