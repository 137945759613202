import { ActivityLogPostEntry, ActivityStatus, Page } from '../api/model';
import { qldDateTime, utcNow } from '@qld-recreational/moment';
import { isNil } from '@qld-recreational/ramda';

const preTripGeneralActivityLog = {
  page: Page.PreTrip,
  action: 'Submit pre-trip',
};

export const preTripSubmitSuccessActivityLog = ({
  tripCorrelationID,
  licence,
  vesselStatusMessage,
  purpose,
}: {
  tripCorrelationID: string;
  licence: string;
  vesselStatusMessage: string;
  purpose: string;
}) => ({
  tripCorrelationID,
  message:
    `Pre-trip for <em>${licence}</em> successfully given for trip purpose ${purpose}.` +
    (isNil(vesselStatusMessage) ? '' : `<br>${vesselStatusMessage}.`),
  status: ActivityStatus.success,
  timestamp: utcNow(),
  ...preTripGeneralActivityLog,
});

export const preTripSubmitFailureActivityLog = (licence: string) => ({
  message: `Pre-trip for <em>${licence}</em> was not successfully submitted.`,
  status: ActivityStatus.error,
  timestamp: utcNow(),
  ...preTripGeneralActivityLog,
});

export const preTripCancelTripActivityLog = (
  licence: string
): ActivityLogPostEntry => {
  const timestamp = utcNow();
  return {
    message: `User submitted cancellation for pre-trip for ${licence} at ${qldDateTime(
      timestamp
    )}`,
    timestamp,
    status: ActivityStatus.error,
    page: Page.PreTrip,
    action: 'Cancel pre-trip',
  };
};
