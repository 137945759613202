import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { defer, from, Observable, of } from 'rxjs';
import {
  catchError,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import {
  fetchState,
  NGRX_ACTIONS,
  NGRX_STORAGE_SYNC_IGNORE_ACTIONS,
  saveState,
  StorageSyncActions,
} from './ngrx-ionic-storage-sync';
import { StorageService } from '@qld-recreational/storage';
import { loadEnvs } from '../settings/settings.actions';
import { Store } from '@ngrx/store';
import { activityLogFeatureKey } from '../activity-log/activity-log.reducer';
import { activityNoticesFeatureKey } from '../activity-notices/activity-notices.reducer';
import {
  amendingNoticeFeatureKey,
  amendingNoticePersistenceKeys,
} from '../amending-notice/amending-notice.reducer';
import { appStateFeatureKey } from '../app-state/app-state.reducer';
import { authFeatureKey, authPersistenceKeys } from '../auth/auth.reducer';
import {
  backgroundRequestsFeatureKey,
  backgroundRequestPersistenceKeys,
} from '../background-request/background-request.reducer';
import { catchDisposalRecordCatchesFeatureKey } from '../catch-disposal-record-catches/catch-disposal-record-catches.reducer';
import { catchDisposalRecordFeatureKey } from '../catch-disposal-record/catch-disposal-record.reducer';
import { contentfulFeatureKey } from '../contentful/contentful.reducer';
import { homeFeatureKey } from '../home/home.reducer';
import { logbookCatchesFeatureKey } from '../logbook-catches/logbook-catches.reducer';
import { logbookDaysFeatureKey } from '../logbook-days/logbook-days.reducer';
import { logbookEventsFeatureKey } from '../logbook-events/logbook-events.reducer';
import { logbooksFeatureKey } from '../logbooks/logbooks.reducer';
import { manualPollingFeatureKey } from '../manual-polling/manual-polling.reducer';
import { notificationsFeatureKey } from '../notification/notification.reducer';
import { preferencesCDRFishFormsMeasuresFeatureKey } from '../preference-cdr-fish-forms-measures/preference-cdr-fish-forms-measures.reducer';
import { preferencesConsignmentFeatureKey } from '../preference-consignment/preferences-consignment.reducer';
import { preferencesDisposalFeatureKey } from '../preference-disposal/preferences-disposal.reducer';
import { preferencesGPSFeatureKey } from '../preference-gps/preference-gps.reducer';
import { preferencesLandingPlacesFeatureKey } from '../preference-landing-places/preference-landing-places.reducer';
import {
  preferenceFeatureKey,
  preferenceFeaturePersistenceKeys,
} from '../preference/preference.reducer';
import { preferenceRegionsSpeciesFeatureKey } from '../preferences-regions-species/preferences-regions-species.reducer';
import { priorEmergencyNoticeFeatureKey } from '../prior-emergency-notice/prior-emergency-notice.reducer';
import { retainNoticeFeatureKey } from '../retain-notice/retain-notice.reducer';
import { settingsFeatureKey } from '../settings/settings.reducer';
import { tripFeatureKey } from '../trip/trip.reducer';
import {
  userBucketFeatureKey,
  userBucketPersistenceKeys,
} from '../user-bucket/user-bucket.reducer';
import { vmsFeatureKey } from '../vms/vms.reducer';
import { weightNoticeFeatureKey } from '../weight-notice/weight-notice.reducer';

const keys = [
  { [authFeatureKey]: authPersistenceKeys },
  { [userBucketFeatureKey]: userBucketPersistenceKeys },
  logbooksFeatureKey,
  {
    [contentfulFeatureKey]: [
      'nextSyncToken',
      'contentfulContext',
      'targetEnv',
      'environments',
    ],
  },
  logbookDaysFeatureKey,
  logbookEventsFeatureKey,
  logbookCatchesFeatureKey,
  tripFeatureKey,
  { [preferenceFeatureKey]: preferenceFeaturePersistenceKeys },
  preferencesGPSFeatureKey,
  vmsFeatureKey,
  activityLogFeatureKey,
  homeFeatureKey,
  priorEmergencyNoticeFeatureKey,
  settingsFeatureKey,
  { [backgroundRequestsFeatureKey]: backgroundRequestPersistenceKeys },
  activityNoticesFeatureKey,
  weightNoticeFeatureKey,
  retainNoticeFeatureKey,
  catchDisposalRecordFeatureKey,
  notificationsFeatureKey,
  catchDisposalRecordCatchesFeatureKey,
  manualPollingFeatureKey,
  preferencesDisposalFeatureKey,
  preferencesConsignmentFeatureKey,
  { [amendingNoticeFeatureKey]: amendingNoticePersistenceKeys },
  appStateFeatureKey,
  preferenceRegionsSpeciesFeatureKey,
  preferencesLandingPlacesFeatureKey,
  preferencesCDRFishFormsMeasuresFeatureKey,
];

@Injectable()
export class StorageSyncEffects {
  constructor(
    private actions$: Actions,
    private storage: StorageService,
    private store: Store<{}>
  ) {}

  public saveState$ = createEffect(
    () =>
      this.actions$.pipe(
        switchMap((action) =>
          from(this.storage.init()).pipe(
            withLatestFrom(this.store.select((store) => store)),
            tap(([storage, nextState]) => {
              if (!NGRX_STORAGE_SYNC_IGNORE_ACTIONS.includes(action.type)) {
                saveState(storage, nextState, keys).catch((err) =>
                  console.log(err)
                );
              }
            })
          )
        )
      ),
    {
      dispatch: false,
    }
  );

  public hydrate$: Observable<any> = createEffect(() =>
    defer(() =>
      from(this.storage.init()).pipe(
        switchMap((storage) =>
          from(fetchState(storage)).pipe(
            map((state) => ({
              type: StorageSyncActions.HYDRATED,
              payload: state,
            })),
            catchError((e) => {
              console.warn(
                `error fetching data from store for hydration: ${e}`
              );

              return of({
                type: StorageSyncActions.HYDRATED,
                payload: {},
              });
            })
          )
        )
      )
    )
  );
}
