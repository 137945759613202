import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { equals } from '@qld-recreational/ramda';
import { filter, take } from 'rxjs/operators';
import { Page } from '../api/model';
import { ViewStatus } from '../shared/ViewStatus';
import { loadVMS } from '../vms/vms.actions';
import { IVMSState } from '../vms/vms.reducer';
import { loadUserBucket } from './user-bucket.actions';
import {
  IUserBucketState,
  selectUserBucketViewStatus,
} from './user-bucket.reducer';

@Injectable()
export class UserBucketService {
  constructor(
    private userBucketStore: Store<IUserBucketState>,
    private vmsStore: Store<IVMSState>,
    private loadingController: LoadingController
  ) {}

  public async fetchLatestUserBucketAndVMS(
    onCompleted?: (status: ViewStatus) => void
  ) {
    await this.presentLoading();

    this.userBucketStore.dispatch(loadUserBucket({ page: Page.PreTrip }));
    this.vmsStore.dispatch(loadVMS({ page: Page.PreTrip }));

    this.userBucketStore
      .select(selectUserBucketViewStatus)
      .pipe(
        filter((status) => !equals(ViewStatus.Loading, status)),
        take(1)
      )
      .subscribe((status) => {
        onCompleted && onCompleted(status);
        this.loadingController.dismiss();
      });
  }

  private async presentLoading(): Promise<void> {
    const loading = await this.loadingController.create({
      spinner: 'crescent',
      message: 'Loading user bucket and vms...',
      translucent: true,
      cssClass: 'commercial',
    });
    return loading.present();
  }
}
