/**
 * Queensland Fisheries FishNet API
 * Queensland Fisheries FishNet API
 *
 * OpenAPI spec version: 1.0.2
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
export enum TripTypeId {
  Commercial = 'CO',
  Recreational = 'RE',
  Charter = 'CH',
}

export interface TripType {
  id?: TripTypeId;
  description?: string;
}
