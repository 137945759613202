import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'home',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./home-page/home-page.module').then((m) => m.HomePageModule),
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'logbook-catch',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./logbook-catches-page/logbook-catches-page.module').then(
        (m) => m.LogbookCatchesPageModule
      ),
  },
  {
    path: 'logbook-record-catch',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./logbook-record-catch/logbook-record-catch.module').then(
        (m) => m.LogbookRecordCatchModule
      ),
  },
  {
    path: 'logbook-home',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./logbook-home/logbook-home.module').then(
        (m) => m.LogbookHomeModule
      ),
  },
  {
    path: 'logbook-summary',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./logbook-summary-page/logbook-summary-page.module').then(
        (m) => m.LogbookSummaryPageModule
      ),
  },
  {
    path: 'logbook-effort',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./logbook-effort/logbook-effort.module').then(
        (m) => m.LogbookEffortModule
      ),
  },
  {
    path: 'ready-to-fish',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pre-trip-page/pre-trip-page.module').then(
        (m) => m.PreTripPageModule
      ),
  },
  {
    path: 'vessel-tracking',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./vessel-tracking/vessel-tracking.module').then(
        (m) => m.VesselTrackingModule
      ),
  },
  {
    path: 'preferences',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./preferences-page/preferences-page.module').then(
        (m) => m.PreferencesPageModule
      ),
  },
  {
    path: 'settings',
    loadChildren: () =>
      import('./settings-page/settings-page.module').then(
        (m) => m.SettingsPageModule
      ),
  },
  {
    path: 'activity-log',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./activity-log-page/activity-log-page.module').then(
        (m) => m.ActivityLogPageModule
      ),
  },
  {
    path: 'prior-emergency-notice',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        './prior-emergency-notice-page/prior-emergency-notice-page.module'
      ).then((m) => m.PriorEmergencyNoticePageModule),
  },
  {
    path: 'amending-notice',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./amending-notice/amending-notice.module').then(
        (m) => m.AmendingNoticeModule
      ),
  },
  {
    path: 'activity-notices',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./activity-notices-page/activity-notices-page.module').then(
        (m) => m.ActivityNoticesPageModule
      ),
  },
  {
    path: 'logbook-effort-eventable',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        './logbook-effort-eventable-page/logbook-effort-eventable-page.module'
      ).then((m) => m.LogbookEffortEventablePageModule),
  },
  {
    path: 'weight-notice',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./weight-notice-page/weight-notice-page.module').then(
        (m) => m.WeightNoticePageModule
      ),
  },
  {
    path: 'weight-disposal',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./weight-disposal/weight-disposal.module').then(
        (m) => m.WeightDisposalModule
      ),
  },
  {
    path: 'retain-notice',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./retain-notice-page/retain-notice-page.module').then(
        (m) => m.RetainNoticePageModule
      ),
  },
  {
    path: 'catch-disposal-record',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        './catch-disposal-record-page/catch-disposal-record-page.module'
      ).then((m) => m.CatchDisposalRecordPageModule),
  },
  {
    path: 'quota-balance',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./quota-balance/quota-balance.module').then(
        (m) => m.QuotaBalanceModule
      ),
  },
  {
    path: 'nil-fishing',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./nil-fishing/nil-fishing.module').then(
        (m) => m.NilFishingModule
      ),
  },
  {
    path: 'reference',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./reference/reference.module').then((m) => m.ReferenceModule),
  },
  {
    path: 'notification',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./notification-page/notification-page.module').then(
        (m) => m.NotificationPageModule
      ),
  },
  {
    path: 'manual-polling',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./manual-polling-page/manual-polling-page.module').then(
        (m) => m.ManualPollingPageModule
      ),
  },
  {
    path: 'contact-fisheries',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./contact-fisheries/contact-fisheries.module').then(
        (m) => m.ContactFisheriesModule
      ),
  },
  {
    path: 'authority-details',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./authority-details/authority-details.module').then(
        (m) => m.AuthorityDetailsModule
      ),
  },
  {
    path: 'on-boarding',
    loadChildren: () =>
      import('./on-boarding/on-boarding.module').then(
        (m) => m.OnBoardingModule
      ),
  },
  {
    path: 'auth-test',
    loadChildren: () =>
      import('./auth-test/auth-test.module').then((m) => m.AuthTestModule),
  },
  {
    path: 'version-mismatch',
    loadChildren: () =>
      import('./version-mismatch-page/version-mismatch-page.module').then(
        (m) => m.VersionMismatchModule
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      useHash: false,
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
